import React from 'react';
import { Grid, FormControl, OutlinedInput, FormHelperText, Select, MenuItem } from '@mui/material';
import { TimesOnly, Minute } from 'components/Function/AddDatas';

/**
 * 
 * @param {string} value
 * @param {function(): string} handleChange
 * @param {string} placeholder
 * @param {boolean} required
 * @param {boolean} error
 * @param {string} label
 * @param {number} rows
 * @description 
 */
const TimeForm = (props) => {

    return (
        <>
            {props.label ?
            <Grid style={{ marginBottom: '8px' }}>
                {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
            </Grid> :
            null
            }
            <Grid container spacing={1} alignItems={'center'}>
                <Grid item xs={5.5}>
                    <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                        <Select
                            value={props.value ? `${props.value.split(':')[0]}:00` : ''}
                            onChange={props.handleChange}
                            displayEmpty
                            className='select_placeholder'
                            disabled={props.disabled}
                        >
                            {TimesOnly().map((item, i) => (
                            <MenuItem key={i} value={`${item}:00`}>{item}</MenuItem> 
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1} textAlign={'center'}>:</Grid>
                <Grid item xs={5.5}>
                    <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                        <Select
                            value={props.value ? props.value : ''}
                            onChange={props.handleChange}
                            displayEmpty
                            className='select_placeholder'
                            disabled={props.disabled || !props.value}
                        >
                            {Minute().map((item, i) => (
                            <MenuItem key={i} value={props.value ? `${props.value.split(':')[0]}:${item}` : item}>{item}</MenuItem> 
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {/* <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                {props.label ?
                    <Grid style={{ marginBottom: '8px' }}>
                        {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
                    </Grid> :
                    null
                }
                <OutlinedInput
                    type={'time'}
                    placeholder={props.placeholder}
                    value={props.value || ''}
                    onChange={props.handleChange}
                    rows={props.rows ? props.rows : 1}
                    multiline={props.rows}
                    timeStep={300}
                />
                {props.error ? <FormHelperText style={{ marginLeft: 0 }}>Error</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>}
            </FormControl> */}
        </>
    );
};

export default TimeForm;