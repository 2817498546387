import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm } from 'components/Items/Form';
import { ConfirmModal } from 'components/Items/Modal';
import { YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const UserDetailView = (props) => {
    const navigate = useNavigate();
    const { userNo } = useParams();
    const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false);
    const [search, setSearch] = useState('');
    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'Name', label: '이름' },
        { key: 'Email', label: '이메일' },
        { key: 'Phone', label: '연락처' },
        { key: 'StatusKo', label: '권한' },
        { key: 'CreatedAtValue', label: '생성일시' }
    ];

    return (
        <>
        <DetailContainer
            title='유저 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenUserDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/admin/user/${userNo}/update`) } ]}
            switch={{ label: props.manager.State === 1 ? <b>사용 가능</b> : <span style={{ color: '#5E5E5E'}}>사용 불가</span>, value: Boolean(props.manager.State === 1), handleChange: props.handleClickState, disabled: props.updating }}
            // button={{ label: '비밀번호 초기화', handleClick: () => console.log(1) }}
        >
            <DataBox
                datas={[
                    { key: '이름', value: props.manager.Name },
                    { key: '연락처', value: props.manager.Phone },
                    { key: '이메일', value: props.manager.Email },
                    { key: '가입 일시', value: YyyyMmDdHhMmSs(props.manager.createdAt) }
                ]}
            />
        </DetailContainer>
        <DetailContainer
            title='직원 목록'
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={props.workers}
                        delete={false}
                        update={false}
                        search={search}
                        handleClickDetail={() => { return false }}
                    />
                </Grid>
            </Grid>
        </DetailContainer>
        <ConfirmModal
            title='유저 삭제'
            icon='error'
            open={openUserDeleteModal}
            handleClose={() => {setOpenUserDeleteModal(false);}}
            handleClickSubmit={() => {setOpenUserDeleteModal(false);props.handleClickDeleteBtn();}}
            submitLabel='삭제'
        >
            유저 정보를 삭제 하시겠습니까? 유저 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        </>
    );
};

export default UserDetailView;