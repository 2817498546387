import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { TeamList, TeamCreate, TeamDetail, TeamUpdate } from 'domains/User/Manage/Team';
import NotFound from 'routes';

const TeamRoute = () => {
    return (
        <Routes>
            <Route path='' element={<TeamList />} />
            <Route path='create' element={<TeamCreate />} />
            <Route path=':teamNo' element={<TeamDetail />} />
            <Route path=':teamNo/update' element={<TeamUpdate />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default TeamRoute;