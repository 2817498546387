import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, FormGroup, Checkbox } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { TextForm, SelectForm, DateForm, TimeForm, NumberForm } from 'components/Items/Form';
import { SessionKind, Service, ScheduleKind, RepeatCount, RepeatDay } from 'components/Function/AddDatas';
import { YyyyMmDd } from 'components/Function/ChangeText';

const ScheduleUpdateView = (props) => {
    const navigate = useNavigate();
    const schedule = props.schedule;
    const setSchedule = props.setSchedule;
    const rooms = props.rooms;
    const setRooms = props.setRooms;
    const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);

    const handleChangeStartTime = (e) => {
        let changeSchedule = { ...schedule };
        changeSchedule.StartTime = e.target.value;
        let tumpTime = new Date(`1980-01-01 ${e.target.value}`);
        tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
        const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
        const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
        changeSchedule.EndTime = `${tumpHours}:${tumpMinutes}`;
        setSchedule(changeSchedule)
    };
    const handleChangeRepeatCount = (e) => {
        let changeSchedule = { ...schedule };
        changeSchedule.Repeat.Count = e.target.value;
        setSchedule(changeSchedule);
    };
    const handleChangeRepeatDays = (e) => {
        let changeSchedule = { ...schedule };
        changeSchedule.Repeat.Days = e.target.value;
        setSchedule(changeSchedule);
    };

    const handleResetRooms = () => {
        let changeRooms = [...rooms];
        for(let i = 0; i < changeRooms.length; i++){
            changeRooms[i].disabled = false;
        }
        if(schedule.StartDate && schedule.StartTime){
            for(let i = 0; i < changeRooms.length; i++){
                let tumpDatas = changeRooms[i].Schedule_ids.filter((item) => (new Date(item.StartDate) - new Date(schedule.StartDate) === 0));
                tumpDatas = tumpDatas.filter((item) => ((
                    new Date(`2000-01-01 ${schedule.StartTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${schedule.StartTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                ) || (
                    new Date(`2000-01-01 ${schedule.EndTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${schedule.EndTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                )));
                if(tumpDatas.length > 0){
                    changeRooms[i].disabled = true;
                }
            }
        }
        setRooms(changeRooms);
    };
    
    useEffect(() => {
        handleResetRooms();
    }, [schedule.StartDate, schedule.StartTime]);

    useEffect(() => {
        let tump = false;
        if(schedule.Kind.State === 1){
            if(!schedule.Client_id){
                tump = true;
            }
            if(!schedule.Kind.Detail){
                tump = true;
            }
            if(!schedule.StartDate){
                tump = true;
            }
            if(!schedule.StartTime){
                tump = true;
            }
            if(!schedule.Service){
                tump = true;
            }
            if(!schedule.Counselor_id){
                tump = true;
            }
        }else{
            if(!schedule.Kind.Detail){
                tump = true;
            }
            if(!schedule.StartDate){
                tump = true;
            }
            if(!schedule.StartTime){
                tump = true;
            }
        }
        setDisabledUpdateBtn(tump);
    }, [schedule]);

    return (
        <>
        <FormContainer title='스케줄 수정'>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    스케줄
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={1} disabled={props.user.Status === 3} control={<Radio checked={schedule.Kind.State === 1}/>} label={'상담'} onChange={() => setSchedule({ ...schedule, Kind: { State: 1, Detail: '' }})}/>
                    <FormControlLabel value={2} control={<Radio checked={schedule.Kind.State === 2}/>} label={'기타'} onChange={() => setSchedule({ ...schedule, Kind: { State: 2, Detail: '' }})}/>
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {schedule.Kind.State === 1 ?
            <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <SelectForm
                        label='내담자'
                        required={true}
                        error={false}
                        value={schedule.Client_id}
                        datas={props.clients}
                        handleChange={(e) => setSchedule({ ...schedule, Client_id: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectForm
                        label='상담 종류'
                        required={true}
                        error={false}
                        value={schedule.Kind.Detail}
                        datas={SessionKind()}
                        handleChange={(e) => setSchedule({ ...schedule, Kind: { State: 1, Detail: e.target.value }})}
                    />
                </Grid>
            </Grid>
            <DateForm
                label='상담 일'
                required={true}
                error={false}
                value={YyyyMmDd(schedule.StartDate)}
                handleChange={(e) => setSchedule({ ...schedule, StartDate: e.target.value })}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TimeForm
                        label='상담 시작 시간'
                        required={true}
                        error={false}
                        value={schedule.StartTime}
                        handleChange={handleChangeStartTime}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeForm
                        label='상담 종료 시간'
                        required={true}
                        error={false}
                        value={schedule.EndTime}
                        handleChange={(e) => setSchedule({ ...schedule, EndTime: e.target.value })}
                    />
                </Grid>
            </Grid>
            <SelectForm
                label='서비스 방식'
                required={true}
                error={false}
                value={schedule.Service}
                datas={Service()}
                handleChange={(e) => setSchedule({ ...schedule, Service: e.target.value })}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <SelectForm
                        label='상담자'
                        required={true}
                        error={false}
                        disabled={true}
                        value={schedule.Counselor_id}
                        datas={props.members}
                        handleChange={(e) => setSchedule({ ...schedule, Counselor_id: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectForm
                        label='상담실'
                        required={false}
                        error={!Boolean(schedule.StartDate) || !Boolean(schedule.StartTime)}
                        errorMessage={'상담 일 및 시간을 먼저 설정해주세요.'}
                        cancel={true}
                        value={schedule.Room_id?._id}
                        datas={props.rooms}
                        handleChange={(e) => setSchedule({ ...schedule, Room_id: { _id: e.target.value } })}
                    />
                </Grid>
            </Grid>
            </>:
            <>
            <SelectForm
                label='스케줄 종류'
                required={true}
                error={false}
                value={schedule.Kind.Detail}
                datas={ScheduleKind()}
                handleChange={(e) => setSchedule({ ...schedule, Kind: { State: 2, Detail: e.target.value }})}
            />
            <DateForm
                label='일정 일'
                required={true}
                error={false}
                value={YyyyMmDd(schedule.StartDate)}
                handleChange={(e) => setSchedule({ ...schedule, StartDate: e.target.value })}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TimeForm
                        label='일정 시작 시간'
                        required={true}
                        error={false}
                        value={schedule.StartTime}
                        handleChange={handleChangeStartTime}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeForm
                        label='일정 종료 시간'
                        required={true}
                        error={false}
                        value={schedule.EndTime}
                        handleChange={(e) => setSchedule({ ...schedule, EndTime: e.target.value })}
                    />
                </Grid>
            </Grid>
            </>}
            <TextForm
                label='메모'
                required={false}
                error={false}
                value={schedule.Memo}
                rows={4}
                handleChange={(e) => setSchedule({ ...schedule, Memo: e.target.value })}
            />
            {schedule.Kind.State === 1 ?
            <Grid container spacing={1}>
                <Grid item xs={4}>
                <FormControl fullWidth>
                    <Grid style={{ marginBottom: '8px' }}>
                        상담비 지불 여부
                    </Grid>
                    <RadioGroup row>
                        <FormControlLabel value={true} onChange={() => setSchedule({ ...schedule, IsFee: true })} control={<Radio checked={Boolean(schedule.IsFee)} />} label={'O'} />
                        <FormControlLabel value={false} onChange={() => setSchedule({ ...schedule, IsFee: false })} control={<Radio checked={!Boolean(schedule.IsFee)} />} label={'X'} />
                    </RadioGroup>
                    <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <NumberForm
                        label='상담비'
                        required={false}
                        error={false}
                        // disabled={!Boolean(schedule.IsFee)}
                        placeholder='상담비를 입력해 주세요.'
                        value={schedule.Fee}
                        handleChange={(e) => setSchedule({ ...schedule, Fee: e.target.value })}
                    />
                </Grid>
            </Grid>:null}
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Grid style={{ marginBottom: '8px' }}>
                            반복 여부
                        </Grid>
                        <FormGroup row>
                            <FormControlLabel 
                                onChange={() => setSchedule({ ...schedule, Repeat: { State: !Boolean(schedule.Repeat.State), Count: '', Days: '' }})} 
                                control={<Checkbox checked={Boolean(schedule.Repeat.State)}/>} 
                            />
                        </FormGroup>
                        <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <SelectForm
                        label='반복 횟수'
                        required={false}
                        error={false}
                        disabled={!Boolean(schedule.Repeat.State)}
                        value={schedule.Repeat.Count}
                        datas={RepeatCount()}
                        handleChange={handleChangeRepeatCount}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectForm
                        label='반복 주기'
                        required={false}
                        error={false}
                        disabled={!Boolean(schedule.Repeat.State)}
                        value={schedule.Repeat.Days}
                        datas={RepeatDay()}
                        handleChange={handleChangeRepeatDays}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton
                        label='수정'
                        loading={props.updating}
                        disabled={disabledUpdateBtn}
                        handleClick={props.handleClickUpdateBtn}/>
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default ScheduleUpdateView;