import React, { useState } from 'react';
import { FormControl, Grid, RadioGroup, Radio, FormControlLabel, FormHelperText, FormGroup, Checkbox, IconButton, Tooltip } from '@mui/material';
import { Add, Remove, Help } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { FormContainer } from 'components/Items/Container';
import { EmailForm, TextForm, DateForm, SelectForm, NumberForm } from 'components/Items/Form';
import { Risk, Gender, Employment, Living, Nationality, Medication, PossibleDay, PossibleTime, Marriage, Military, Referral, DesiredService, Reason, DesiredTest } from 'components/Function/AddDatas';
import { LargeCancelButton, LargeSubmitButton, SmallSubmitButton } from 'components/Items/Button';
import { AvailableDay } from 'components/Function/ChangeText';
import { FormModal } from 'components/Items/Modal';

const ClientUpdateView = (props) => {
    const client = props.client;
    const setClient = props.setClient;
    const navigate = useNavigate();
    const regEmail = new RegExp('[a-z0-9]+@[a-z]+?\.[a-zA-Z]{2,3}'); // eslint-disable-line
    const regPhone = new RegExp('[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}'); // eslint-disable-line
    const handleClickEmailCheckCancel = () => {
        setClient({ ...client, EmailCheck: false, Email: '' });
    };
    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setClient({ ...client, Phone: tump });
        }else{
            return false;
        }
    };
    const handleClickPlus = () => {
        let changeClient = { ...client };
        changeClient.Family.push({ Relation: '', Age: '', Name: '', Phone: '' });
        setClient(changeClient);
    };
    const handleClickMinus = () => {
        let changeClient = { ...client };
        changeClient.Family.pop();
        setClient(changeClient);
    };
    const handleChangeFamilyRelation = (e, key) => {
        let changeClient = { ...client };
        changeClient.Family[key].Relation = e.target.value;
        setClient(changeClient);
    };
    const handleChangeFamilyAge = (e, key) => {
        let changeClient = { ...client };
        changeClient.Family[key].Age = e.target.value;
        setClient(changeClient);
    };
    const handleChangeFamilyName = (e, key) => {
        let changeClient = { ...client };
        changeClient.Family[key].Name = e.target.value;
        setClient(changeClient);
    };
    const handleChangeFamilyPhone = (e, key) => {
        let changeClient = { ...client };
        changeClient.Family[key].Phone = e.target.value;
        setClient(changeClient);
    };
    const handleChangeDesiredService = (e) => {
        let changeClient = { ...client };
        if(changeClient.DesiredService.indexOf(e.target.value) > -1){
            changeClient.DesiredService = changeClient.DesiredService.filter((item) => (item !== e.target.value));
        }else{
            changeClient.DesiredService.push(e.target.value);
        }
        setClient(changeClient);
    };
    const handleChangeReason = (e) => {
        let changeClient = { ...client };
        if(changeClient.Reason.indexOf(e.target.value) > -1){
            changeClient.Reason = changeClient.Reason.filter((item) => (item !== e.target.value));
        }else{
            changeClient.Reason.push(e.target.value);
        }
        setClient(changeClient);
    };
    const handleChangeDesiredTest = (e) => {
        let changeClient = { ...client };
        if(changeClient.DesiredTest.indexOf(e.target.value) > -1){
            changeClient.DesiredTest = changeClient.DesiredTest.filter((item) => (item !== e.target.value));
        }else{
            changeClient.DesiredTest.push(e.target.value);
        }
        setClient(changeClient);
    };
    const handleChangeWhen = (e) => {
        let changeClient = { ...client };
        changeClient.PriorTreatment.When = e.target.value;
        setClient(changeClient);
    };
    const handleChangeWhere = (e) => {
        let changeClient = { ...client };
        changeClient.PriorTreatment.Where = e.target.value;
        setClient(changeClient);
    };
    const handleChangeHow = (e) => {
        let changeClient = { ...client };
        if(changeClient.PriorTreatment.How.indexOf(e.target.value) > -1){
            changeClient.PriorTreatment.How = changeClient.PriorTreatment.How.filter((item) => (item !== e.target.value));
        }else{
            changeClient.PriorTreatment.How.push(e.target.value);
        }
        setClient(changeClient);
    };
    const handleChangeAvailableDay = (e) => {
        let changeClient = { ...client };
        if(changeClient.AvailableDay.indexOf(e.target.value) > -1){
            changeClient.AvailableDay = changeClient.AvailableDay.filter((item) => (item !== e.target.value));
            changeClient.Available[Number(e.target.value)-1] = [];
        }else{
            changeClient.AvailableDay.push(e.target.value);
            changeClient.AvailableDay = changeClient.AvailableDay.sort((a, b) => (a - b));
        }
        setClient(changeClient);
    };
    const handleChangeAllDay = () => {
        let changeClient = { ...client };
        if(changeClient.AvailableDay.length === 7){
            changeClient.AvailableDay = [];
            changeClient.Available = [[], [], [], [], [], [], []];
        }else{
            changeClient.AvailableDay = ['1', '2', '3', '4', '5', '6', '7'];
        }
        setClient(changeClient);
    };
    const handleChangeAvailable = (e, key) => {
        let changeClient = { ...client };
        if(changeClient.Available[Number(key)-1].indexOf(e.target.value) > -1){
            changeClient.Available[Number(key)-1] = changeClient.Available[Number(key)-1].filter((item) => (item !== e.target.value));
        }else{
            changeClient.Available[Number(key)-1].push(e.target.value);
        }
        setClient(changeClient);
    };
    const handleChangeAvailableAll = (key) => {
        let changeClient = { ...client };
        if(changeClient.Available[Number(key)-1].length === 17){
            changeClient.Available[Number(key)-1] = [];
        }else{
            changeClient.Available[Number(key)-1] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17'];
        }
        setClient(changeClient);
    };

    const [openUrlSendModal, setOpenUrlSendModal] = useState(false);
    const handleCloseUrlSend = () => {
        setOpenUrlSendModal(false);
        props.setSend({ Email: false, Kakao: false });
    };

    return (
        <FormContainer title='내담자 수정'>
            <TextForm
                label='내담자ID'
                required={true}
                error={false}
                value={client.NickName}
                placeholder='내담자ID를 입력해주세요.'
                handleChange={(e) => setClient({ ...client, NickName: e.target.value })}
            />
            <TextForm
                label='이름'
                required={false}
                error={false}
                value={client.Name}
                placeholder='이름을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, Name: e.target.value })}
            />
            <Grid container spacing={1} alignItems={'end'}>
                <Grid item xs={8}>
                    <EmailForm
                        label='이메일'
                        required={true}
                        disabled={client.EmailCheck}
                        error={!regEmail.test(client.Email) && client.Email !== ''}
                        errorMessage='이메일 형식이 일치하지 않습니다.'
                        value={client.Email}
                        handleChange={(e) => setClient({ ...client, Email: e.target.value })}
                    />
                </Grid>
                <Grid item xs={4}>
                    {!client.EmailCheck ?
                    <LargeSubmitButton
                        label='중복확인'
                        loading={props.emailLoading}
                        disabled={!regEmail.test(client.Email)}
                        handleClick={props.handleClickEmailCheck}
                    />:
                    <LargeCancelButton
                        label='사용취소'
                        handleClick={handleClickEmailCheckCancel}
                    />}
                </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid item pr={1}>
                    <Tooltip title='내담자 이메일로 신청서 링크가 발송되어 내담자가 직접 작성할 수 있게 됩니다. 이때, 내담자 ID를 입력해야 작성이 가능하기 때문에 내담자에게 ID를 안내해야 합니다.'>
                        <Help color='secondary'/>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} md={4} alignItems={'end'}>
                    <LargeSubmitButton 
                        label='URL 전송'
                        handleClick={() => setOpenUrlSendModal(true)}
                        alignItems={'end'}
                        mb='0px'
                    />
                </Grid>
            </Grid>
            <TextForm
                label='연락처'
                required={false}
                error={!regPhone.test(client.Phone) && client.Phone !== ''}
                errorMessage='연락처 형식이 올바르지 않습니다.' 
                value={client.Phone}
                placeholder='연락처를 입력해주세요.'
                handleChange={handleChangePhone}
            />
            <SelectForm
                label='담당자'
                required={false}
                error={false}
                cancel={true}
                value={client.CounselorId}
                datas={props.members}
                handleChange={(e) => setClient({ ...client, CounselorId: e.target.value })}
            />
            <SelectForm
                label='위험도'
                required={false}
                error={false}
                cancel={true}
                value={client.Risk}
                datas={Risk()}
                handleChange={(e) => setClient({ ...client, Risk: e.target.value })}
            />
            <SelectForm
                label='그룹'
                required={false}
                error={false}
                cancel={true}
                value={client.Group_id}
                datas={props.groups}
                handleChange={(e) => setClient({ ...client, Group_id: e.target.value })}
            />
            <TextForm
                label='현주소'
                required={false}
                error={false}
                value={client.Address}
                placeholder='현주소를 입력해주세요.'
                handleChange={(e) => setClient({ ...client, Address: e.target.value })}
            />
            <DateForm
                label='생년월일'
                required={false}
                error={false}
                value={client.Birth}
                handleChange={(e) => setClient({ ...client, Birth: e.target.value })}
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    성별
                </Grid>
                <RadioGroup row>
                    {Gender().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={(e) => setClient({ ...client, Gender: e.target.value })} control={<Radio checked={Number(client.Gender) === i+1}/>} label={item.label} />
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    결혼유무
                </Grid>
                <RadioGroup row>
                    {Marriage().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={(e) => setClient({ ...client, Marriage: e.target.value })} control={<Radio checked={Number(client.Marriage) === i+1} />} label={item.label} />
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    병역
                </Grid>
                <RadioGroup row>
                    {Military().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={(e) => setClient({ ...client, Military: e.target.value})} control={<Radio checked={Number(client.Military) === i+1}/>} label={item.label} />
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <TextForm
                label='최종학력'
                required={false}
                error={false}
                value={client.Education}
                placeholder='최종학력을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, Education: e.target.value })}
            />
            <SelectForm
                label='직업'
                required={false}
                error={false}
                cancel={true}
                value={client.Employment}
                datas={Employment()}
                handleChange={(e) => setClient({ ...client, Employment: e.target.value })}
            />
            {Number(client.Employment) === 9 ?
            <TextForm
                label='직업 상세'
                required={false}
                error={false}
                value={client.EmploymentDetail}
                placeholder='상세내용을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, EmploymentDetail: e.target.value })}
            />:null}
            <SelectForm
                label='주거형태'
                required={false}
                error={false}
                cancel={true}
                value={client.Living}
                datas={Living()}
                handleChange={(e) => setClient({ ...client, Living: e.target.value, LivingDetail: '' })}
            />
            {Number(client.Living) === 6 ?
            <TextForm
                label='주거형태 상세'
                required={false}
                error={false}
                value={client.LivingDetail}
                placeholder='상세내용을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, LivingDetail: e.target.value })}
            />:null}
            <NumberForm
                label='부양가족 수'
                required={false}
                error={false}
                value={client.Dependents}
                placeholder='부양가족 수를 입력해주세요.'
                handleChange={(e) => setClient({ ...client, Dependents: e.target.value })}
            />
            <SelectForm
                label='국적'
                required={false}
                error={false}
                cancel={true}
                value={client.Nationality}
                datas={Nationality()}
                handleChange={(e) => setClient({ ...client, Nationality: e.target.value, NationalityDetail: '' })}
            />
            {Number(client.Nationality) === 9 ?
            <TextForm
                label='국적 상세'
                required={false}
                error={false}
                value={client.NationalityDetail}
                placeholder='상세내용을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, NationalityDetail: e.target.value })}
            />:null}
            <TextForm
                label='종교'
                required={false}
                error={false}
                value={client.Religion}
                placeholder='종교를 입력해주세요.'
                handleChange={(e) => setClient({ ...client, Religion: e.target.value })}
            />
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={6}>
                    가족사항
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClickMinus} disabled={client.Family.length < 2}>
                        <Remove />
                    </IconButton>
                    <IconButton onClick={handleClickPlus}>
                        <Add />
                    </IconButton>
                </Grid>
                {client.Family.map((item, i) => (
                <Grid container item xs={12} spacing={1} key={i}>
                    <Grid item xs={6}>
                        <TextForm
                            label='관계'
                            required={false}
                            error={false}
                            value={item.Relation}
                            handleChange={(e) => handleChangeFamilyRelation(e, i)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextForm
                            label='연령'
                            required={false}
                            error={false}
                            value={item.Age}
                            handleChange={(e) => handleChangeFamilyAge(e, i)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextForm
                            label='이름'
                            required={false}
                            error={false}
                            value={item.Name}
                            handleChange={(e) => handleChangeFamilyName(e, i)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextForm
                            label='연락처'
                            required={false}
                            error={false}
                            value={item.Phone}
                            handleChange={(e) => handleChangeFamilyPhone(e, i)}
                        />
                    </Grid>
                </Grid>
                ))}
            </Grid>
            <SelectForm
                label='복용약물'
                required={false}
                error={false}
                cancel={true}
                value={client.Medication}
                datas={Medication()}
                handleChange={(e) => setClient({ ...client, Medication: e.target.value, MedicationDetail: '' })}
            />
            {client.Medication > 1 ? 
            <TextForm
                label='약물 명'
                required={false}
                error={false}
                value={client.MedicationDetail}
                placeholder='약물 명 및 복용양을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, MedicationDetail: e.target.value })}
            />
            :null}
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    찾아오게 된 경위
                </Grid>
                <RadioGroup row>
                    {Referral().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} control={<Radio checked={Number(client.Referral) === i+1} />} label={item.label} onChange={(e) => setClient({ ...client, Referral: e.target.value, ReferralDetail: '' })}/>
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {Number(client.Referral) === 4 ? 
            <TextForm
                label='상세 내용'
                required={false}
                error={false}
                value={client.ReferralDetail}
                placeholder='찾아오게 된 경위에 대해 상세 내용을 입력해주세요.'
                handleChange={(e) => setClient({ ...client, ReferralDetail: e.target.value })}
            />
            :null}
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    찾아오게 된 목적
                </Grid>
                <RadioGroup row>
                    {DesiredService().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeDesiredService} control={<Checkbox checked={client.DesiredService.indexOf(i+1) > -1 || client.DesiredService.indexOf(`${i+1}`) > -1}/>} label={item.label} />    
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    상담 받고 싶은 점
                </Grid>
                <FormGroup row>
                    {Reason().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeReason} control={<Checkbox checked={client.Reason.indexOf(i+1) > -1 || client.Reason.indexOf(`${i+1}`) > -1}/>} label={item.label} />    
                    ))} 
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <TextForm
                label='현재 고민이나 어려움'
                required={false}
                error={false}
                value={client.Concern}
                placeholder='구체적으로 작성해주세요.'
                handleChange={(e) => setClient({ ...client, Concern: e.target.value })}
                rows='8'
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    받고 싶은 검사
                </Grid>
                <FormGroup row>
                    {DesiredTest().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeDesiredTest} control={<Checkbox checked={client.DesiredTest.indexOf(i+1) > -1 || client.DesiredTest.indexOf(`${i+1}`) > -1}/>} label={item.label} />    
                    ))} 
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    이전 상담 여부
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={false} onChange={() => setClient({ ...client, PriorTreatment: { PriorExperience: false, When: '', Where: '', How: [] }})} control={<Radio checked={!client.PriorTreatment.PriorExperience}/>} label={'없음'} />
                    <FormControlLabel value={true} onChange={() => setClient({ ...client, PriorTreatment: { PriorExperience: true, When: '', Where: '', How: [] }})} control={<Radio checked={client.PriorTreatment.PriorExperience}/>} label={'있음'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {client.PriorTreatment.PriorExperience ?
            <>
            <TextForm
                label='언제'
                required={false}
                error={false}
                value={client.PriorTreatment.When}
                handleChange={handleChangeWhen}
            />
            <TextForm
                label='어디서'
                required={false}
                error={false}
                value={client.PriorTreatment.Where}
                handleChange={handleChangeWhere}
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    치료 유형
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={1} onChange={handleChangeHow} control={<Checkbox checked={client.PriorTreatment.How.indexOf(`1`) > -1} />} label={'상담'} />
                    <FormControlLabel value={2} onChange={handleChangeHow} control={<Checkbox checked={client.PriorTreatment.How.indexOf(`2`) > -1} />} label={'심리검사'} />
                    <FormControlLabel value={3} onChange={handleChangeHow} control={<Checkbox checked={client.PriorTreatment.How.indexOf(`3`) > -1} />} label={'약물치료'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            </>:
            null}
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    상담가능한 요일
                </Grid>
                <FormGroup row>
                    {PossibleDay().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeAvailableDay} control={<Checkbox checked={client.AvailableDay.indexOf(item.value) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={handleChangeAllDay} control={<Checkbox checked={client.AvailableDay.length === 7} />} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {client.AvailableDay.map((day, i) => (
            <FormControl fullWidth key={i}>
                <Grid style={{ marginBottom: '8px' }}>
                    {AvailableDay(day)}요일 상담가능한 시간
                </Grid>
                <FormGroup row>
                    {PossibleTime().map((item, j) => (
                    <FormControlLabel key={j} value={item.value} onChange={(e) => handleChangeAvailable(e, day)} control={<Checkbox checked={client.Available[Number(day)-1].indexOf(item.value) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={() => handleChangeAvailableAll(day)} control={<Checkbox checked={client.Available[Number(day)-1].length === 17}/>} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            ))}
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton 
                        label='수정'
                        loading={props.updating}
                        disabled={!Boolean(client.EmailCheck) || !Boolean(client.Email) || !Boolean(client.NickName)}
                        handleClick={props.handleClickUpdateBtn}
                    />
                </Grid>
            </Grid>
            <FormModal
                title='URL 전송'
                open={openUrlSendModal}
                handleClose={() => handleCloseUrlSend()}
                submitLabel='확인'
                handleClickSubmit={() => { props.handleClickUrlSendBtn('basic');handleCloseUrlSend(); }}
                disabled={false}
            >
                <FormControl fullWidth>
                    <FormGroup>
                        <FormControlLabel 
                            value={props.send.Email}
                            onChange={() => props.setSend({ ...props.send.Email, Email: !props.send.Email })}
                            control={<Checkbox checked={props.send.Email}/>}
                            label={`이메일 전송 (${client.Email})`}
                        />
                        <FormControlLabel 
                            value={props.send.Kakao}
                            onChange={() => props.setSend({ ...props.send, Kakao: !props.send.Kakao })}
                            disabled={true}
                            // disabled={!Boolean(props.client.Phone_decrypt) || !Boolean(props.client.Phone_decrypt.indexOf('010') > -1)}
                            control={<Checkbox checked={props.send.Kakao}/>}
                            label={`카카오톡 전송 ${client.Phone}`}
                        />
                    </FormGroup>
                    <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                </FormControl>
            </FormModal>
        </FormContainer>
    );
};

export default ClientUpdateView;