import React from 'react';
import { LoadingButton } from '@mui/lab';

/**
 * 
 * @param {string} label 
 * @param {boolean} disabled
 * @param {function} handleClick
 * @returns 
 */
const LargeCancelButton = (props) => {
    return(
        <LoadingButton
            variant='outlined'
            disableElevation
            fullWidth={true}
            size='large'
            style={{ borderRadius: '10px', marginBottom: '24px', height: '56px', fontWeight: 'bold', fontSize: '18px' }}
            disabled={props.disabled}
            onClick={props.handleClick}
        >
            {props.label}
        </LoadingButton>
    );
};

export default LargeCancelButton;