import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { GroupListView } from 'components/User/Manage/Group';
import { useNavigate } from 'react-router-dom';

const GroupListContainer = (props) => {
    const pages = [
        { name: '그룹 관리', link: '' },
        { name: '그룹 목록', link: '' }
    ];

    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [groups, setGroups] = useState([]);
    const [selectGroup, setSelectGroup] = useState({});

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/group/${selectGroup.GroupNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/group/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadGroups = [...res.data.GroupDatas];
                for(let i = 0; i < loadGroups.length; i++){
                    loadGroups[i].No = i + 1;
                    loadGroups[i].ClientCnt = `${loadGroups[i].Client_ids.length} 명`;
                }
                setGroups(loadGroups);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setGroups, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <GroupListView 
                groups={groups}
                selectGroup={selectGroup}
                setSelectGroup={setSelectGroup}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default GroupListContainer;