import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { ScaleListView } from 'components/Admin/Scale';
import { YyyyMmDdHhMmSs, ScaleState, ScaleKind } from 'components/Function/ChangeText';

const ScalesListContainer = (props) => {
    const pages = [
        { name: '검사', link: '' },
        { name: '검사 목록', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [scales, setScales] = useState([]);

    const [selectScale, setSelectScale] = useState({});

    const handleClickScaleDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/scale/${selectScale.ScaleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/scale/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadScales = [...res.data.ScaleDatas];
                for(let i = 0; i < loadScales.length; i++){
                    loadScales[i].No = i + 1;
                    loadScales[i].CreatedAtValue = YyyyMmDdHhMmSs(loadScales[i].createdAt);
                    loadScales[i].ScaleKindValue = ScaleKind(loadScales[i].ScaleKind);
                    loadScales[i].StateValue = ScaleState(loadScales[i].State);
                }
                setScales(loadScales);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, enqueueSnackbar, setScales]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScaleListView
                scales={scales}
                selectScale={selectScale}
                setSelectScale={setSelectScale}
                handleClickScaleDeleteBtn={handleClickScaleDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default ScalesListContainer;