import React from 'react';
import { Grid } from '@mui/material';

import LoginBackgroundImage from 'images/LoginBackground.png';

const LoginLayout = (props) => {
    return(
        <Grid
            style={{
                backgroundImage: `url('${LoginBackgroundImage}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundAttachment: 'fixed',
                minHeight: '100vh',
                width: '100%',
                position: 'relative'
            }}
        >
            <Grid 
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    zIndex: 1
                }}
            />
            {props.children}
        </Grid>
    );
};

export default LoginLayout;