import React, { useState } from 'react';
import { Grid, Checkbox, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ListContainer } from 'components/Items/Container';
import { SearchForm, TextForm } from 'components/Items/Form';
import { DataTable } from 'components/Items/Table';
import { ConfirmModal, FormModal } from 'components/Items/Modal';

const ContactListView = (props) => {
    const { contacts, selectContact, setSelectContact } = props;
    const navigate = useNavigate();
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'Name', label: '이름'},
                        { key: 'Phone', label: '연락처'}, 
                        { key: 'Email', label: '이메일'}, 
                        { key: 'ContentValue', label: '내용'}, 
                        { key: 'IsReplyValue', label: '회신여부'}, 
                        { key: 'CreatedAtValue', label: '문의 일시'}];

    const [search, setSearch] = useState('');
    const [checked, setChecked] = useState(false);
    const [openContactDetailModal, setOpenContactDetailModal] = useState(false);
    const [openContactDeleteModal, setOpenContactDeleteModal] = useState(false);

    const filterDatas = (datas) => {
        if(checked){
            return datas.filter((item) => (item.IsReplyValue === 'X'));
        }else{
            return datas;
        }
    };
    const handleClickDetail = (data) => {
        props.setSelectContact(data);
        setOpenContactDetailModal(true);
    };
    const handleClickDelete = (data) => {
        props.setSelectContact(data);
        setOpenContactDeleteModal(true);
    };

    return (
        <>
        <ListContainer title='문의 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid container item xs={12} justifyContent={'flex-end'} alignItems={'center'} textAlign={'right'}>
                        <Grid item>
                            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                        </Grid>
                        <Grid item>
                            <span className='hover_pointer' onClick={() => setChecked(!checked)}>미회신</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys}
                    datas={filterDatas(contacts)}
                    delete={true}
                    update={false}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
        </ListContainer>
        {selectContact.ContactNo ?
        <FormModal
            title='문의 상세'
            open={openContactDetailModal}
            handleClose={() => setOpenContactDetailModal(false)}
            submitLabel={selectContact.IsReply ? '수정' : '완료'}
            handleClickSubmit={() => {setOpenContactDetailModal(false);props.handleClickUpdateBtn();}}
            disabled={!Boolean(selectContact.Reply)}
        >
            <Grid container style={{ marginBottom: '16px' }}>
                <Grid item xs={12}>
                    <p>이름</p>
                    <div className='div_data_value'>{selectContact.Name}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>연락처</p>
                    <div className='div_data_value'>{selectContact.Phone}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>이메일</p>
                    <div className='div_data_value'>{selectContact.Email}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>문의 일시</p>
                    <div className='div_data_value'>{selectContact.CreatedAtValue}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>문의 내용</p>
                    <div className='div_data_value'
                        dangerouslySetInnerHTML={{ __html: selectContact.Content?.replaceAll('\n', '<br/>') }}
                    />
                </Grid>
            </Grid>
            <Divider />
            {selectContact.IsReply ?
            <Grid container>
                <Grid item xs={12}>
                    <p>답장 일시</p>
                    <div className='div_data_value'>{selectContact.ReplyAt}</div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <TextForm
                        label='메모'
                        required={true}
                        error={false}
                        rows={5}
                        value={selectContact.Reply}
                        handleChange={(e) => setSelectContact({ ...selectContact, Reply: e.target.value })}
                    />
                </Grid>
            </Grid>:
            <Grid container style={{ marginTop: '16px' }}>
                <TextForm
                    label='메모'
                    required={true}
                    error={false}
                    rows={5}
                    value={selectContact.Reply}
                    handleChange={(e) => setSelectContact({ ...selectContact, Reply: e.target.value })}
                />
            </Grid>}
        </FormModal>:null}
        {selectContact.ContactNo ?
        <ConfirmModal
            title='문의 삭제'
            icon='error'
            open={openContactDeleteModal}
            handleClose={() => setOpenContactDeleteModal(false)}
            handleClickSubmit={() => {setOpenContactDeleteModal(false);props.handleClickDeleteBtn();}}
            submitLabel='삭제'
        >
            문의 정보를 삭제 하시겠습니까? 문의 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default ContactListView;