import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { TextForm } from 'components/Items/Form';
import { LargeSubmitButton, SmallCancelButton, SmallSubmitButton } from 'components/Items/Button';
import { ItemShortForm, ItemMultipleChoiceForm, ItemLikertForm } from './Items/Form';

const ScaleSessionView = (props) => {
    const [nickName, setNickName] = useState('');
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const scale = props.scale.Scales.filter((a) => (a?.Scale_id.ScaleNo === Number(props.scaleNo)))[0]?.Scale_id;
    const handleClickSave = (state) => {
        setSaving(true);
        props.handleClickSave();
        setTimeout(() => {
            if(state === 1){
                setSaving(false);
            }else{
                window.close();
            }
        }, [1000]);
    };

    const handleClickStartBtn = () => {
        setLoading(true);
        setTimeout(() => {
            if(nickName === props.scale.NickName){
                setCheck(true);
                enqueueSnackbar('검사를 시작합니다.', { variant: 'info' });
            }else{
                enqueueSnackbar('닉네임이 일치하지 않습니다.', { variant: 'error' });
            }
        setLoading(false);
        }, [800]);
    };

    const handleChangeAnswer = (e, key, mc) => {
        let changeAnswer = { ...props.scale };
        let firstData = true;
        for(let i = 0; i < changeAnswer.ScaleAnswer.length; i++){
            for(let j = 0; j < changeAnswer.ScaleAnswer[i].Answer.length; j++){
                if(changeAnswer.ScaleAnswer[i].Answer[j].Item_id === key){
                    if(mc){
                        if(changeAnswer.ScaleAnswer[i].Answer[j].Answer.indexOf(e.target.value) > -1){
                            changeAnswer.ScaleAnswer[i].Answer[j].Answer.splice(changeAnswer.ScaleAnswer[i].Answer[j].Answer.indexOf(e.target.value), 1);
                        }else{
                            changeAnswer.ScaleAnswer[i].Answer[j].Answer.push(String(e.target.value));
                        }
                    }else{
                        changeAnswer.ScaleAnswer[i].Answer[j] = { Item_id: key, Answer: [e.target.value] };
                    }
                    firstData = false;
                    break;
                }
            }
        }
        let stop = false;
        if(firstData){
            for(let i = 0; i < changeAnswer.ScaleAnswer.length; i++){
                for(let j = 0; j < changeAnswer.Scales.length; j++){
                    if(changeAnswer.ScaleAnswer[i].Scale_id === changeAnswer.Scales[j].Scale_id._id){
                        if(changeAnswer.Scales[j].Scale_id.Item_ids.filter((item) => (item._id === key))[0]){
                            changeAnswer.ScaleAnswer[i].Answer.push({ Item_id: key, Answer: [e.target.value] });
                            stop = true;
                            break;
                        }
                    }
                }
                if (stop) break;
            }
        }
        console.log(changeAnswer)
        props.setScale(changeAnswer);
    };

    return(
        <>
        <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{
                minHeight: '100vh',
                paddingLeft: '1em',
                paddingRight: '1em',
                position: 'relative',
                zIndex: '2'
            }}
        >
        {!check ?
            <Grid
                xs={12} sm={10} md={8} lg={6} xl={4}
                item
                style={{
                    backgroundColor: '#FFF',
                    borderRadius: '30px',
                    padding: '60px 4%'
                }}
            >
                <h1 style={{ textAlign: 'center' }}>검사를 진행합니다.</h1>
                <h3 style={{ textAlign: 'center', fontWeight: 'normal' }}>&gt; 검사 이름: <b>{scale?.Label}</b></h3>
                <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>신청시 안내받은 <b>내담자ID</b>를 입력해 주세요.</h4>
                <p style={{ textAlign: 'center' }}>(Please enter your client ID)</p>
                <p style={{ textAlign: 'center' }}>(ID가 기억나지 않으면 이용중인 상담센터로 문의해주세요)</p>
                <TextForm
                    label=''
                    required={true}
                    value={nickName}
                    handleChange={(e) => setNickName(e.target.value)}
                />
                <LargeSubmitButton
                    label='시작하기'
                    disabled={!Boolean(nickName)}
                    loading={loading}
                    handleClick={handleClickStartBtn}
                />
            </Grid>:
            props.scale.Scales.map((item, i) => (
                Number(props.scaleNo) === item.Scale_id.ScaleNo ?
                <Grid
                    item
                    xs={12}
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '30px',
                        padding: '60px 4%',
                        width: '100%'
                    }}
                    key={i}
                >
                    <h1 style={{ textAlign: 'center'}}>{item.Scale_id.Label}</h1>
                    <p>{item.Scale_id.Detail}</p>
                    {item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                    <Grid key={j} style={{ marginBottom: '8px' }}>
                        {q.ItemKind === 1 ?
                        <ItemShortForm 
                            item={q}
                            no={j+1}
                            scale={item.Scale_id}
                            subScale={
                                q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)
                            }
                            lastItem={item.Scale_id.Item_ids.length === j+1}
                            answer={props.scale.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            handleChange={handleChangeAnswer}
                        /> :
                        q.ItemKind === 2 ?
                        <ItemMultipleChoiceForm 
                            item={q}
                            no={j+1}
                            scale={item.Scale_id}
                            subScale={
                                q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)
                            }
                            lastItem={item.Scale_id.Item_ids.length === j+1}
                            answer={props.scale.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            handleChange={handleChangeAnswer}
                        /> :
                        q.ItemKind === 3 ?
                        <ItemLikertForm
                            item={q}
                            no={j+1}
                            scale={item.Scale_id}
                            subScale={
                                q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)
                            }
                            lastItem={item.Scale_id.Item_ids.length === j+1}
                            answer={props.scale.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            handleChange={handleChangeAnswer}    
                        /> :
                        null}
                    </Grid>
                    ))}
                    {/* <Grid container justifyContent={'flex-end'} spacing={1}>
                        <Grid item>
                            <SmallCancelButton
                                label='임시 저장'
                                handleClick={() => handleClickSave(1)}
                                disabled={saving}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid style={{ marginTop: '64px', borderBottom: '1px solid #E2E2E2'}}/>
                </Grid>:
                null
            ))}
            {check ?
            <Grid
                xs={12}
                item
                style={{
                    backgroundColor: '#FFF',
                    borderRadius: '30px',
                    padding: '0px 4% 80px 4%',
                    width: '100%'
                }}
            >
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <SmallSubmitButton
                            label='완료'
                            handleClick={() => handleClickSave(2)}
                            loading={saving}
                            disabled={!(props.scale.ScaleAnswer.filter((a) => (a.Scale_id === scale._id))[0].Answer.length === scale.Item_ids.length)}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <br/>
                        {(props.scale.ScaleAnswer.filter((a) => (a.Scale_id === scale._id))[0].Answer.length === scale.Item_ids.length) ? '' : '* 응답하지 않은 문항이 있는지 확인하시고 완료해 주세요.'}
                    </Grid>
                </Grid>
            </Grid>:null}
        </Grid>
        </>
    );
};

export default ScaleSessionView;