import React from 'react';
import { FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

/**
 * 
 * @param {string} value  
 * @param {function} handleChange
 */
const SearchForm = (props) => {
    return (
        <FormControl fullWidth >
            <OutlinedInput
                value={props.value}
                onChange={props.handleChange}
                startAdornment={
                    <InputAdornment position='start'>
                        <Search />
                    </InputAdornment>
                }
                placeholder='검색'
                size='small'
            />
        </FormControl>
    );
};

export default SearchForm;