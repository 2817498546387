import React, { useState } from 'react';
import { Grid, Box, Tabs, Tab } from '@mui/material';
import { useCookies } from 'react-cookie';

import { ListContainer } from 'components/Items/Container';
import { SearchForm, SelectForm,TextForm } from 'components/Items/Form';
import { SmallSubmitButton } from 'components/Items/Button';
import { DataTable } from 'components/Items/Table';
import { FormModal, ConfirmModal, DetailModal } from 'components/Items/Modal';
import { MemberStatus } from 'components/Function/ChangeText';

import { useSelector, useDispatch } from 'react-redux';
import { decrement } from 'reducer/messegeReducer';

const MessageListView = (props) => {
    const [cookies] = useCookies(['UserNo']);
    const keys = [[{ key: 'No', label: 'No.'},
                        { key: 'SenderName', label: '발신'},
                        { key: 'Title', label: '제목'},
                        { key: 'CreatedAtValue', label: '발신 일시'},
                        { key: 'ReadValue', label: '메시지 확인'}],
                        [{ key: 'No', label: 'No.'},
                        { key: 'ReciverName', label: '수신'},
                        { key: 'Title', label: '제목'},
                        { key: 'CreatedAtValue', label: '발신 일시'},
                        { key: 'ReadValue', label: '메시지 확인'}],
                        [{ key: 'No', label: 'No.'},
                        { key: 'SenderName', label: '발신'},
                        { key: 'ReciverName', label: '수신'},
                        { key: 'Title', label: '제목'},
                        { key: 'CreatedAtValue', label: '발신 일시'},
                        { key: 'DeletedAtValue', label: '삭제 일시'},
                        { key: 'ReadValue', label: '메시지 확인'}]];

    const [search, setSearch] = useState('');
    const [tab, setTab] = useState(0);
    const [selectMessage, setSelectMessage] = useState({
        Title: '',
        Content: '',
        CreatedAtValue: '',
        Reciver_id: { Name: '', Status: '' },
        Sender_id: { Name: '', Status: '' }
    });
    const [openMessageDetailModal, setOpenMessageDetailModal] = useState(false);
    const [openMessageCreateModal, setOpenMessageCreateModal] = useState(false);
    const [openMessageRestoreModal, setOpenMessageRestoreModal] = useState(false);
    const [openMessageDeleteModal, setOpenMessageDeleteModal] = useState(false);
    const [openMessageHardDeleteModal, setOpenMessageHardDeleteModal] = useState(false);

    const count = useSelector((state) => state.counter.value);
    const dispatch = useDispatch();

    const handleClickDetail = (data) => {
        setSelectMessage(data);
        setOpenMessageDetailModal(true);
        if(!data.Read){
            props.handleClickDetailBtn(data);            
        }
        dispatch(decrement());
    };

    const handleClickHardDelete = (data) => {
        setSelectMessage(data);
        setOpenMessageHardDeleteModal(true);
    };

    const handleClickDelete = (data) => {
        setSelectMessage(data);
        setOpenMessageDeleteModal(true);
    };

    const handleClickRestore = (data) => {
        setSelectMessage(data);
        setOpenMessageRestoreModal(true);
    };

    const filterDatas = (datas) => {
        return datas.filter((item) => {
            if(tab === 0){
                return item.Reciver_id.UserNo === Number(cookies.UserNo) && !Boolean(item.DeletedAtValue !== '-');
            }else if(tab === 1){
                return item.Sender_id.UserNo === Number(cookies.UserNo) && !Boolean(item.DeletedAtValue !== '-');
            }else{
                return Boolean(item.DeletedAtValue !== '-') && !(new Date(new Date(item.DeletedAtValue).setDate(new Date(item.DeletedAtValue).getDate()+30)) < new Date());
            }
        });
    };

    return(
        <>
        <ListContainer title='메시지 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} justifyContent={'flex-end'} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={6} textAlign={'right'}>
                        <SmallSubmitButton 
                            label='메시지 보내기'
                            handleClick={() => setOpenMessageCreateModal(true)}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)}>
                            <Tab label='받은 메시지' tabIndex={0}/>
                            <Tab label='보낸 메시지' tabIndex={1}/>
                            <Tab label='삭제된 메시지' tabIndex={2}/>
                        </Tabs>
                    </Box>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys[tab]}
                    datas={filterDatas(props.messages)}
                    delete={true}
                    button={tab === 2 ? true : false}
                    buttonLabel='복구'
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickDelete={tab === 2 ? handleClickHardDelete : handleClickDelete}
                    handleClickButton={tab === 2 ? handleClickRestore : null}
                />
            </Grid>
        </ListContainer>
        <DetailModal
            title='메시지 상세 보기'
            open={openMessageDetailModal}
            handleClose={() => setOpenMessageDetailModal(false)}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <p>제목</p>
                    <div className='div_data_value'>{selectMessage.Title}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>발신일</p>
                    <div className='div_data_value'>{selectMessage.CreatedAtValue}</div>
                </Grid>       
                <Grid item xs={6}>
                    <p>발신</p>
                    <div className='div_data_value'>{selectMessage.Sender_id.Name} ({MemberStatus(selectMessage.Sender_id.Status)})</div>
                </Grid>
                <Grid item xs={6}>
                    <p>수신</p>
                    <div className='div_data_value'>{selectMessage.Reciver_id.Name} ({MemberStatus(selectMessage.Reciver_id.Status)})</div>
                </Grid>
                <Grid item xs={12}>
                    <p>내용</p>
                    <div className='div_data_value' dangerouslySetInnerHTML={{ __html: selectMessage.Content.replaceAll('\n', '<br/>') }}></div>
                </Grid>
            </Grid>
        </DetailModal>
        <FormModal
            open={openMessageCreateModal}
            handleClose={() => setOpenMessageCreateModal(false)}
            title={'메시지 보내기'}
            submitLabel={'전송'}
            handleClickSubmit={() => {props.handleClickSendBtn();setOpenMessageCreateModal(false);}}
            disabled={!Boolean(props.sendMessage.Reciver_id) || !Boolean(props.sendMessage.Title) || !Boolean(props.sendMessage.Content)}
        >
            <SelectForm
                label='수신자'
                required={true}
                error={false}
                value={props.sendMessage.Reciver_id}
                datas={props.members}
                handleChange={(e) => props.setSendMessage({ ...props.sendMessage, Reciver_id: e.target.value })}
            />
            <TextForm
                label='제목'
                required={true}
                error={false}
                value={props.sendMessage.Title}
                placeholder='제목을 입력해주세요.'
                handleChange={(e) => props.setSendMessage({ ...props.sendMessage, Title: e.target.value })}
            />
            <TextForm
                label='내용'
                required={false}
                error={false}
                rows={8}
                value={props.sendMessage.Content}
                placeholder='내용을 입력해주세요.'
                handleChange={(e) => props.setSendMessage({ ...props.sendMessage, Content: e.target.value })}
            />     
        </FormModal>
        <ConfirmModal
            title='메시지 삭제'
            icon='error'
            open={openMessageDeleteModal}
            handleClose={() => setOpenMessageDeleteModal(false)}
            handleClickSubmit={() => {props.handleClickDeleteBtn(selectMessage);setOpenMessageDeleteModal(false);}}
            submitLabel='삭제'
        >
            메시지를 삭제 하시겠습니까? 삭제된 메시지는 30일 이후 자동으로 삭제됩니다.
        </ConfirmModal>
        <ConfirmModal
            title='메시지 복구'
            icon='info'
            open={openMessageRestoreModal}
            handleClose={() => setOpenMessageRestoreModal(false)}
            handleClickSubmit={() => {props.handleClickRestoreBtn(selectMessage);setOpenMessageRestoreModal(false);}}
            submitLabel='복구'
        >
            메시지를 복구 하시겠습니까? 복구된 메시지는 보관함으로 이동합니다.
        </ConfirmModal>
        <ConfirmModal
            title='메시지 영구 삭제'
            icon='error'
            open={openMessageHardDeleteModal}
            handleClose={() => setOpenMessageHardDeleteModal(false)}
            handleClickSubmit={() => {props.handleClickHardDeleteBtn(selectMessage);setOpenMessageHardDeleteModal(false);}}
            submitLabel='삭제'
        >
            메시지를 영구 삭제 하시겠습니까? 영구 삭제된 메시지는 복구할 수 없습니다.
        </ConfirmModal>
        </>
    );
};

export default MessageListView;