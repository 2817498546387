import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { RoomCreateView } from 'components/User/Manage/Room';

const RoomCreateContainer = (props) => {
    const pages = [
        { name: '상담실 관리', link: '/manage/room' },
        { name: '상담실 생성', link: '' }
    ];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [creating, setCreating] = useState(false);
    const [room, setRoom] = useState({
        Name: '',
        State: 2
    });

    const handleClickCreateBtn = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(room);
        await axios.post(`/api/manager/room/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/manage/room/${res.data.Result.RoomNo}`);
                }, [1200]);
            }else{
                setCreating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <RoomCreateView
                room={room}
                setRoom={setRoom}
                creating={creating}
                handleClickCreateBtn={handleClickCreateBtn}
            />
        </DashboardLayout>
    );
};

export default RoomCreateContainer;