import React, { useState } from 'react';
import { Grid, Box, Tabs, Tab } from '@mui/material';

import { DetailContainer, ListContainer } from 'components/Items/Container';
import { DataBox } from 'components/Items/Table';
import { ProfileView, BasicView, SessionView, GoalView, ChartView, SummaryView } from 'components/User/Manage/Client/Session';
import { GenderKo, NationalityKo, MarriageKo, MilitaryKo, EmploymentKo, LivingKo, UserStatus } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime } from 'components/Function/AddDatas';

const SuperviseeDetailView = (props) => {
    const [tab, setTab] = useState(0);
    const datas = [
        { key: '이름', value: props.client.Counselor_id.Name },
        { key: '이메일', value: props.client.Counselor_id.Email },
        { key: '연락처', value: props.client.Counselor_id.Phone },
        { key: '권한', value: UserStatus(props.client.Counselor_id.Status) }
    ];

    const datas2 = [
        { key: '이름', value: props.client.Name },
        { key: '내담자ID', value: props.client.NickName },
        { key: '이메일', value: props.client.Email },
        { key: '연락처', value: props.client.Phone },
        { key: '현 주소', value: props.client.Address },
        { key: '생년월일', value: props.client.Birth },
        { key: '성별', value: GenderKo(props.client.Gender) },
        { key: '국적', value: props.client.Nationality !== 9 ? NationalityKo(props.client.Nationality) : props.client.NationalityDetail },
        { key: '종교', value: props.client.Religion },
        { key: '결혼유무', value: MarriageKo(props.client.Marriage) },
        { key: '병역', value: MilitaryKo(props.client.Military) },
        { key: '최종학력', value: props.client.Education },
        { key: '직업', value: props.client.Employment !== 9 || !props.client.EmploymentDetail ? EmploymentKo(props.client.Employment) : props.client.EmploymentDetail },
        { key: '주거 형태', value: props.client.Living !== 6 ? LivingKo(props.client.Living) :props.client.LivingDetail },
        { key: '부양가족 수', value: props.client.Dependents || props.client.Dependents === 0 ? `${props.client.Dependents} 명` : '' },
        { key: '상담자', value: props.client.Counselor_id ? props.client.Counselor_id.Name : '' },
        { key: '신청일', value: props.client.CreatedAtValue }
    ];
    return (
        <>
        <DetailContainer
            title='상담자 정보'
        >
            <DataBox
                datas={datas}
            />
        </DetailContainer>
        <DetailContainer
            title='내담자 정보'
        >
            <DataBox
                datas={datas2}
            />
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            희망 상담시간
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <Grid container style={{ textAlign: 'center', borderBottom: '1px solid #C0C0C0', fontSize: '80%' }}>
                                <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                </Grid>
                                {PossibleDay().map((day, i) => (
                                <Grid item xs={12/8} key={i} style={{ borderRight: i < 6 ? '1px solid #C0C0C0' : '0px' }}>
                                    {day.label.replace('요일', '')}
                                </Grid>
                                ))}
                            </Grid>
                            {PossibleTime().map((time, i) => (
                            <Grid container key={i} style={{ textAlign: 'center', borderBottom: i < 17 ? '1px solid #C0C0C0' : '0px', fontSize: '80%' }}>
                                <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                    {time.label.split('~')[0].replace(':00 ', '시')}
                                </Grid>
                                {PossibleDay().map((day, j) => (
                                <Grid
                                    item
                                    xs={12/8}
                                    key={j}
                                    style={{ 
                                        backgroundColor: 
                                            props.client.AvailableDay.indexOf(day.value) > -1 && props.client.Available[Number(day.value)-1].indexOf(time.value) > -1 ?
                                            'rgba(161, 216, 160, 0.7)' : 
                                            '', 
                                        borderRight: j < 6 ? '1px solid #C0C0C0' : '0px'
                                    }}
                                />
                                ))}
                            </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
                        <Grid item xs={12}>
                            <h3>긴급 연락처</h3>
                        </Grid>
                        <Grid item xs={12} className='table_normal'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>관계</th><th>연령</th><th>이름</th><th>연락처</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.client.Family[0]?.Name ?
                                    props.client.Family.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ borderRadius: '0px 0px 0px 8px' }}>{item.Relation}</td><td>{item.Age}</td><td>{item.Name}</td><td>{item.Phone}</td>
                                    </tr>
                                    )):
                                    <tr>
                                        <td style={{ borderRadius: '0px 0px 8px 8px' }} colSpan={4}>긴급 연락처가 없습니다.</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DetailContainer>
        <ListContainer>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ marginBottom: '16px', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)}>
                            <Tab label='프로필' tabIndex={0}/>
                            <Tab label='기본서류' tabIndex={1}/>
                            <Tab label='상담' tabIndex={2}/>
                            {/* <Tab label='목표' tabIndex={3}/> */}
                            <Tab label='차트' tabIndex={3}/>
                            <Tab label='요약' tabIndex={4}/>
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                    tab === 0 ?
                    <ProfileView 
                        client={props.client}
                    /> :
                    tab === 1 ?
                    <BasicView 
                        client={props.client}
                        basicScale={props.client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3))[0]}
                    /> :
                    tab === 2 ? 
                    <SessionView 
                        client={props.client}
                        setClient={props.setClient}
                        selectSession={props.selectSession}
                        setSelectSession={props.setSelectSession}
                    /> :
                    // tab === 3 ?
                    // <GoalView 
                    //     goals={props.goals}
                    //     selectGoal={props.selectGoal}
                    //     setSelectGoal={props.setSelectGoal}
                    // /> :
                    tab === 3 ?
                    <ChartView 
                        client={props.client}
                        goals={props.goals}
                    /> :
                    <SummaryView 
                        goals={props.goals}
                        client={props.client}
                    />
                    } 
                </Grid>
            </Grid>
        </ListContainer>
        </>
    );
};

export default SuperviseeDetailView;