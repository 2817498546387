import React from 'react';
import { LoadingButton } from '@mui/lab';

/**
 * 
 * @param {string} label 
 * @param {boolean} loading
 * @param {boolean} disabled
 * @param {function} handleClick
 * @returns 
 */
const SmallSubmitButton = (props) => {
    return(
        <LoadingButton
            variant='contained'
            disableElevation
            style={{ borderRadius: '5px', height: '40px' }}
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.handleClick}
        >
            {props.label}
        </LoadingButton>
    );
};

export default SmallSubmitButton;