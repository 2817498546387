import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { ContactListView } from 'components/Admin/Contact';
import { YyyyMmDd, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const ContactListContainer = (props) => {
    const pages = [
        { name: '문의', link: '' },
        { name: '문의 목록', link: '' }
    ];
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [contacts, setContacts] = useState([]);
    const [selectContact, setSelectContact] = useState({});

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/contact/${selectContact.ContactNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickUpdateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(selectContact);
        await axios.put(`/api/admin/contact/${selectContact.ContactNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/contact/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadContacts = [...res.data.ContactDatas];
                for(let i = 0; i < loadContacts.length; i++){
                    loadContacts[i].No = i + 1;
                    loadContacts[i].ContentValue = loadContacts[i].Content?.substring(0, 12);
                    loadContacts[i].IsReplyValue = loadContacts[i].IsReply ? 'O' : 'X';
                    loadContacts[i].CreatedAtValue = YyyyMmDdHhMmSs(loadContacts[i].createdAt);
                }
                setContacts(res.data.ContactDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, []);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ContactListView
                contacts={contacts}
                selectContact={selectContact}
                setSelectContact={setSelectContact}
                handleClickUpdateBtn={handleClickUpdateBtn}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default ContactListContainer;