import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { GroupUpdateView } from 'components/User/Manage/Group';

const GroupUpdateContainer = (props) => {
    const { groupNo } = useParams();
    const pages = [
        { name: '그룹 관리', link: '/manage/group' },
        { name: '그룹 상세', link: `/manage/group/${groupNo}` },
        { name: '그룹 수정', link: '' }
    ];

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);

    const [group, setGroup] = useState({
        Name: ''
    });
    const [updating, setUpdating] = useState(false);

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(group);
        await axios.put(`/api/manager/group/${groupNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/manage/group/${groupNo}`);
                }, [1200]);
            }else{
                setUpdating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setUpdating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/group/${groupNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setGroup(res.data.GroupData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [groupNo, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <GroupUpdateView
                group={group}
                setGroup={setGroup}
                updating={updating}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default GroupUpdateContainer;