import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { ContactView } from 'components/User';

const ContactContainer = (props) => {
    const pages = [
        { name: '문의하기', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [user, setUser] = useState({});

    const handleClickSendBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(user);
        await axios.post(`/api/contact`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setUser({ ...user, Content: '' });
                enqueueSnackbar(res.data.Message, { variant: 'info' })
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/user/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setUser(res.data.UserData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ContactView 
                user={user}
                setUser={setUser}
                handleClickSendBtn={handleClickSendBtn}
            />
        </DashboardLayout>
    );
};

export default ContactContainer;