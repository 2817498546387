import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { MessageList } from 'domains/User/Message';
import NotFound from 'routes';

const MessageRoute = () => {
    return (
        <Routes>
            <Route path='' element={<MessageList />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default MessageRoute;