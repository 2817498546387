export const getWeek = (date) => {
    const tumpDate = new Date(date);
    const currentDate = tumpDate.getDate();
    const firstDay = new Date(tumpDate.setDate(1)).getDay();

    return Math.ceil((currentDate+firstDay)/7);
};

export const DayKo = (date) => {
    switch(new Date(date).getDay()){
        case 0: return '일';
        case 1: return '월';
        case 2: return '화';
        case 3: return '수';
        case 4: return '목';
        case 5: return '금';
        case 6: return '토';
        default: return 'ㅤ';
    }
};


export const YyMmDd = (date) => {
    if(date){
        const yyyy = new Date(date).getFullYear();
        let mm = new Date(date).getMonth()+1;
        mm = mm > 9 ? mm : `0${mm}`;
        let dd = new Date(date).getDate();
        dd = dd > 9 ? dd : `0${dd}`;
        return `${yyyy.toString().slice(2, 4)}-${mm}-${dd}`;
    }else{
        return 'ㅤ';
    }
};

export const YyyyMmDd = (date) => {
    if(date){
        const yyyy = new Date(date).getFullYear();
        let mm = new Date(date).getMonth()+1;
        mm = mm > 9 ? mm : `0${mm}`;
        let dd = new Date(date).getDate();
        dd = dd > 9 ? dd : `0${dd}`;
        return `${yyyy}-${mm}-${dd}`;
    }else{
        return 'ㅤ';
    }
};

export const YyyyMmDdPlusDay = (date, day) => {
    if(date){
        const resultDate = new Date(date);
        resultDate.setDate(resultDate.getDate() + ( 7 * day)); // 7일을 더함
        const yyyy = resultDate.getFullYear();
        let mm = resultDate.getMonth()+1;
        mm = mm > 9 ? mm : `0${mm}`;
        let dd = resultDate.getDate();
        dd = dd > 9 ? dd : `0${dd}`;
        return `${yyyy}-${mm}-${dd}`;
    }else{
        return 'ㅤ';
    }
};

export const YyyyMmDdHhMmSs = (date) => {
    if(date){
        const yyyy = new Date(date).getFullYear();
        let mm = new Date(date).getMonth()+1;
        mm = mm > 9 ? mm : `0${mm}`;
        let dd = new Date(date).getDate();
        dd = dd > 9 ? dd : `0${dd}`;
        let hh = new Date(date).getHours();
        hh = hh > 9 ? hh : `0${hh}`;
        let mm2 = new Date(date).getMinutes();
        mm2 = mm2 > 9 ? mm2 : `0${mm2}`;
        let ss = new Date(date).getSeconds();
        ss = ss > 9 ? ss : `0${ss}`;
        return `${yyyy}-${mm}-${dd} ${hh}:${mm2}:${ss}`;
    }else{
        return 'ㅤ';
    }
};

export const ScheduleKindKo = (kind) => {
    switch(Number(kind)){
        case 1: return '개인상담';
        case 2: return '접수면접';
        case 3: return '검사실시';
        case 4: return '검사해석';
        case 5: return '집단상담';
        case 6: return '커플상담';
        case 7: return '가족상담';
        case 8: return '위기상담';
        case 9: return '면담·자문';
        case 10: return '종결';
        case 11: return '개인수퍼비전';
        case 12: return '그룹수퍼비전';
        case 13: return '사례회의';
        case 14: return '회의';
        case 15: return '교육';
        case 16: return '특강';
        case 17: return '준비';
        case 18: return '강의';
        case 19: return 'OFF';
        case 20: return '[예약가능]';
        case 21: return '[예약가능]상담';
        case 22: return '[예약가능]접수면접';
        case 23: return '[예약가능]검사실시';
        case 24: return '[예약가능]검사해석';
        case 25: return '공개사례발표';
        default: return 'ㅤ';
    }
};

export const SelectScheduleBorderColor = (detail, date, state, visit) => {
    if(detail >= 11 ){
        return 5;
    }else{
        if(state === 1){
            return 2;
        }else if(state === 2){
            return 3;
        }else if(state === 3){
            return 4;
        }else if(state === 4){
            return 1;
        }else{
            return 1;
        }
    }
};

export const SelectScheduleBgColor = (detail) => {
    switch(Number(detail)){
        case 1: return 1;
        case 2: return 2;
        case 3: return 3;
        case 4: return 4;
        case 5: return 5;
        case 6: return 5;
        case 7: return 5;
        case 8: return 5;
        case 9: return 5;
        case 10: return 6;
        case 11: return 8;
        case 12: return 8;
        case 13: return 7;
        case 14: return 7;
        case 15: return 7;
        case 16: return 7;
        case 17: return 8;
        case 18: return 8;
        case 19: return 9;
        case 20: return 10;
        case 21: return 10;
        case 22: return 10;
        case 23: return 10;
        case 24: return 10;
        default: return 1;
    }
};

export const ServiceKo = (service) => {
    switch(Number(service)){
        case 1: return '대면'
        case 2: return '화상'
        case 3: return '전화'
        case 4: return '문자'
        case 5: return '이메일'
        default: return 'ㅤ'
    }
};

export const ScaleState = (state) => {
    switch(Number(state)){
        case 1: return '출시'
        case 2: return '미출시'
        default: return 'ㅤ'
    };
};

export const ScaleKind = (kind) => {
    switch(Number(kind)){
        case 1: return '일반 검사'
        case 2: return '피드백'
        case 3: return '기본 서류'
        default: return 'ㅤ'
    };   
};

export const MemberStatus = (status) => {
    switch(Number(status)){
        case 1: return '대표관리자'
        case 2: return '상담자'
        case 3: return '행정담당자'
        default: return 'ㅤ'
    };
};

export const RoomState = (state) => {
    switch(Number(state)){
        case 1: return '사용가능'
        case 2: return '사용불가'
        default: return 'ㅤ'
    };
};

export const AvailableDay = (number) => {
    switch(Number(number)){
        case 1: return '일'
        case 2: return '월'
        case 3: return '화'
        case 4: return '수'
        case 5: return '목'
        case 6: return '금'
        case 7: return '토'
        default: return 'ㅤ'
    };
};

export const CalcAge = (date) => {
    if(date){
        const today = new Date();
        const birth = new Date(date);
        const thisYearBirth = new Date(new Date(date).setFullYear(new Date().getFullYear()));
        if(today > thisYearBirth){
            return `${today.getFullYear() - birth.getFullYear()}세`;
        }else{
            return `${today.getFullYear() - birth.getFullYear() - 1}세`;
        }
    }else{
        return 'ㅤ';
    }
};

export const GenderKo = (gender) => {
    switch(Number(gender)){
        case 1: return '남성'
        case 2: return '여성'
        case 3: return '논바이너리'
        case 4: return '다른 성 정체성'
        default: return 'ㅤ'
    };
};

export const NationalityKo = (nationality) => {
    switch(Number(nationality)){
        case 1: return '한국'
        case 2: return '미국'
        case 3: return '중국'
        case 4: return '베트남'
        case 5: return '태국'
        case 6: return '우즈베키스탄'
        case 7: return '필리핀'
        case 8: return '일본'
        case 9: return '기타'
        default: return 'ㅤ'
    };
};

export const MarriageKo = (marriage) => {
    switch(Number(marriage)){
        case 1: return '미혼'
        case 2: return '기혼'
        case 3: return '이혼'
        case 4: return '별거'
        case 5: return '재혼'
        default: return 'ㅤ'
    };
};

export const MilitaryKo = (military) => {
    switch(Number(military)){
        case 1: return '현역'
        case 2: return '방위'
        case 3: return '미필'
        case 4: return '면제'
        case 5: return '해당하지 않음'
        default: return 'ㅤ'
    };
};

export const EmploymentKo = (employment) => {
    switch(Number(employment)){
        case 1: return '회사원/피고용인'
        case 2: return '자영업자'
        case 3: return '구직활동 중'
        case 4: return '구직활동 중이지 않음'
        case 5: return '학생'
        case 6: return '전업주부'
        case 7: return '자원 봉사'
        case 8: return '퇴직'
        case 9: return '명시되지 않음/기타'
        default: return 'ㅤ'
    };
};

export const LivingKo = (living) => {
    switch(Number(living)){        
        case 1: return '자취'
        case 2: return '동거'
        case 3: return '부모(혹은 원가족)와 함께'
        case 4: return '배우자(혹은 결혼 후 형성한 가족)와 함께'
        case 5: return '기숙사/숙소'
        case 6: return '기타'
        default: return 'ㅤ'
    };
};

export const SessionStateKo = (state) => {
    switch(Number(state)){
        case 1: return '예약'
        case 2: return '취소'
        case 3: return '불참'
        case 4: return '참석'
        default: return 'ㅤ'
    };
};

export const ClientStateKo = (state) => {
    switch(Number(state)){
        case 1: return '신청'
        case 2: return '접수면접예약'
        case 3: return '접수면접실시'
        case 4: return '검사예약'
        case 5: return '검사실시'
        case 6: return '검사해석'
        case 7: return '상담중'
        case 8: return '종결'
        default: return 'ㅤ'
    };
};

export const ClientClosingKo = (closing) => {
    switch(Number(closing)){
        case 1: return '.합의'
        case 2: return '.조기'
        default: return 'ㅤ'
    };
};

export const MedicationKo = (medication) => {
    switch(Number(medication)){
        case 2: return '항우울제'
        case 3: return '항불안제/수면제'
        case 4: return '항정신병제'
        case 5: return '기분 안정제'
        case 6: return '기타'
        default: return 'ㅤ'
    };
};

export const ReferralKo = (referral) => {
    switch(Number(referral)){
        case 1: return '자진해서'
        case 2: return '인터넷 검색이나 안내 팜플렛을 통해'
        case 3: return '주위 사람의 권유'
        case 4: return '기타'
        default: return 'ㅤ'
    };
};

export const DesiredServiceKo = (desiredService) => {
    switch(Number(desiredService)){
        case 1: return '개인 상담'
        case 2: return '집단 상담'
        case 3: return '가족 상담'
        case 4: return '심리 검사'
        default: return 'ㅤ'
    };
};

export const ReasonKo = (reason) => {
    switch(Number(reason)){
        case 1: return '성격'
        case 2: return '심리 정서'
        case 3: return '직장'
        case 4: return '학업'
        case 5: return '진로'
        case 6: return '가족관계'
        case 7: return '연인관계'
        case 8: return '대인관계'
        case 9: return '기타'
        default: return 'ㅤ'
    };
};

export const DesiredTestKo = (desiredTest) => {
    switch(Number(desiredTest)){
        case 1: return '성격'
        case 2: return '정신건강'
        case 3: return '진로 및 적성'
        case 4: return '지능 및 인지'
        case 5: return '기타'
        default: return 'ㅤ'
    };
};

export const RiskKo = (risk) => {
    switch(Number(risk)){
        case 1: return '낮음'
        case 2: return '중간'
        case 3: return '높음'
        default: return 'ㅤ'
    };
};

export const HowKo = (how) => {
    switch(Number(how)){
        case 1: return '상담'
        case 2: return '심리검사'
        case 3: return '약물치료'
        default: return 'ㅤ'
    };
};

export const GoalActiveKo = (active) => {
    switch(Number(active)){
        case 1: return '진행중'
        case 2: return '종료'
        default: return 'ㅤ'
    };
};

export const GoalPeriodKo = (period) => {
    switch(Number(period)){
        case 1: return '1개월 미만'
        case 2: return '1개월 이상 3개월 미만'
        case 3: return '3개월 이상 1년 미만'
        case 4: return '1년 이상 5년 미만'
        case 5: return '5년 이상'
        case 6: return '간헐적 / 반복적'
        default: return 'ㅤ'
    };
};

export const UserStatus = (status) => {
    switch(Number(status)){
        case 1: return '대표관리자'
        case 2: return '상담자'
        case 3: return '행정담당자'
        case 4: return '내담자'
        case 5: return '관리자'
        default: return 'ㅤ'
    };
};

export const LicenseKo = (license) => {
    switch(Number(license)){
        case 1: return '(국가전문자격)정신건강전문요원 1급'
        case 2: return '(국가전문자격)청소년상담사 1급'
        case 3: return '(국가전문자격)전문상담교사 1급'
        case 4: return '(민간자격)임상심리전문가(한국심리학회)'
        case 5: return '(민간자격)상담심리사 1급(한국상담심리학회/한국심리학회)'
        case 6: return '(민간자격)전문상담사 1급(한국상담학회)'
        case 7: return '(국가전문자격)정신건강전문요원 2급'
        case 8: return '(국가전문자격)청소년상담사 2급'
        case 9: return '(국가전문자격)전문상담교사 2급'
        case 10: return '(국가기술자격)임상심리사 1급'
        case 11: return '(민간자격)상담심리사 2급(한국상담심리학회/한국심리학회)'
        case 12: return '(민간자격)전문상담사 2급(한국상담학회)'
        case 13: return '기타(1급 유형)'
        case 14: return '기타(2급 유형)'
        default: return 'ㅤ'
    };
};

export const DegreeKo = (degree) => {
    switch(Number(degree)){
        case 1: return '학사'
        case 2: return '석사'
        case 3: return '박사'
        default: return 'ㅤ'
    };
};

export const UniversityStatusKo = (us) => {
    switch(Number(us)){
        case 1: return '재학'
        case 2: return '수료'
        case 3: return '졸업'
        default: return 'ㅤ'
    };
};

export const RegionKo = (region) => {
    switch(Number(region)){
        case 1: return '서울'
        case 2: return '경기'
        case 3: return '인천'
        case 4: return '부산'
        case 5: return '대구'
        case 6: return '광주'
        case 7: return '대전'
        case 8: return '울산'
        case 9: return '강원'
        case 10: return '충북'
        case 11: return '충남'
        case 12: return '전북'
        case 13: return '전남'
        case 14: return '경북'
        case 15: return '경남'
        case 16: return '제주'
        case 17: return '세종'
        default: return 'ㅤ'
    };
};
