import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SuperviseeList, SuperviseeDetail } from 'domains/User/Manage/Supervisee';
import NotFound from 'routes';

const SuperviseeRoute = () => {
    return (
        <Routes>
            <Route path='' element={<SuperviseeList />} />
            <Route path=':clientNo' element={<SuperviseeDetail />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default SuperviseeRoute;