import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton } from 'components/Items/Button';
import { DataTableSort } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';

const ClientListView = (props) => {
    const navigate = useNavigate();
    const keys = [
        { key: 'No', label: 'No.'},
        { key: 'NickName', label: '내담자ID'},
        { key: 'Name_decrypt', label: '이름'},
        { key: 'CreatedAtValue', label: '신청일'},
        { key: 'StateName', label: '현황'},
        { key: 'BirthValue', label: '생년월일'},
        { key: 'GenderValue', label: '성별'},
        { key: 'Phone_decrypt', label: '연락처'}, 
        { key: 'Email_decrypt', label: '이메일'}, 
        { key: 'CounselorName', label: '담당자'},
        { key: 'GroupName', label: '그룹'},
    ];

    const [search, setSearch] = useState('');
    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState([true, false]);
    const [openClientDeleteModal, setOpenClientDeleteModal] = useState(false);

    const { handleClickDownload } = props;

    const filterDatas = (datas) => {
        if(checked[0] && checked[1]){
            return datas;
        }else if(checked[0]){
            return datas.filter((item) => (item.State !== 8));
        }else if(checked[1]){
            return datas.filter((item) => (item.State === 8));
        }else{
            return datas;
        }
    };
    const handleClickDetail = (data) => {
        navigate(`/manage/client/${data.ClientNo}`);
    };
    const handleClickUpdate = (data) => {
        navigate(`/manage/client/${data.ClientNo}/update`);
    };

    const handleClickDelete = (data) => {
        props.setSelectClient(data);
        setOpenClientDeleteModal(true);
    };

    const handleClickChecked = (e, i) => {
        let changeChecked = [...checked];
        changeChecked[i] = e.target.checked;
        if(changeChecked[0] && changeChecked[1]){
            setCheckedAll(true);
        }
        if(!changeChecked[0] || !changeChecked[1]){
            setCheckedAll(false);
        }
        setChecked(changeChecked);
    };
    
    const handleClickCheckedAll = (e) => {
        setCheckedAll(e.target.checked);
        setChecked([e.target.checked, e.target.checked]);
    };

    return (
        <>
        <ListContainer title='내담자 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={3} lg={4} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={9} lg={8} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    {/* <Grid container item xs={6} justifyContent={'flex-end'} alignItems={'center'} textAlign={'right'}>
                        <Grid item>
                            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                        </Grid>
                        <Grid item>
                            <span className='hover_pointer' onClick={() => setChecked(!checked)}>미분류 내담자</span>
                        </Grid>
                    </Grid> */}
                    <Grid item sm={6} md={7} sx={{ pl: 3 }}>
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox checked={checkedAll} onChange={(e) => handleClickCheckedAll(e)}/>} label='전체' />
                            <FormControlLabel control={<Checkbox checked={checked[0]} onChange={(e) => handleClickChecked(e, 0)}/>} label='진행중' />
                            <FormControlLabel control={<Checkbox checked={checked[1]} onChange={(e) => handleClickChecked(e, 1)} />} label='종결' />
                        </FormGroup>
                    </Grid>
                    <Grid item sm={6} md={5} container justifyContent={'flex-end'} spacing={1}>
                        <Grid item>
                            <SmallSubmitButton label='다운로드' handleClick={handleClickDownload} loading={props.loading} />
                        </Grid>
                        <Grid item>
                            <SmallSubmitButton 
                                label='내담자 생성'
                                handleClick={() => navigate('/manage/client/create')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTableSort
                    keys={keys}
                    datas={filterDatas(props.clients)}
                    delete={true}
                    update={true}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickUpdate={handleClickUpdate}
                    handleClickDelete={handleClickDelete}
                    defaultSort={[0, 2]}

                />
            </Grid>
        </ListContainer>
        {props.selectClient.ClientNo ? 
        <ConfirmModal
            title='내담자 삭제'
            icon='error'
            open={openClientDeleteModal}
            handleClose={() => setOpenClientDeleteModal(false)}
            handleClickSubmit={() => {setOpenClientDeleteModal(false);props.handleClickDeleteUserBtn();}}
            submitLabel='삭제'
        >
            내담자 정보를 삭제 하시겠습니까? 내담자 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default ClientListView;