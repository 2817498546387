import React from 'react';
import { Grid, Divider, IconButton, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const ItemLikertForm = (props) => {
    const regex = /[^0-9]/g;
    const regex2 = /[0-9]/g;

    return (
        <Grid container justifyContent={'center'}>
            {props.subScale ?
            <Grid item xs={12} style={{ paddingTop: props.no === 1 ? '0px' : '16px', marginBottom: '16px' }}>
                <span style={{ color: 'rgb(139, 139, 139)'}}>{props.scale.SubScale[props.item.SubScale-1]}</span>
            </Grid>:
            null
            }
            <Grid item xs={props.update ? 10 : 12} container>
                <Grid item xs={12} style={{ fontSize: '20px' }}>
                    Q{props.no}. {props.item.Content}
                </Grid>
            </Grid>
            {props.update ?
            <Grid item xs={2} style={{ textAlign: 'right'}}>
                <IconButton style={{ marginRight: '8px'}} onClick={props.delete}>
                    <Delete />
                </IconButton>
                <IconButton onClick={props.update}>
                    <Edit />
                </IconButton>
            </Grid>:null}
            <Grid item xs={12} container style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center', verticalAlign: 'middle' }}>
                {/* <Grid item>
                    A{props.no}.&nbsp;
                </Grid> */}
                <Grid item>
                    <RadioGroup>                    
                        <table style={{ tableLayout: 'fixed', width: '100%' }}>
                            <tbody>
                                <tr>
                                    {props.item.LabelKind === 1 ?
                                    <td>
                                        {props.item.Choices[0]}
                                    </td>:
                                    null}
                                    {props.item.Points?.map((_, i) => (
                                    <td key={i} style={{ textAlign: 'center' }}>
                                        {props.item.LabelKind === 2 ?
                                        <Grid style={{ color: 'gray', whiteSpace: 'nowrap' }}>
                                            {props.item.Choices[i].replace(regex2, '') ? props.item.Choices[i].replace(regex2, '') : 'ㅤ'}
                                        </Grid>:
                                        null}
                                        <FormControlLabel
                                            sx={{ margin: 'auto', padding: 0 }}
                                            value={i}
                                            onChange={(e) => props.handleChange(e, props.item._id)}
                                            control={<Radio checked={Boolean(props.answer?.indexOf(`${i}`)> -1)}/>}
                                        />
                                        {props.item.LabelKind === 2 ?
                                        <Grid>
                                            {props.item.Choices[i].replace(regex, '') ? props.item.Choices[i].replace(regex, '') : 'ㅤ'}
                                        </Grid>:
                                        null}
                                    </td>
                                    ))}
                                    {props.item.LabelKind === 1 ?
                                    <td>
                                        {props.item.Choices[props.item.Choices.length-1]}
                                    </td>:
                                    null}
                                </tr>
                            </tbody>
                        </table>
                    </RadioGroup>
                </Grid>
            </Grid>
            {!props.lastItem ?
            <Grid item xs={11.5} style={{ paddingBottom: '4px' }}>
                <Divider />
            </Grid>:null}
        </Grid>
    );
};

export default ItemLikertForm;