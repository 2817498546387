import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { ScaleBasicView } from 'components';

const ScaleBasicContainer = () => {
    const { clientNo, itemId, codeKey } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [scale, setScale] = useState({
        NickName: '',
        BasicScale: {
            Item_ids: []
        },
        BasicAnswer: [{
            Item_id: '',
            Answer: []
        }]
    });
    const [key, setKey] = useState('');

    const handleClickSave = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({ BasicAnswer: scale.BasicAnswer });
        const loginKey = cookies.LoginKey ? cookies.LoginKey : key;
        await axios.post(`/api/scale/basic/${clientNo}/${itemId}/${loginKey}`, body, config)
        .then((res) => {
            if(res.data.ErrorMessage){
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };
    
    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        if(cookies.LoginKey){
            await axios.get(`/api/scale/basic/${clientNo}/${itemId}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setScale(res.data.ScaleData);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }else{
            await axios.get(`/api/login/codeKey/${codeKey}`, config)
            .then(async(res) => {
                if(!res.data.ErrorMessage){
                    setKey(res.data.CodeKey.LoginKey);
                    await axios.get(`/api/scale/basic/${clientNo}/${itemId}/${res.data.CodeKey.LoginKey}`, config)
                    .then((res2) => {
                        if(!res2.data.ErrorMessage){
                            
                            setScale(res2.data.ScaleData);
                        }else{
                            enqueueSnackbar(res2.data.ErrorMessage, { variant: 'error' });
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar('Network Error!', { variant: 'error' });
                        console.log(err);
                    });
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }
    }, [enqueueSnackbar, setScale]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        <ScaleBasicView
            scale={scale}
            setScale={setScale}
            handleClickSave={handleClickSave}
        />
    );
};

export default ScaleBasicContainer;