import React from 'react';
import { Grid } from '@mui/material';
import { ItemShortForm, ItemMultipleChoice, ItemLikert, ItemShortFormOther } from 'components/Items/View';

const BasicView = (props) => {
    const basicScale = props.basicScale;
    const option1 = [13, 26, 39, 49, 2, 19, 29, 50, 12, 24, 35, 48, 9, 25, 8, 20, 36, 10, 23, 37, 47, 6, 22 ,32, 44, 3, 31, 5, 21, 40, 43, 14, 17, 27, 46, 1, 15, 34, 38, 7, 33, 45, 4, 11, 16, 18, 28, 30, 41, 42];
    const subScale1 = ['① 어머니에 대한 태도', '① 어머니에 대한 태도', '① 어머니에 대한 태도', '① 어머니에 대한 태도',
                        '② 아버지에 대한 태도', '② 아버지에 대한 태도', '② 아버지에 대한 태도', '② 아버지에 대한 태도',
                        '③ 가족에 대한 태도', '③ 가족에 대한 태도', '③ 가족에 대한 태도', '③ 가족에 대한 태도',
                        '④ 여성에 대한 태도', '④ 여성에 대한 태도',
                        '⑤ 남성에 대한 태도', '⑤ 남성에 대한 태도', '⑤ 남성에 대한 태도',
                        '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도',
                        '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도',
                        '⑧ 권위자에 대한 태도', '⑧ 권위자에 대한 태도',
                        '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도',
                        '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도',
                        '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도',
                        '⑫ 과거에 대한 태도', '⑫ 과거에 대한 태도', '⑫ 과거에 대한 태도',
                        '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도',
                        '⑭ 목표에 대한 태도', '⑭ 목표에 대한 태도', '⑭ 목표에 대한 태도'];
    const option2 = [15, 23, 14, 27, 1, 8, 11, 12, 16, 18, 22, 34, 13, 28, 36, 9, 17, 31, 37, 2, 6, 10, 35, 4, 20, 21,24, 25, 30, 32, 33, 29, 7, 38, 3, 5, 19, 26];
    const subScale2 = ['① 어머니에 대한 태도', '① 어머니에 대한 태도',
                        '② 아버지에 대한 태도', '② 아버지에 대한 태도',
                        '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계',
                        '④ 학교생활에 대한 태도', '④ 학교생활에 대한 태도', '④ 학교생활에 대한 태도',
                        '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도',
                        '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도',
                        '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', 
                        '⑧ 과거에 대한 태도',
                        '⑨ 미래에 대한 태도', '⑨ 미래에 대한 태도',
                        '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도'];
    return(
        <>
        <Grid container>
            {basicScale ? 
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <h4>기본 서류</h4>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '8px'}}>
                    <Grid container item xs={8} alignItems={'center'}>
                        <Grid item>
                            <b>{basicScale.Scale_id.Label}</b>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid style={{ marginBottom : '16px' }}>
                            {basicScale.Scale_id.Detail}
                        </Grid>
                        {basicScale.Scale_id._id === '653f90eba149184a6bc2b8b4' ?
                        basicScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((_, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            <ItemShortFormOther 
                                item={basicScale.Scale_id?.Item_ids[`${option1[j]-1}`]} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={j === 0 || subScale1[j] !== subScale1[j-1]}
                                subScales={subScale1}
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === basicScale.Scale_id.Item_ids[`${option1[j]-1}`]?._id))[0]}
                            />
                        </Grid>
                        ))
                        :
                        basicScale.Scale_id._id === '653fc2bba149184a6bc2e2f2' ?
                        basicScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((_, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            <ItemShortFormOther 
                                item={basicScale.Scale_id?.Item_ids[`${option2[j]-1}`]} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={j === 0 || subScale2[j] !== subScale2[j-1]}
                                subScales={subScale2}
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === basicScale.Scale_id.Item_ids[`${option1[j]-1}`]?._id))[0]}
                            />
                        </Grid>
                        ))
                        :
                        basicScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            {q.ItemKind === 1 ?
                            <ItemShortForm 
                                item={q} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== basicScale.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            q.ItemKind === 2 ?
                            <ItemMultipleChoice 
                                item={q}
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== basicScale.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            q.ItemKind === 3 ?
                            <ItemLikert 
                                item={q} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== basicScale.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            null}
                        </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>:
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <h4>기본 서류가 없습니다.</h4>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid>
        </>
    );
};

export default BasicView;