import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { TeamListView } from 'components/User/Manage/Team';

const TeamListContainer = (props) => {
    const pages = [
        { name: '팀 관리', link: '' },
        { name: '팀 목록', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [teams, setTeams] = useState([]);

    const [selectTeam, setSelectTeam] = useState({});
    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/team/${selectTeam.TeamNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/team/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadTeams = [...res.data.TeamDatas];
                for(let i = 0; i < loadTeams.length; i++){
                    loadTeams[i].No = i + 1;
                    loadTeams[i].UserCnt = `${loadTeams[i].User_ids.length} 명`;
                }
                setTeams(loadTeams);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setTeams, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <TeamListView
                teams={teams}
                selectTeam={selectTeam}
                setSelectTeam={setSelectTeam}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default TeamListContainer;