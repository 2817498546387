import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { UserDetail, UserList, UserUpdate } from 'domains/Admin/User';

const UserRoute = () => {
    return (
        <Routes>
            <Route path='' element={<UserList />}/>
            <Route path=':userNo' element={<UserDetail />}/>
            <Route path=':userNo/update' element={<UserUpdate />}/>
        </Routes>
    );
};

export default UserRoute;