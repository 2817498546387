import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { TextForm, EmailForm } from 'components/Items/Form';

const UserUpdateView = (props) => {
    const manager = props.manager;
    const setManager = props.setManager
    const navigate = useNavigate();
    const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);
    const [nameError, setNameError] = useState(false);
    const regKo = new RegExp(/^[ㄱ-ㅎㅏ-ㅣ가-힣]+$/); // eslint-disable-line
    const regPhone = new RegExp('[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}'); // eslint-disable-line
    const handleChangeName = (e) => {
        if(regKo.test(e.target.value) || e.target.value === ''){
            setNameError(false);
            setManager({ ...manager, Name: e.target.value });
        }else{
            setNameError(true);
        }
    };
    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setManager({ ...manager, Phone: tump });
        }else{
            return false;
        }
    };

    const handleChangeIPs = (e) => {
        setManager({ ...manager, IPs: e.target.value });
    }

    useEffect(() => {
        let tump = false;
        if(!manager.Name){
            tump = true;
        }
        if(!regPhone.test(manager.Phone) || manager.Phone === ''){
            tump = true;
        }
        setDisabledUpdateBtn(tump);
    }, [manager, setDisabledUpdateBtn, regPhone]);
    return (
        <>
        <FormContainer title='유저 수정'>
            <TextForm
                label='이름'
                required={true}
                error={nameError}
                errorMessage='한글만 입력이 가능합니다.'
                value={manager.Name}
                handleChange={handleChangeName}
            />
            <EmailForm
                label='이메일'
                required={false}
                error={false}
                disabled={true}
                value={manager.Email}
            />
            <TextForm
                label='연락처'
                required={true}
                error={!regPhone.test(manager.Phone) && manager.Phone !== ''}
                errorMessage='연락처 형식이 올바르지 않습니다.'
                value={manager.Phone}
                placeholder='연락처를 입력해주세요.'
                handleChange={handleChangePhone}
            />
            <TextForm
                label='접속 IP'
                required={false}
                value={manager.IPs}
                placeholder='IP는 ,(콤마)로 구분 000.000.000.000, 111.111.111.111'
                handleChange={handleChangeIPs}
            />
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton 
                        label='수정' 
                        loading={props.updating}
                        disabled={disabledUpdateBtn}
                        handleClick={props.handleClickUpdateBtn}
                    />
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default UserUpdateView;