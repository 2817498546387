import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { TextForm } from 'components/Items/Form';
import { LargeSubmitButton, SmallCancelButton, SmallSubmitButton } from 'components/Items/Button';
import { ItemShortForm, ItemMultipleChoiceForm, ItemLikertForm } from './Items/Form';

const ScaleBasicView = (props) => {
    const [nickName, setNickName] = useState('');
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleClickSave = (state) => {
        setSaving(true);
        props.handleClickSave();
        setTimeout(() => {
            enqueueSnackbar('저장 되었습니다.', { variant: 'info' });
            if(state === 1){
                setSaving(false);
            }else{
                window.close();
            }
        }, [1000]);
    };

    const handleClickStartBtn = () => {
        setLoading(true);
        setTimeout(() => {
            if(nickName === props.scale.NickName){
                setCheck(true);
                enqueueSnackbar('기본 서류 작성을 시작합니다.', { variant: 'info' });
            }else{
                enqueueSnackbar('닉네임이 일치하지 않습니다.', { variant: 'error' });
            }
        setLoading(false);
        }, [800]);
    };

    const handleChangeAnswer = (e, key, mc) => {
        let changeAnswer = { ...props.scale };
        let firstData = true;
        for(let i = 0; i < changeAnswer.BasicAnswer.length; i++){
            if(changeAnswer.BasicAnswer[i].Item_id === key){
                if(mc){
                    if(changeAnswer.BasicAnswer[i].Answer.indexOf(e.target.value) > -1){
                        changeAnswer.BasicAnswer[i].Answer.splice(changeAnswer.BasicAnswer[i].Answer.indexOf(e.target.value), 1);
                    }else{
                        changeAnswer.BasicAnswer[i].Answer.push(String(e.target.value));
                    }
                }else{
                    changeAnswer.BasicAnswer[i] = { Item_id: key, Answer: [e.target.value] };
                }
                firstData = false;
                break;
            }
        }
        if(firstData){
            changeAnswer.BasicAnswer.push({ Item_id: key, Answer: [e.target.value] });
        }
        props.setScale(changeAnswer);
    };

    return(
        <>
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            style={{
                minHeight: '100vh',
                paddingLeft: '1em',
                paddingRight: '1em',
                position: 'relative',
                zIndex: '2'
            }}
        >
        {!check ?
            <Grid
                xs={12} sm={10} md={8} lg={6} xl={4}
                item
                style={{
                    backgroundColor: '#FFF',
                    borderRadius: '30px',
                    padding: '60px 4%'
                }}
            >
                <h1 style={{ textAlign: 'center' }}>{props.scale.BasicScale.Label}</h1>
                <TextForm
                    label='내담자 등록 시 사용한 내담자ID를 입력해 주세요.'
                    required={true}
                    value={nickName}
                    handleChange={(e) => setNickName(e.target.value)}
                />
                <LargeSubmitButton
                    label='시작하기'
                    disabled={!Boolean(nickName)}
                    loading={loading}
                    handleClick={handleClickStartBtn}
                />
            </Grid>:
            <Grid
                xs={12} sm={10} md={8} lg={6} xl={4}
                item
                style={{
                    backgroundColor: '#FFF',
                    borderRadius: '30px',
                    padding: '60px 4%'
                }}
            >
                <h1 style={{ textAlign: 'center' }}>{props.scale.BasicScale.Label}</h1>
                <p style={{ textAlign: 'right'}}>{props.scale.NickName}</p>
                <p>{props.scale.BasicScale.Detail}</p>
                {props.scale.BasicScale.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                <Grid key={j} style={{ marginBottom: '8px' }}>
                    {q.ItemKind === 1 ?
                    <ItemShortForm 
                        item={q}
                        no={j+1}
                        scale={props.scale.BasicScale}
                        subScale={
                            q.SubScale && (j === 0 || q.SubScale !== props.scale.BasicScale.Item_ids[j-1].SubScale)
                        }
                        lastItem={props.scale.BasicScale.Item_ids.length === j+1}
                        answer={props.scale.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                        handleChange={handleChangeAnswer}
                    /> :
                    q.ItemKind === 2 ?
                    <ItemMultipleChoiceForm 
                        item={q}
                        no={j+1}
                        scale={props.scale.BasicScale}
                        subScale={
                            q.SubScale && (j === 0 || q.SubScale !== props.scale.BasicScale.Item_ids[j-1].SubScale)
                        }
                        lastItem={props.scale.BasicScale.Item_ids.length === j+1}
                        answer={props.scale.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                        handleChange={handleChangeAnswer}
                    /> :
                    q.ItemKind === 3 ?
                    <ItemLikertForm
                        item={q}
                        no={j+1}
                        scale={props.scale.BasicScale}
                        subScale={
                            q.SubScale && (j === 0 || q.SubScale !== props.scale.BasicScale.Item_ids[j-1].SubScale)
                        }
                        lastItem={props.scale.BasicScale.Item_ids.length === j+1}
                        answer={props.scale.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                        handleChange={handleChangeAnswer}    
                    /> :
                    null}
                </Grid>
                ))}
                <Grid container justifyContent={'flex-end'} spacing={1}>
                    <Grid item>
                        <SmallCancelButton
                            label='임시 저장'
                            handleClick={() => handleClickSave(1)}
                            disabled={saving}
                        />
                    </Grid>
                    <Grid item>
                        <SmallSubmitButton
                            label='완료'
                            handleClick={() => handleClickSave(2)}
                            loading={saving}
                        />
                    </Grid>
                </Grid>
            </Grid>}
        </Grid>
        </>
    );
};

export default ScaleBasicView;