import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ContactList } from 'domains/Admin/Contact';

const ContactRoute = () => {
    return (
        <Routes>
            <Route path='' element={<ContactList />}/>
        </Routes>
    );
};

export default ContactRoute;