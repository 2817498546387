import React from 'react';
import { Grid } from '@mui/material';

const DataBox = (props) => {
    return (
        <Grid container spacing={1}>
            {props.datas.map((item, i) => (
                <Grid item xs={12} md={6} key={i}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'center'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {item.key}
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9}
                            style={{
                                paddingLeft: '16px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {item.value}
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default DataBox;