import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControlLabel, Checkbox, FormHelperText, FormGroup, FormControl, Select, Stack, Chip, MenuItem, ListItemText, InputLabel } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { TextForm, EmailForm, SelectForm } from 'components/Items/Form';
import { MemberStatus, AvailableDay, LicenseKo } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime, Region, License } from 'components/Function/AddDatas';

const MemberUpdateView = (props) => {
    const navigate = useNavigate();
    const member = props.member;
    const setMember = props.setMember;
    // const regKo = new RegExp(/^[ㄱ-ㅎㅏ-ㅣ가-힣]+$/); // eslint-disable-line
    const regPhone = new RegExp('[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}'); // eslint-disable-line

    const [nameError, setNameError] = useState(false);
    const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);

    const handleChangeName = (e) => {
        // if(regKo.test(e.target.value) || e.target.value === ''){
        //     setNameError(false);
        //     setMember({ ...member, Name: e.target.value });
        // }else{
        //     setNameError(true);
        // }
        setMember({ ...member, Name: e.target.value });
    };
    const handleChangeSupervisor = (e) => {
        setMember({ ...member, Supervisor_id: e.target.value });
    };
    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setMember({ ...member, Phone: tump });
        }else{
            return false;
        }
    };

    const handleChangeAvailableDay = (e) => {
        let changeMember = { ...member };
        if(changeMember.AvailableDay.indexOf(e.target.value) > -1){
            changeMember.AvailableDay = changeMember.AvailableDay.filter((item) => (item !== e.target.value));
            changeMember.Available[Number(e.target.value)-1] = [];
        }else{
            changeMember.AvailableDay.push(e.target.value);
            changeMember.AvailableDay = changeMember.AvailableDay.sort((a, b) => (a - b));
        }
        setMember(changeMember);
    };
    const handleChangeAllDay = () => {
        let changeMember = { ...member };
        if(changeMember.AvailableDay.length === 7){
            changeMember.AvailableDay = [];
            changeMember.Available = [[], [], [], [], [], [], []];
        }else{
            changeMember.AvailableDay = ['1', '2', '3', '4', '5', '6', '7'];
        }
        setMember(changeMember);
    };
    const handleChangeAvailable = (e, key) => {
        let changeMember = { ...member };
        if(changeMember.Available[Number(key)-1].indexOf(e.target.value) > -1){
            changeMember.Available[Number(key)-1] = changeMember.Available[Number(key)-1].filter((item) => (item !== e.target.value));
        }else{
            changeMember.Available[Number(key)-1].push(e.target.value);
        }
        setMember(changeMember);
    };
    const handleChangeAvailableAll = (key) => {
        let changeMember = { ...member };
        if(changeMember.Available[Number(key)-1].length === 17){
            changeMember.Available[Number(key)-1] = [];
        }else{
            changeMember.Available[Number(key)-1] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17'];
        }
        setMember(changeMember);
    };

    const handleChangeRegion = (e) => {
        let changeMember = { ...member };
        if(changeMember.Region.indexOf(e.target.value) > -1){
            changeMember.Region = changeMember.Region.filter((item) => (item !== e.target.value));
            changeMember.Available[Number(e.target.value)-1] = [];
        }else{
            changeMember.Region.push(e.target.value);
            changeMember.Region = changeMember.Region.sort((a, b) => (a - b));
        }
        setMember(changeMember);
    };
    const handleChangeAllRegion = (e) => {
        let changeMember = { ...member };
        if(e.target.checked){
            changeMember.Region = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17'];
        }else{
            changeMember.Region = [];
        }
        setMember(changeMember);
    };

    useEffect(() => {
        let tump = false;
        if(!member.Name){
            tump = true;
        }
        if(!regPhone.test(member.Phone) || member.Phone === ''){
            tump = true;
        }
        setDisabledUpdateBtn(tump);
    }, [member, setDisabledUpdateBtn, regPhone]);
    
    return (
        <>
        <FormContainer title='직원 수정'>
            <TextForm
                label='이름'
                required={true}
                error={nameError}
                errorMessage='한글만 입력이 가능합니다.'
                value={member.Name}
                placeholder='이름을 입력해주세요.'
                handleChange={handleChangeName}
            />
            <EmailForm
                label='이메일'
                required={true}
                error={false}
                value={member.Email}
                disabled={true}
            />
            <TextForm
                label='연락처'
                required={true}
                error={!regPhone.test(member.Phone) && member.Phone !== ''}
                errorMessage='연락처 형식이 올바르지 않습니다.'
                value={member.Phone}
                placeholder='연락처를 입력해주세요.'
                handleChange={handleChangePhone}
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    상담(근무) 가능 요일
                </Grid>
                <FormGroup row>
                    {PossibleDay().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeAvailableDay} control={<Checkbox checked={member.AvailableDay.indexOf(`${i+1}`) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={handleChangeAllDay} control={<Checkbox checked={member.AvailableDay.length === 7} />} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {member.AvailableDay.map((day, i) => (
            <FormControl fullWidth key={i}>
                <Grid style={{ marginBottom: '8px' }}>
                    {AvailableDay(day)}요일 상담(근무) 가능 시간
                </Grid>
                <FormGroup row>
                    {PossibleTime().map((item, j) => (
                    <FormControlLabel key={j} value={item.value} onChange={(e) => handleChangeAvailable(e, day)} control={<Checkbox checked={member.Available[Number(day)-1].indexOf(item.value) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={() => handleChangeAvailableAll(day)} control={<Checkbox checked={member.Available[Number(day)-1].length === 17}/>} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            ))}
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    주요 활동지역
                </Grid>
                <FormGroup row>
                    {Region().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeRegion} control={<Checkbox checked={member.Region.indexOf(`${i+1}`) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={handleChangeAllRegion} control={<Checkbox checked={member.Region.length === 17} />} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant='outlined' className='form_default'>                
                <Grid style={{ marginBottom: '8px' }}>
                    자격증
                </Grid>
                <Select
                    multiple
                    value={member.License}
                    onChange={(e) => setMember({ ...member, License: e.target.value })}
                    renderValue={(selected) => `${selected.length}개의 자격증이 선택 됨`}
                >
                {License().map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                        <Checkbox checked={member.License.indexOf(item.value) > -1} />
                        <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
                </Select>
                <Stack direction='row' flexWrap='wrap' style={{ marginTop: 8 }}>
                {member.License.map((item, i) => (
                    <Chip key={i} label={LicenseKo(item)} size='small' variant='outlined' style={{ marginRight: 8, marginBottom: 8 }} />  
                ))}
                </Stack>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <Grid container>
                <Grid mb={1} xs={12}>
                    최종학력
                </Grid>
                <Grid xs={6} pr={1}>
                    <SelectForm
                        label='학위'
                        required={false}
                        error={false}
                        value={Number(member.Degree)}
                        datas={[{ value: '1', label: '학사' }, { value: '2', label: '석사' }, { value: '3', label: '박사' }]}
                        handleChange={(e) => setMember({ ...member, Degree: Number(e.target.value) })}
                    />
                </Grid>
                <Grid xs={6} pl={1}>
                    <SelectForm
                        label='수료 여부'
                        required={false}
                        error={false}
                        value={Number(member.UniversityStatus)}
                        datas={[{ value: '1', label: '재학' }, { value: '2', label: '수료' }, { value: '3', label: '졸업' }]}
                        handleChange={(e) => setMember({ ...member, UniversityStatus: Number(e.target.value) })}
                        disabled={!member.Degree}
                    />
                </Grid>
                <Grid xs={6} pr={1}>
                    <TextForm
                        label='학교명'
                        required={false}
                        error={false}
                        value={member.University}
                        disabled={!member.Degree}
                        handleChange={(e) => setMember({ ...member, University: e.target.value })}
                    />
                </Grid>
                <Grid xs={6} pl={1}>
                    <TextForm
                        label='전공'
                        required={false}
                        error={false}
                        value={member.Major}
                        disabled={!member.Degree}
                        handleChange={(e) => setMember({ ...member, Major: e.target.value })}
                    />
                </Grid>
            </Grid>
            <EmailForm
                label='권한'
                required={true}
                error={false}
                value={MemberStatus(member.Status)}
                disabled={true}
            />
            {member.Status === 2 ?
            <SelectForm
                label='수퍼바이저'
                required={false}
                error={false}
                value={member.Supervisor_id}
                datas={props.supervisors}
                handleChange={handleChangeSupervisor}
            />:null}
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton label='수정' disabled={disabledUpdateBtn} loading={props.updating} handleClick={props.handleClickUpdateBtn}/>
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default MemberUpdateView;