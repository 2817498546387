import React from 'react';
import { Grid, Divider } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { SelectForm } from 'components/Items/Form';
import { ClientState } from 'components/Function/AddDatas';
import { MedicationKo, ReferralKo, DesiredServiceKo, ReasonKo, DesiredTestKo, RiskKo, HowKo } from 'components/Function/ChangeText';

const ProfileView = (props) => {
    return (
        <>
        <Grid container style={{ marginBottom: '16px' }} alignItems={'end'} spacing={1}>
            <Grid item xs={12}>
                <h3>상담 현황</h3>
            </Grid>
            <Grid item xs={6}>
                <SelectForm
                    required={false}
                    error={false}
                    value={props.client.State}
                    datas={ClientState()}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={2}>
            </Grid>
        </Grid>
        {/* <Divider/> */}
        <DetailContainer
            title='신청 정보'
            collapse={180}
        >
            <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
                <Grid item xs={12} className='table_normal'>
                    <table>
                        <thead>
                            <tr>
                                <th>복용약물</th><th colSpan={2}>약물 명</th><th>위험도</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{MedicationKo(props.client.Medication)}</td><td colSpan={2}>{props.client.MedicationDetail}</td><td>{RiskKo(props.client.Risk)}</td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan={2}>찾아오게된 경위</th><th colSpan={2}>찾아온 목적</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>{props.client.Referral === 4 ? props.client.ReferralDetail : ReferralKo(props.client.Referral)}</td>
                                <td colSpan={2}>{props.client.DesiredService.length > 0 ? props.client.DesiredService.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{DesiredServiceKo(item)}</span>)) : 'ㅤ'}</td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan={2}>상담 받고 싶은 점</th><th colSpan={2}>받고 싶은 검사</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>{props.client.Reason.length > 0 ? props.client.Reason.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{ReasonKo(item)}</span>)) : 'ㅤ'}</td>
                                <td colSpan={2}>{props.client.DesiredTest.length > 0 ? props.client.DesiredTest.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{DesiredTestKo(item)}</span>)) : 'ㅤ'}</td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan={4}>현재 고민이나 어려움</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td colSpan={4} style={{ whiteSpace: 'normal' }}>{props.client.Concern ? <div dangerouslySetInnerHTML={{ __html: props.client.Concern.replaceAll('\n', '<br/>') }} /> : 'ㅤ'}</td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th>이전 상담 여부</th><th>언제</th><th>어디서</th><th>치료유형</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.client.PriorTreatment.PriorExperience ? '있음' : '없음'}</td>
                                <td>{props.client.PriorTreatment.When}</td>
                                <td>{props.client.PriorTreatment.Where}</td>
                                <td>{props.client.PriorTreatment.How.length > 0 ? props.client.PriorTreatment.How.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{HowKo(item)}</span>)) : 'ㅤ'}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        </DetailContainer>
        {/* <Divider/>
        <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Grid item xs={12}>
                <h3>긴급 연락처</h3>
            </Grid>
            <Grid item xs={12} className='table_normal'>
                <table>
                    <thead>
                        <tr>
                            <th>관계</th><th>연령</th><th>이름</th><th>연락처</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.client.Family[0]?.Name ?
                        props.client.Family.map((item, i) => (
                        <tr key={i}>
                            <td style={{ borderRadius: '0px 0px 0px 8px' }}>{item.Relation}</td><td>{item.Age}</td><td>{item.Name}</td><td>{item.Phone}</td>
                        </tr>
                        )):
                        <tr>
                            <td style={{ borderRadius: '0px 0px 8px 8px' }} colSpan={4}>긴급 연락처가 없습니다.</td>
                        </tr>}
                    </tbody>
                </table>
            </Grid>
        </Grid>
        <Divider/> */}
        <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={6}>
                    <h3>내담자 검사 정보</h3>
                </Grid>
            </Grid>
            <Grid container>
                {props.client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length > 0 ? 
                <Grid item xs={12}>
                    <h4>검사</h4>
                    {props.client.Scales.map((item, i) => (
                    item.Scale_id.ScaleKind === 1 ?
                    <Grid container key={i} style={{ marginBottom: '8px'}}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Grid item>
                                <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid style={{ marginBottom : '16px' }}>
                                <div 
                                    dangerouslySetInnerHTML={{ 
                                        __html: item.Scale_id.Content.replaceAll('\n', '<br/>')
                                    }} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    :null
                    ))}
                    <h4>피드백</h4>
                    {props.client.Scales.map((item, i) => (
                    item.Scale_id.ScaleKind === 2 ?
                    <Grid container key={i} style={{ marginBottom: '8px'}}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Grid item>
                                <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid style={{ marginBottom : '16px' }}>
                                <div 
                                    dangerouslySetInnerHTML={{ 
                                        __html: item.Scale_id.Content.replaceAll('\n', '<br/>')
                                    }} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    :null
                    ))}
                </Grid>:
                <Grid item xs={12}>
                    등록된 검사가 없습니다.
                </Grid>}
            </Grid>
        </Grid>
        </>
    );
};

export default ProfileView;