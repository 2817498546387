import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ScaleUpdateView } from 'components/Admin/Scale';

const ScaleUpdateContainer = (props) => {
    const { scaleNo } = useParams();
    const pages = [
        { name: '검사', link: '/admin/scale' },
        { name: '검사 상세', link: `/admin/scale/${scaleNo}` },
        { name: '검사 수정', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [updating, setUpdating] = useState(false);
    const [scale, setScale] = useState({
        Label: '',
        ScaleKind: 1,
        CalScore: false,
        Negative: false,
        SubScale: [''],
        Content: '',
        Detail: '',
        Min: 0,
        Max: 0,
        Standard: 0,
        SubMin: 0,
        SubMax: 0,
        RCI: 0
    });

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(scale);
        await axios.put(`/api/admin/scale/${scaleNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar('검사가 수정되었습니다.', { variant: 'success' });
                setTimeout(() => {
                    navigate(`/admin/scale/${res.data.Scale.ScaleNo}`);
                }, [1200]);
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                setTimeout(() => {
                    setUpdating(false);
                }, [1200]);
            }
        })
        .catch((err) => {
            setUpdating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/scale/${scaleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadScale = { ...res.data.ScaleData };
                loadScale.SubScale = loadScale.SubScale.length > 0 ? loadScale.SubScale : ['']; 
                setScale(loadScale);
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [scaleNo, cookies.LoginKey, setScale, enqueueSnackbar]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScaleUpdateView
                scale={scale}
                setScale={setScale}
                handleClickUpdateBtn={handleClickUpdateBtn}
                updating={updating}
            />
        </DashboardLayout>
    );
};

export default ScaleUpdateContainer;