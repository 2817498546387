import React from 'react';
import { LoadingButton } from '@mui/lab';

/**
 * 
 * @param {string} label 
 * @param {boolean} loading
 * @param {boolean} disabled
 * @param {function} handleClick
 * @returns 
 */
const LargeSubmitButton = (props) => {
    return(
        <LoadingButton
            variant='contained'
            disableElevation
            fullWidth={true}
            size='large'
            style={{ borderRadius: '10px', marginBottom: props.mb ? props.mb : '24px', height: '56px', fontWeight: 'bold', fontSize: '18px' }}
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.handleClick}
        >
            {props.label}
        </LoadingButton>
    );
};

export default LargeSubmitButton;