import React from 'react';
import { useParams } from 'react-router-dom';

import { DashboardLayout } from 'components/Layout';
import { ClientUpdateView } from 'components/User/Client';

const ClientUpdateContainer = (props) => {
    const { clientNo } = useParams();
    const pages = [
        { name: 'My내담자', link: '/client' },
        { name: '내담자 상세,', link: `/client/${clientNo}` },
        { name: '내담자 수정,', link: '' }
    ];

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ClientUpdateView />
        </DashboardLayout>
    );
};

export default ClientUpdateContainer;