import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { TeamUpdateView } from 'components/User/Manage/Team';

const TeamUpdateContainer = (props) => {
    const { teamNo } = useParams();
    const pages = [
        { name: '팀 관리', link: '/manage/team' },
        { name: '팀 상세', link: `/manage/team/${teamNo}` },
        { name: '팀 수정', link: '' }
    ];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [updaing, setUpdating] = useState(false);
    const [team, setTeam] = useState({
        Name: ''
    });

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(team);
        await axios.put(`/api/manager/team/${teamNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/manage/team/${teamNo}`);
                }, [1200]);
            }else{
                setUpdating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setUpdating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/team/${teamNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTeam(res.data.TeamData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setTeam, enqueueSnackbar, cookies.LoginKey, teamNo]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <TeamUpdateView 
                team={team}
                setTeam={setTeam}
                updaing={updaing}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default TeamUpdateContainer;