import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { RoomUpdateView } from 'components/User/Manage/Room';

const RoomUpdateContainer = (props) => {
    const { roomNo } = useParams();
    const pages = [
        { name: '상담실 관리', link: '/manage/room' },
        { name: '상담실 상세', link: `/manage/room/${roomNo}` },
        { name: '상담실 수정', link: '' }
    ];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [updating, setUpdate] = useState(false);
    const [room, setRoom] = useState({
        Name: '',
        State: 2
    });

    const handleClickUpdateBtn = async() => {
        setUpdate(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(room);
        await axios.put(`/api/manager/room/${roomNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/manage/room/${roomNo}`);
                }, [1200]);
            }else{
                setUpdate(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setUpdate(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };
    
    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/room/${roomNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setRoom(res.data.RoomData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [roomNo, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <RoomUpdateView 
                room={room}
                setRoom={setRoom}
                updating={updating}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default RoomUpdateContainer;