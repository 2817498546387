import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ScheduleList, ScheduleDetail, ScheduleUpdate } from 'domains/User/Schedule';
import NotFound from 'routes';

const ScheduleRoute = () => {
    return (
        <Routes>
            <Route path='' element={<ScheduleList />} />
            <Route path=':scheduleNo' element={<ScheduleDetail />} />
            <Route path=':scheduleNo/update' element={<ScheduleUpdate />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default ScheduleRoute;