import React, { useState } from 'react';
import { Grid, Divider, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, FormGroup, Checkbox, Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';
import { DetailContainer } from 'components/Items/Container';
import { SelectForm, TextForm } from 'components/Items/Form';
import { ClientState } from 'components/Function/AddDatas';
import { LargeSubmitButton, SmallSubmitButton } from 'components/Items/Button';
import { FormModal, ConfirmModal } from 'components/Items/Modal';
import { MedicationKo, ReferralKo, DesiredServiceKo, ReasonKo, DesiredTestKo, RiskKo, HowKo } from 'components/Function/ChangeText';
import { DataBox } from 'components/Items/Table';
import { PossibleDay, PossibleTime, ClosingReason } from 'components/Function/AddDatas';

const ProfileView = (props) => {
    const [openScaleUpdateModal, setOpenScaleUpdateModal] = useState(false);
    const [openScaleDeleteModal, setOpenScaleDeleteModal] = useState(false);
    
    const handleChangeScale = (e) => {
        let changeClientScales = [...props.clientScales];
        if(e.target.checked){
            changeClientScales.push({ Scale_id: e.target.value, Primary: false, Basic: false });
        }else{
            changeClientScales = changeClientScales.filter((item) => (item.Scale_id !== e.target.value ));
        }
        props.setClientScales(changeClientScales);
    };
    const handleChangeScalePrimary = (e) => {
        let changeClientScales = [...props.clientScales];
        for(let i = 0; i < changeClientScales.length; i++){
            if(e.target.value === changeClientScales[i].Scale_id){
                changeClientScales[i].Primary = true;
            }else{
                changeClientScales[i].Primary = false;
            }
        }
        props.setClientScales(changeClientScales);
    };
    
    return (
        <>
        <Grid container style={{ marginBottom: '16px' }} alignItems={'end'} spacing={1}>
            <DetailContainer
                title='내담자 정보'
                collapse={180}
                shadow='none'
            >
                <DataBox
                    datas={props.datas}
                />
                <Grid container style={{ marginTop: '8px' }}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            style={{
                                borderRadius: '5px',
                                border: '1px solid #C0C0C0'
                            }}
                            alignItems={'stretch'}
                        >
                            <Grid
                                item 
                                xs={4} sm={3} md={1.5} 
                                style={{ 
                                    backgroundColor: '#D9D9D9', 
                                    textAlign: 'center', 
                                    fontWeight: 'bold', 
                                    borderRadius: '5px 0px 0px 5px', 
                                    padding: '4px 16px', 
                                    overflow: 'hidden', 
                                    whiteSpace: 'nowrap', 
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                희망 상담시간
                            </Grid>
                            <Grid 
                                item
                                xs={8} sm={9} md={10.5}
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <Grid container style={{ textAlign: 'center', borderBottom: '1px solid #C0C0C0', fontSize: '80%' }}>
                                    <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                    </Grid>
                                    {PossibleDay().map((day, i) => (
                                    <Grid item xs={12/8} key={i} style={{ borderRight: i < 6 ? '1px solid #C0C0C0' : '0px' }}>
                                        {day.label.replace('요일', '')}
                                    </Grid>
                                    ))}
                                </Grid>
                                {PossibleTime().map((time, i) => (
                                <Grid container key={i} style={{ textAlign: 'center', borderBottom: i < 17 ? '1px solid #C0C0C0' : '0px', fontSize: '80%' }}>
                                    <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                        {time.label.split('~')[0].replace(':00 ', '시')}
                                    </Grid>
                                    {PossibleDay().map((day, j) => (
                                    <Grid
                                        item
                                        xs={12/8}
                                        key={j}
                                        style={{ 
                                            backgroundColor: 
                                                props.client.AvailableDay.indexOf(day.value) > -1 && props.client.Available[Number(day.value)-1].indexOf(time.value) > -1 ?
                                                'rgba(161, 216, 160, 0.7)' : 
                                                '', 
                                            borderRight: j < 6 ? '1px solid #C0C0C0' : '0px'
                                        }}
                                    />
                                    ))}
                                </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
                            <Grid item xs={12}>
                                <h3>가족사항</h3>
                            </Grid>
                            <Grid item xs={12} className='table_normal'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>관계</th><th>연령</th><th>이름</th><th>연락처</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.client.Family[0]?.Name ?
                                        props.client.Family.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ borderRadius: '0px 0px 0px 8px' }}>{item.Relation}</td><td>{item.Age}</td><td>{item.Name}</td><td>{item.Phone}</td>
                                        </tr>
                                        )):
                                        <tr>
                                            <td style={{ borderRadius: '0px 0px 8px 8px' }} colSpan={4}>긴급 연락처가 없습니다.</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DetailContainer>
            <Grid item xs={12}>
                <h3>상담 현황</h3>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} container>
                    <Grid item xs={12}>
                        <SelectForm
                            required={false}
                            error={false}
                            value={props.client.State}
                            datas={ClientState()}
                            handleChange={(e) => props.setClient({ ...props.client, State: e.target.value, Closing: null, ClosingReason: null, ClosingReasonDetail: null })}
                        />
                    </Grid>
                    {props.client.State === 8 ?
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel value={1} control={<Radio checked={props.client.Closing === 1} onChange={(e) => props.setClient({ ...props.client, Closing: Number(e.target.value) })}/>} label={'합의종결'} />
                            <FormControlLabel value={2} control={<Radio checked={props.client.Closing === 2} onChange={(e) => props.setClient({ ...props.client, Closing: Number(e.target.value) })}/>} label={'조기종결'} />
                        </FormGroup>
                    </Grid>:
                    null}
                    {props.client.State === 8 ?
                    <Grid item xs={6}>
                        <h4 style={{ paddingLeft: '8px' }}>종결 사유</h4>
                        <FormGroup>
                            {ClosingReason().map((closingReason, i) => (
                            <FormControlLabel value={closingReason.value} key={i} control={<Radio checked={props.client.ClosingReason === Number(closingReason.value)} onChange={(e) => props.setClient({ ...props.client, ClosingReason: Number(e.target.value), ClosingReasonDetail: '' })}/>} label={closingReason.label} />
                            ))}
                        </FormGroup>
                    </Grid>:
                    null}
                    {props.client.ClosingReason === 2 || props.client.ClosingReason === 4 || props.client.ClosingReason === 5 || props.client.ClosingReason === 6 ?
                    <Grid item xs={6}>
                        <h4 style={{ paddingLeft: '8px' }}>종결 사유 상세</h4>
                        {props.client.ClosingReason === 2 ?
                        <FormGroup>
                            <FormControlLabel value={'본인'} control={<Radio checked={props.client.ClosingReasonDetail === '본인'} onChange={(e) => props.setClient({ ...props.client, ClosingReasonDetail: e.target.value })}/>} label='본인' />
                            <FormControlLabel value={'보호자'} control={<Radio checked={props.client.ClosingReasonDetail === '보호자'} onChange={(e) => props.setClient({ ...props.client, ClosingReasonDetail: e.target.value })}/>} label='보호자' />
                        </FormGroup>:null}
                        {props.client.ClosingReason === 5 ?
                        <FormGroup>
                            <FormControlLabel value={'사고'} control={<Radio checked={props.client.ClosingReasonDetail === '사고'} onChange={(e) => props.setClient({ ...props.client, ClosingReasonDetail: e.target.value })}/>} label='사고' />
                            <FormControlLabel value={'질병'} control={<Radio checked={props.client.ClosingReasonDetail === '질병'} onChange={(e) => props.setClient({ ...props.client, ClosingReasonDetail: e.target.value })}/>} label='질병' />
                            <FormControlLabel value={'자살'} control={<Radio checked={props.client.ClosingReasonDetail === '자살'} onChange={(e) => props.setClient({ ...props.client, ClosingReasonDetail: e.target.value })}/>} label='자살' />
                        </FormGroup>:null}
                        {props.client.ClosingReason === 4 || props.client.ClosingReason === 6 ?
                        <TextForm
                            required={false}
                            error={false}
                            value={props.client.ClosingReasonDetail}
                            placeholder=''
                            handleChange={(e) => props.setClient({ ...props.client, ClosingReasonDetail: e.target.value })}
                        />:null}
                    </Grid>:
                    null}
                    <Grid item xs={12}>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} spacing={1}>
                            <Grid item>
                                <h4 style={{ paddingLeft: '8px' }}>My분류</h4>
                            </Grid>
                            <Grid item>
                                <Tooltip title='상담자 전문성 향상을 위해 상담자가 내담자를 자유롭게 분류할 수 있는 탭입니다. 예를 들어, 상담자가 애착, 비자발적 내담자, 청소년-진로, 청소년-품행장애, 다문화 사례 등 다양한 방식으로 메모하여 사용할 수 있습니다.'>
                                    <Help color='secondary'/>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <TextForm
                            required={false}
                            error={false}
                            value={props.client.Category}
                            placeholder='상담자가 원하는 방식으로 자유롭게 분류'
                            handleChange={(e) => props.setClient({ ...props.client, Category: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <LargeSubmitButton
                        label='저장'
                        handleClick={props.handleClickStateBtn}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Divider/>
        <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Grid item xs={12}>
                <h3>신청 정보</h3>
            </Grid>
            <Grid item xs={12} className='table_normal'>
                <table>
                    <thead>
                        <tr>
                            <th>복용약물</th><th colSpan={2}>약물 명</th><th>위험도</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{MedicationKo(props.client.Medication)}</td><td colSpan={2}>{props.client.MedicationDetail}</td><td>{RiskKo(props.client.Risk)}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan={2}>찾아오게된 경위</th><th colSpan={2}>찾아온 목적</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>{props.client.Referral === 4 ? props.client.ReferralDetail : ReferralKo(props.client.Referral)}</td>
                            <td colSpan={2}>{props.client.DesiredService.length > 0 ? props.client.DesiredService.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{DesiredServiceKo(item)}</span>)) : 'ㅤ'}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan={2}>상담 받고 싶은 점</th><th colSpan={2}>받고 싶은 검사</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>{props.client.Reason.length > 0 ? props.client.Reason.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{ReasonKo(item)}</span>)) : 'ㅤ'}</td>
                            <td colSpan={2}>{props.client.DesiredTest.length > 0 ? props.client.DesiredTest.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{DesiredTestKo(item)}</span>)) : 'ㅤ'}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan={4}>현재 고민이나 어려움</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4} style={{ whiteSpace: 'normal' }}>{props.client.Concern ? <div dangerouslySetInnerHTML={{ __html: props.client.Concern.replaceAll('\n', '<br/>') }} /> : 'ㅤ'}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>이전 상담 여부</th><th>언제</th><th>어디서</th><th>치료유형</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{props.client.PriorTreatment.PriorExperience ? '있음' : '없음'}</td>
                            <td>{props.client.PriorTreatment.When}</td>
                            <td>{props.client.PriorTreatment.Where}</td>
                            <td>{props.client.PriorTreatment.How.length > 0 ? props.client.PriorTreatment.How.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{HowKo(item)}</span>)) : 'ㅤ'}</td>
                        </tr>
                    </tbody>
                </table>
            </Grid>
        </Grid>
        {/* <Divider/>
        <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Grid item xs={12}>
                <h3>긴급 연락처</h3>
            </Grid>
            <Grid item xs={12} className='table_normal'>
                <table>
                    <thead>
                        <tr>
                            <th>관계</th><th>연령</th><th>이름</th><th>연락처</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.client.Family[0]?.Name ?
                        props.client.Family.map((item, i) => (
                        <tr key={i}>
                            <td style={{ borderRadius: '0px 0px 0px 8px' }}>{item.Relation}</td><td>{item.Age}</td><td>{item.Name}</td><td>{item.Phone}</td>
                        </tr>
                        )):
                        <tr>
                            <td style={{ borderRadius: '0px 0px 8px 8px' }} colSpan={4}>긴급 연락처가 없습니다.</td>
                        </tr>}
                    </tbody>
                </table>
            </Grid>
        </Grid>
        <Divider/> */}
        <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={6}>
                    <h3>내담자 검사 정보</h3>
                </Grid>
                {props.client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length > 0 ? 
                <Grid item xs={6} container justifyContent={'end'} spacing={1}>
                    <Grid item>
                        <SmallSubmitButton
                            label='삭제'
                            handleClick={() => setOpenScaleDeleteModal(true)}
                        />
                    </Grid>
                    <Grid item>
                        <SmallSubmitButton
                            label='수정'
                            handleClick={() => setOpenScaleUpdateModal(true)}
                        />
                    </Grid>
                </Grid>:
                <Grid item xs={6} container justifyContent={'end'} spacing={1}>
                    <Grid item>
                        <SmallSubmitButton
                            label='등록'
                            handleClick={() => setOpenScaleUpdateModal(true)}
                        />
                    </Grid>
                </Grid>}
            </Grid>
            <Grid container>
                {props.client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length > 0 ? 
                <Grid item xs={12}>
                    <h4>검사</h4>
                    {props.client.Scales.map((item, i) => (
                    item.Scale_id.ScaleKind === 1 ?
                    <Grid container key={i} style={{ marginBottom: '8px'}}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Grid item>
                                <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid style={{ marginBottom : '16px' }}>
                                <div 
                                    dangerouslySetInnerHTML={{ 
                                        __html: item.Scale_id.Content.replaceAll('\n', '<br/>')
                                    }} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    :null
                    ))}
                    <h4>피드백</h4>
                    {props.client.Scales.map((item, i) => (
                    item.Scale_id.ScaleKind === 2 ?
                    <Grid container key={i} style={{ marginBottom: '8px'}}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Grid item>
                                <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid style={{ marginBottom : '16px' }}>
                                <div 
                                    dangerouslySetInnerHTML={{ 
                                        __html: item.Scale_id.Content.replaceAll('\n', '<br/>')
                                    }} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    :null
                    ))}
                </Grid>:
                <Grid item xs={12}>
                    등록된 검사가 없습니다.
                </Grid>}
            </Grid>
        </Grid>
        <FormModal
            open={openScaleUpdateModal}
            handleClose={() => setOpenScaleUpdateModal(false)}
            title={'기본 검사 설정'}
            submitLabel={'완료'}
            handleClickSubmit={() => {props.handleClickScaleBtn();setOpenScaleUpdateModal(false);}}
        >
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    검사
                </Grid>
                <FormGroup>
                    {props.scales.filter((item) => (item.ScaleKind === 1)).map((item, i) => (
                    <FormControlLabel key={i} value={item._id} control={<Checkbox checked={props.clientScales.filter((scale) => (scale.Scale_id === item._id)).length > 0} onChange={handleChangeScale}/>} label={item.Label}/>    
                    ))}
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    피드백
                </Grid>
                <FormGroup>
                    {props.scales.filter((item) => (item.ScaleKind === 2)).map((item, i) => (
                    <FormControlLabel key={i} value={item._id} control={<Checkbox checked={props.clientScales.filter((scale) => (scale.Scale_id === item._id)).length > 0} onChange={handleChangeScale}/>} label={item.Label}/>
                    ))}
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    주요 검사
                </Grid>
                <RadioGroup>
                    {props.clientScales.filter((item) => (!item.Basic)).map((item, i) => (
                        <FormControlLabel key={i} value={item.Scale_id} control={<Radio checked={item.Primary} onChange={handleChangeScalePrimary}/>} label={props.scales.filter((scale) => (scale._id === item.Scale_id))[0]?.Label} />
                    ))}
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}>{props.clientScales.filter((item) => (!item.Basic)).length === 0 ? '검사 또는 피드백을 하나 이상 선택해 주세요.' : ''} </FormHelperText>
            </FormControl> 
        </FormModal>
        <ConfirmModal
            title='기본 검사 삭제'
            icon='error'
            open={openScaleDeleteModal}
            handleClose={() => setOpenScaleDeleteModal(false)}
            handleClickSubmit={() => {props.handleClickDeleteScaleBtn();setOpenScaleDeleteModal(false);}}
            submitLabel='삭제'
        >
            기본 검사 정보를 삭제 하시겠습니까? 삭제 이전에 생성 된 상담정보는 변경되지 않습니다.
        </ConfirmModal>
        </>
    );
};

export default ProfileView;