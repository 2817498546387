import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { TextForm } from 'components/Items/Form';
import { RoomState } from 'components/Function/AddDatas';

const RoomCreateView = (props) => {
    const navigate = useNavigate();
    return (
        <>
        <FormContainer title='상담실 생성'>
            <TextForm
                label='이름'
                required={true}
                error={false}
                value={props.room.Name}
                placeholder='이름을 입력해주세요.'
                handleChange={(e) => props.setRoom({ ...props.room, Name: e.target.value })}
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    사용 가능 여부
                </Grid>
                <RadioGroup row>
                    {RoomState().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} control={<Radio onChange={(e) => props.setRoom({ ...props.room, State: Number(e.target.value) })} checked={props.room.State === i+1}/>} label={item.label} />
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton 
                        label='생성'
                        loading={props.creating}
                        disabled={!Boolean(props.room.Name)}
                        handleClick={props.handleClickCreateBtn}
                    />
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default RoomCreateView;