import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Contact, UserUpdate } from 'domains/User';
import NotFound from 'routes';

const UserRoute = () => {
    return (
        <Routes>
            <Route path='contact' element={<Contact />}/>
            <Route path='update' element={<UserUpdate />}/>
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default UserRoute;