import React from 'react';
import AppPresenter from './AppPresenter';

/**
 * @title AppContainer
 * @description App Container, Wrapp Context
 */
const AppContainer = () => {
  return <AppPresenter />;
};

export default AppContainer;