import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ClientList, ClientCreate, ClientDetail, ClientUpdate } from 'domains/User/Manage/Client';
import NotFound from 'routes';

const ClientRoute = () => {
    return (
        <Routes>
            <Route path='' element={<ClientList />} />
            <Route path='create' element={<ClientCreate />} />
            <Route path=':clientNo' element={<ClientDetail />} />
            <Route path=':clientNo/update' element={<ClientUpdate />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default ClientRoute;