import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

import { DashboardLayout } from 'components/Layout';
import { UserUpdateView } from 'components/Admin/User';

const UserUpdateContainer = (props) => {
    const { userNo } = useParams();    
    const pages = [
        { name: '유저', link: '/admin/user' },
        { name: '유저 상세', link: `/admin/user/${userNo}` },
        { name: '유저 수정', link: '' }
    ];
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [updating, setUpdating] = useState(false);
    const [manager, setManager] = useState({});

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(manager);
        await axios.put(`/api/admin/manager/${userNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    navigate(`/admin/user/${userNo}`);
                }, [1200]);
            }else{
                setUpdating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            setUpdating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/manager/${userNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setManager(res.data.ManagerData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, enqueueSnackbar, setManager, userNo]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <UserUpdateView
                manager={manager}
                setManager={setManager}
                updating={updating}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default UserUpdateContainer;