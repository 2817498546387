import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { UserListView } from 'components/Admin/User';
import { YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const UserListContainer = (props) => {
    const pages = [
        { name: '유저', link: '' },
        { name: '유저 목록', link: '' }
    ];

    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [managers, setManagers] = useState([]);
    const [selectManager, setSelectManager] = useState({});

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/manager/${selectManager._id}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/manager/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadManagers = [...res.data.ManagerDatas];
                for(let i = 0; i < loadManagers.length; i++){
                    loadManagers[i].No = i + 1;
                    loadManagers[i].CreatedAtValue = YyyyMmDdHhMmSs(loadManagers[i].createdAt);
                }
                setManagers(loadManagers);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, enqueueSnackbar, setManagers]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <UserListView
                managers={managers}
                selectManager={selectManager}
                setSelectManager={setSelectManager}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default UserListContainer;