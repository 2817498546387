import React, { useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { RoomDetailView } from 'components/User/Manage/Room';
import { ScheduleKindKo } from 'components/Function/ChangeText';

const RoomDetailContainer = (props) => {
    const { roomNo } = useParams();
    const pages = [
        { name: '상담실 관리', link: '/manage/room' },
        { name: '상담실 상세', link: '' }
    ];
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [room, setRoom] = useState({
        Name: '',
        State: ' '
    });
    const [scheudles, setSchedules] = useState([]);

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/room/${roomNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                navigate(-1);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/room/${roomNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setRoom(res.data.RoomData);
                let loadSchedules = [...res.data.ScheduleDatas];
                for(let i = 0; i < loadSchedules.length; i++){
                    loadSchedules[i].KindDetailKo = ScheduleKindKo(loadSchedules[i].Kind.Detail);
                }
                setSchedules(loadSchedules);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [roomNo, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <RoomDetailView 
                room={room}
                schedules={scheudles}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default RoomDetailContainer;