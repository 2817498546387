import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { User, Scale, Contact } from './Admin';
import NotFound from 'routes';

const AdminRoute = () => {
    return (
        <Routes>
            <Route path='' element={<h1>Admin</h1>} />
            <Route path='user/*' element={<User />} />
            <Route path='scale/*' element={<Scale />} />
            <Route path='contact/*' element={<Contact />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default AdminRoute;