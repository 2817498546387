import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { RoomListView } from 'components/User/Manage/Room';
import { RoomState } from 'components/Function/ChangeText';

const RoomListContainer = (props) => {
    const pages = [
        { name: '상담실 관리', link: '' },
        { name: '상담실 목록', link: '' }
    ];

    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();

    const [rooms, setRooms] = useState([]);
    const [selectRoom, setSelectRoom] = useState({});

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/room/${selectRoom.RoomNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/room/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadRooms = [...res.data.RoomDatas];
                for(let i = 0; i < loadRooms.length; i++){
                    loadRooms[i].No = i + 1;
                    loadRooms[i].StateKo = RoomState(loadRooms[i].State);
                }
                setRooms(loadRooms);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setRooms, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);


    return (
        <DashboardLayout pages={pages} status={props.status}>
            <RoomListView 
                rooms={rooms}
                selectRoom={selectRoom}
                setSelectRoom={setSelectRoom}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default RoomListContainer;