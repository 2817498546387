import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { GroupCreateView } from 'components/User/Manage/Group';

const GroupCreateContainer = (props) => {
    const pages = [
        { name: '그룹 관리', link: '/manage/group' },
        { name: '그룹 생성', link: '' }
    ];

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);

    const [group, setGroup] = useState({
        Name: ''
    });
    const [creating, setCreating] = useState(false);

    const handleClickCreateBtn = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(group);
        await axios.post(`/api/manager/group/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/manage/group/${res.data.Result.GroupNo}`);
                }, [1200]);
            }else{
                setCreating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <GroupCreateView 
                group={group}
                setGroup={setGroup}
                creating={creating}
                handleClickCreateBtn={handleClickCreateBtn}
            />
        </DashboardLayout>
    );
};

export default GroupCreateContainer;