import React from 'react';
import { Grid } from '@mui/material';

const FormContainer = (props) => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={9} lg={7} xl={5} style={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '24px 16px', overflow: 'hidden' }} sx={{ boxShadow: 1 }}>
                <h2 style={{ textAlign: 'center', marginTop: 0 }}>{props.title}</h2>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default FormContainer;