import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import { YyyyMmDd, YyyyMmDdPlusDay } from 'components/Function/ChangeText';
import { DetailModal } from 'components/Items/Modal';
import { ItemLikert, ItemMultipleChoice, ItemShortForm } from 'components/Items/View';

const ChartView = (props) => {
    const [cnt, setCnt] = useState({ scale: 0, result: 0 });
    const [scales, setScales] = useState([]);
    const [datas, setDatas] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedScales, setCheckedScales] = useState([]);
    const [bgc, setBgc] = useState(false);
    const [openScaleModal, setOpenScaleModal] = useState(false);
    const [selectScale, setSelectScale] = useState({});
    const [chartMin, setChartMin] = useState('auto');
    const [chartMax, setChartMax] = useState('auto');
    const [chartStandardUp, setChartStandardUp] = useState(50);
    const [chartStandardDown, setChartStandardDown] = useState(50);
    const [chartStandard, setChartStandard] = useState('0');
    
    useEffect(() => {
      let loadScales = [];
      let loadDatas = [];
      let loadCheckedScales = [];
      let scaleCnt = 0;
      let resultCnt = 0;
      const tumpSchedules = props.client.Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
      const tumpScales = props.client.Scales.filter((a) => (a.Scale_id.State === 1 && !a.Basic));
      for(let i = 0; i < tumpScales.length; i++){
        if(tumpScales[i].Scale_id.CalScore){
          loadScales.push(tumpScales[i].Scale_id.Label);
          loadDatas.push({ id: tumpScales[i].Scale_id.Label, data: [] });
          loadCheckedScales.push(false);
          for(let j = 0; j < 10; j++){
            scaleCnt += 1;
            if( j < tumpSchedules.length ) {
              if(tumpSchedules[j][`${tumpScales[i].Scale_id._id}`]){
                loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDd(tumpSchedules[j].StartDate), 'rawy': tumpSchedules[j][`${tumpScales[i].Scale_id._id}`], ScheduleNo: tumpSchedules[j].ScheduleNo, Scale_id: tumpScales[i].Scale_id._id, 'max': tumpSchedules[j][`${tumpScales[i].Scale_id._id}Max`], 'scalemin': tumpScales[i].Scale_id.Min, 'scalemax': tumpScales[i].Scale_id.Max, 'scalestandard': tumpScales[i].Scale_id.Standard, 'negative': tumpScales[i].Scale_id.Negative });
                resultCnt += 1;
              } else {
                loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDdPlusDay(tumpSchedules[0].StartDate, j), 'rawy': null, ScheduleNo: null, Scale_id: null, 'max': null, 'scalemin': tumpScales[i].Scale_id.Min, 'scalemax': tumpScales[i].Scale_id.Max, 'scalestandard': tumpScales[i].Scale_id.Standard, 'negative': tumpScales[i].Scale_id.Negative });
                resultCnt += 1;
              }
            }
          }
        }
      }
      // let tumpGoals = [...props.goals];
      // loadScales.push('목표');
      // loadDatas.push({ id: '목표', data: [] });
      // loadCheckedScales.push(false);
      // for(let i = 0; i < tumpGoals.length; i++){
      //   for(let j = 0; j < tumpGoals[i].Points.length; j++){
      //     if(loadDatas[loadDatas.length - 1].data.filter((a) => (a.x === tumpGoals[i].Points[j].Date))[0]){
      //       const dateIdx = loadDatas[loadDatas.length - 1].data.indexOf(loadDatas[loadDatas.length - 1].data.filter((a) => (a.x === tumpGoals[i].Points[j].Date))[0]);
      //       loadDatas[loadDatas.length - 1].data[dateIdx].sum += Number(tumpGoals[i].Points[j].Point);
      //       loadDatas[loadDatas.length - 1].data[dateIdx].cnt += 1;
      //       loadDatas[loadDatas.length - 1].data[dateIdx].rawy = loadDatas[loadDatas.length - 1].data[dateIdx].sum/loadDatas[loadDatas.length - 1].data[dateIdx].cnt;
      //     }else{
      //       loadDatas[loadDatas.length - 1].data.push({ 'x': tumpGoals[i].Points[j].Date, 'rawy': tumpGoals[i].Points[j].Point, 'sum': tumpGoals[i].Points[j].Point, 'cnt': 1, 'max': 100, 'scalemin': '0', 'scalemax': '100', 'scalestandard': 0, 'negative': 0 });
      //     }
      //   }
      //   for(let j = loadDatas[loadDatas.length - 1].data.length; j < 10; j++){
      //     loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDdPlusDay(tumpGoals[0].Points[0].Date, j), 'rawy': null, 'max': null, 'scalemin': '0', 'scalemax': '100', 'scalestandard': 0, 'negative': 0 });
      //   }
      // }
      // for(let i = 0; i < loadDatas.length; i++){
      //   loadDatas[i].data = loadDatas[i].data.sort((a, b) => (new Date(a.x) - new Date(b.x)));
      // }
      setScales(loadScales);
      setDatas(loadDatas);
      setCheckedScales(loadCheckedScales);
      setCnt({ scale: scaleCnt, result: resultCnt });
    }, [props]);
    
    const handleChange = (e) => {
        if(Number(e.target.value) !== 0){
            let changeCheckedScales = [...checkedScales];
            changeCheckedScales[Number(e.target.value) - 1] = !checkedScales[Number(e.target.value) - 1];
            setCheckedScales(changeCheckedScales);
        }else{
          let changeChecked = [...checkedScales];
          for(let i = 0; i < changeChecked.length; i++){
            changeChecked[i] = !allChecked;
          }
          setCheckedScales(changeChecked);
          setAllChecked(!allChecked);
        }
    };
    const filterDatas = (datas) => {
        return datas.filter((item) => {
            let returnData = false;
            for(let i = 0; i < checkedScales.length; i++){
                if(checkedScales[i] && item.id === scales[i]){
                    returnData = true;
                }
            }
            return returnData;
        });
    };

    const dataPoint = (data, option) => {
      let first, end, sum, sum2;
      for(let i = 0; i < data.length; i++){
        if(data[i].rawy){
          if(first){
            end = data[i].rawy;
          }else{
            first = data[i].rawy;
          }
        }
      }
      if(end && first){
        sum = (((end - first)/(Math.abs(first)))*100).toFixed(1);
        sum = ((end - first)/(Math.abs(first))) > 0 ? `+${sum}%` : `${sum}%`;
        sum2 = end - first > 0 ? '+'+(end - first).toFixed(1).replace('.0', '') : (end - first).toFixed(1).replace('.0', '');
      }
      if(option === 1){
        return first;
      }else if(option === 2){
        return end;
      }else if(option === 3){
        return sum;
      }else if(option === 4){
        return sum2;
      }else{ 
        return '-';
      }
    };

    const handleClickPoint = (node) => {
      if(node.data.ScheduleNo){
        const tumpSchedule = props.client.Schedule_ids.filter((item) => (item.ScheduleNo === Number(node.data.ScheduleNo)))[0];
        if(tumpSchedule){
          const tumpAnswer = tumpSchedule.ScaleAnswer.filter((item) => (item.Scale_id === node.data.Scale_id))[0];
          const scaleAnswer = { Answer: tumpAnswer.Answer, Scale_id: props.client.Scales.filter((item) => (item.Scale_id._id === node.data.Scale_id))[0].Scale_id }
          setSelectScale(scaleAnswer);
          setOpenScaleModal(true);
        }else{ return false; }
      }else{
        return false;
      }
    };

    useEffect(() => {
      setTimeout(() => {
        let changeDatas = [...datas];
        for(let i = 0; i < checkedScales.length; i++){
          if(checkedScales[i]){
            if(checkedScales.filter((item) => (item === true)).length === 1){
              for(let j = 0; j < changeDatas[i].data.length; j++){
                changeDatas[i].data[j].y = changeDatas[i].data[j].rawy;
                setChartMin(changeDatas[i].data[j].scalemin);
                setChartMax(changeDatas[i].data[j].scalemax);
                setChartStandard((400-(changeDatas[i].data[j].scalestandard*4)));
              }
            }else{
              for(let j = 0; j < changeDatas[i].data.length; j++){
                if( changeDatas[i].data[j].negative ) {
                  changeDatas[i].data[j].y = changeDatas[i].data[j].max ? changeDatas[i].data[j].rawy/changeDatas[i].data[j].scalemax*100 : (changeDatas[i].data[j].max === null ? null : 0 );
                  if( changeDatas[i].data[j].y !== null ) {
                    changeDatas[i].data[j].y = 100 - changeDatas[i].data[j].y;
                  }
                } else {
                  changeDatas[i].data[j].y = changeDatas[i].data[j].max ? changeDatas[i].data[j].rawy/changeDatas[i].data[j].scalemax*100 : (changeDatas[i].data[j].max === null ? null : 0 );
                }
              }
              setChartMin('0');
              setChartMax('100');
              setChartStandard('0');
            }
          }
        }
        if(checkedScales.filter((item) => (item === true)).length === 0){
          setChartStandard('0');
        }
        setDatas(changeDatas);
      }, [200]);
    }, [checkedScales]);
    
    return (
        <>
        <Grid container>
            <Grid item xs={12} sm={12} md={3} style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px'}}>
                <h3 style={{ textAlign: 'center', marginTop: '0px' }}>차트 컨트롤러</h3>
                <p style={{ textAlign: 'center', marginTop: '0px' }}>검사 선택</p>
                <FormControl fullWidth>
                    <FormGroup onChange={handleChange}>
                        <FormControlLabel control={<Checkbox value={'0'} checked={allChecked} />} label={'ALL'} disabled={scales.length === 0}/>
                        {scales.map((scales, i) => (
                        <FormControlLabel key={i} control={<Checkbox value={i+1} checked={checkedScales[i]} />} label={scales} />
                        ))}
                    </FormGroup>
                </FormControl>
                <p style={{ textAlign: 'center', marginTop: '0px' }}>옵션</p>
                <FormControl fullWidth>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label={'배경색'} onChange={() => setBgc(!bgc)}/>
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <Grid height={'500px'} marginBottom={'16px'} style={{ position: 'relative' }}>
                <div style={{ background: 'linear-gradient(blue, yellow, red)', opacity: 0.5, height: '500px', width: '100%', position: 'absolute', display: bgc ? 'block' : 'none' }} />
                  <ResponsiveLine
                    data={filterDatas(datas)}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    yScale={{
                        type: 'linear',
                        min: chartMin,
                        max: chartMax,
                        reverse: false
                    }}
                    layers={[
                      'grid',
                         ({ innerWidth }) => {      
                        if( chartStandard === '0' ) return null;
                        return (
                            <line
                                x1={0}
                                y1={chartStandard}
                                x2={innerWidth}
                                y2={chartStandard}
                                stroke="red"
                                strokeWidth={2}
                            />
                        );
                    },
                      'markers',
                      'areas',
                      'lines',
                      'slices',
                      'axes',
                      'points',
                      'mesh',
                      'legends',
                    ]}
                    yFormat=' >-.2f'
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        legendPosition: 'middle',
                        legend: '상담일',
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '점수',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    colors={{ scheme: 'category10' }}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    tooltip={(e) => (
                      <Grid container sx={{ boxShadow: 1, p: 1, bgcolor: '#FFF', borderRadius: '4px', fontSize: '14px' }} alignItems={'center'}>
                          <Grid item sx={{ pr: 0.5 }}><div style={{ width: '15px', height: '15px', backgroundColor: e.point.serieColor, borderRadius: '7.5px' }}/></Grid>
                          <Grid item sx={{ pr: 1 }}>{e.point.serieId}</Grid>
                          <Grid item sx={{ pr: 1 }}>
                              <span style={{ color: '#C0C0C0'}}>{YyyyMmDd(e.point.data.x)}: </span> {e.point.data.rawy}
                          </Grid>
                      </Grid>
                    )}
                    onClick={(node, _) => handleClickPoint(node)}
                    // theme={{ 'background': bgc ? 'rgba(255, 255, 0, 0.1)' : 'rgba(0, 0, 0, 0)' }}
                />
                </Grid>
                <Grid container alignItems={'stretch'} style={{ padding: '16px 8px 0px 8px'}}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Grid style={{ padding: '0px 8px', height: '100%' }} >
                            <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                <h3 style={{ textAlign: 'center', marginTop: '0px' }}>데이터 요약</h3>
                                <Grid container>
                                    <Grid item xs={8}>검사 수</Grid>
                                    <Grid item xs={4}>{cnt.scale}</Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>결과 수</Grid>
                                    <Grid item xs={4}>{cnt.result}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Grid style={{ padding: '0px 8px', height: '100%' }} >
                            <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                <h3 style={{ textAlign: 'center', marginTop: '0px' }}>검사 평균</h3>
                                <table className='mini_table'>
                                    <thead>
                                        <tr>
                                            <th>검사 이름</th>
                                            <th>첫번째 검사</th>
                                            <th>마지막 검사</th>
                                            <th>증감</th>
                                            <th>증감률</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datas.map((data, i) => (
                                        <tr key={i}>
                                          <td>{data.id}</td>
                                          <td>{dataPoint(data.data, 1)}</td>
                                          <td>{dataPoint(data.data, 2)}</td>
                                          <td>{dataPoint(data.data, 4)}</td>
                                          <td>{dataPoint(data.data, 3)}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {selectScale.Scale_id ?
        <DetailModal
          title={selectScale.Scale_id.Label}
          open={openScaleModal}
          handleClose={() => {setOpenScaleModal(false);setSelectScale({});}}
        >
        <Grid item xs={12}>
            <Grid style={{ marginBottom : '16px' }}>
                {selectScale.Scale_id.Detail}
            </Grid>
            {selectScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
            <Grid key={j} style={{ marginBottom: '8px', padding: '4px 8px' }}>
                {q.ItemKind === 1 ?
                <ItemShortForm
                    item={q} 
                    no={j+1} 
                    scale={selectScale.Scale_id} 
                    subScale={q.SubScale && (j === 0 || q.SubScale !== selectScale.Scale_id.Item_ids[j-1].SubScale)} 
                    lastItem={selectScale.Scale_id.Item_ids.length === j+1}
                    answer={selectScale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                /> :
                q.ItemKind === 2 ?
                <ItemMultipleChoice
                    item={q}
                    no={j+1}
                    scale={selectScale.Scale_id}
                    subScale={q.SubScale && (j === 0 || q.SubScale !== selectScale.Scale_id.Item_ids[j-1].SubScale)}
                    lastItem={selectScale.Scale_id.Item_ids.length === j+1}
                    answer={selectScale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                /> :
                q.ItemKind === 3 ?
                <ItemLikert
                    item={q}
                    no={j+1}
                    scale={selectScale.Scale_id}
                    subScale={q.SubScale && (j === 0 || q.SubScale !== selectScale.Scale_id.Item_ids[j-1].SubScale)}
                    lastItem={selectScale.Scale_id.Item_ids.length === j+1}
                    answer={selectScale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                /> :
                null}
            </Grid>
            ))}
        </Grid>
        </DetailModal>:null}
        </>
    );
};

export default ChartView;