import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Divider, IconButton, Collapse, Radio, RadioGroup } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

import { NumberForm, SearchForm, SelectForm, TimeForm, TextForm } from 'components/Items/Form';
import { DataBox, DataTable } from 'components/Items/Table';

import { SmallCancelButton } from 'components/Items/Button';
import { Risk, SesstionState } from 'components/Function/AddDatas';
import { ItemLikert, ItemMultipleChoice, ItemShortForm } from 'components/Items/View';
import { YyyyMmDd } from 'components/Function/ChangeText';

const SessionView = (props) => {  
    const selectSession = props.selectSession;
    const setSelectSession = props.setSelectSession;
    const [search, setSearch] = useState('');
    
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'StartDateValue', label: '상담 일시'},
                        { key: 'KindDetailValue', label: '상담 종류'},
                        { key: 'ServiceValue', label: '상담 방식' },
                        { key: 'StateValue', label: '상태' }];
    

    const handleClickSessionDetail = (data) => {
        setSelectSession(data);
        setTimeout(() => {
            const sessionTop = window.document.querySelector('#session_detail').offsetTop - 48;
            window.scrollTo(0, sessionTop);
        }, [100]);
    };
    
    const handleClickOpenSacle = (key) => {
        let openScales = { ...props.client };
        openScales.Scales[key].Open = openScales.Scales[key].Open ? false : true;
        props.setClient(openScales);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px', textAlign: 'right' }}>
                    
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={props.client.Schedule_ids}
                        search={search}
                        update={false}
                        delete={false}
                        handleClickDetail={handleClickSessionDetail}
                    />
                </Grid>
            </Grid>
            <Grid id='session_detail' />
            {selectSession.ScheduleNo ?
            <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 1 }}/>
                </Grid>
                <Grid item xs={12}>
                    <h3>상담 정보</h3>
                    <DataBox
                        datas={[
                            { key: '상담 번호', value: selectSession.ScheduleNo },
                            { key: '상담실', value: selectSession.Room_id ? selectSession.Room_id.Name : '' },
                            { key: '상담 일', value: YyyyMmDd(selectSession.StartDate) },
                            { key: '상담 시간', value: `${selectSession.StartTime} ~ ${selectSession.EndTime}` },
                            { key: '상담 종류', value: selectSession.KindDetailValue },
                            { key: '상담 방식', value: selectSession.ServiceValue }
                        ]}
                    />
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0',
                            marginTop: '8px'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            메모
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                padding: '4px 16px'
                            }}
                        >
                            <div 
                                dangerouslySetInnerHTML={{ 
                                    __html: selectSession.Memo.replaceAll('\n', '<br/>')
                                }} 
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 1, mt: 1 }}/>
                </Grid>
                <Grid item xs={12}>
                    <h3>상담 내용</h3>    
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={6}>
                            <TextForm
                                label='상세 상담 내용'
                                required={false}
                                error={false}
                                value={selectSession.Note}
                                rows={8}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={6}>
                            <NumberForm
                                label='기능 평가'
                                required={false}
                                error={false}
                                value={selectSession.GAF}
                                placeholder='1이상 100이하의 정수를 입력해 주세요.'
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={6}>
                            <SelectForm
                                label='위험 수준'
                                required={false}
                                error={false}
                                value={selectSession.Risk}
                                datas={Risk()}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Grid style={{ marginBottom: '8px' }}>
                                    방문 여부
                                </Grid>
                                <RadioGroup row>
                                    {SesstionState().map((item, i) => (
                                    <FormControlLabel key={i} value={item.value} onChange={(e) => setSelectSession({ ...selectSession, State: e.target.value })} control={<Radio checked={Number(selectSession.State) === Number(item.value)}/>} label={item.label} disabled={true}/>
                                    ))}
                                </RadioGroup>
                                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                            </FormControl> 
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 1, mt: 1 }}/>
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{ marginBottom: 0 }}>검사 목록</h3>
                    <Grid container>
                        {props.client.Scales ? 
                        <Grid item xs={12}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <h4>검사</h4>
                                </Grid>
                            </Grid>
                            {props.client.Scales.map((item, i) => (
                            item.Scale_id.ScaleKind === 1 ?
                            <Grid container key={i} style={{ marginBottom: '8px'}}>
                                <Grid container item xs={8} alignItems={'center'}>
                                    <Grid item>
                                        <IconButton onClick={() => handleClickOpenSacle(i)}>
                                            <KeyboardArrowUp style={{ transform: `rotate(${item.Open ? 0 : 180}deg)`, transition: 'all ease 0.25s' }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                                    </Grid>
                                </Grid>
                                {selectSession.ScaleAnswer.filter((s) => (s.Scale_id === item.Scale_id._id)).length === 0 ?
                                null:
                                <Grid item xs={4} spacing={1}  justifyContent={'flex-end'} container>
                                    <Grid item>
                                        <SmallCancelButton
                                            label='PDF'
                                        />
                                    </Grid>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Collapse in={item.Open}>
                                        <Grid style={{ marginBottom : '16px' }}>
                                            {item.Scale_id.Detail}
                                        </Grid>
                                        {item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                                        <Grid key={j} style={{ marginBottom: '8px', padding: '4px 8px' }}>
                                            {q.ItemKind === 1 ?
                                            <ItemShortForm 
                                                item={q} 
                                                no={j+1} 
                                                scale={item.Scale_id} 
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)} 
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 2 ?
                                            <ItemMultipleChoice
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 3 ?
                                            <ItemLikert
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            null}
                                        </Grid>
                                        ))}
                                        <Divider />
                                    </Collapse>
                                </Grid>
                            </Grid>
                            :null
                            ))}
                            <h4>피드백</h4>
                            {props.client.Scales.map((item, i) => (
                            item.Scale_id.ScaleKind === 2 ?
                            <Grid container key={i} style={{ marginBottom: '8px'}}>
                                <Grid container item xs={8} alignItems={'center'}>
                                    <Grid item>
                                        <IconButton onClick={() => handleClickOpenSacle(i)}>
                                            <KeyboardArrowUp style={{ transform: `rotate(${item.Open ? 0 : 180}deg)`, transition: 'all ease 0.25s' }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                                    </Grid>
                                </Grid>
                                {selectSession.ScaleAnswer.filter((s) => (s.Scale_id === item.Scale_id._id)).length === 0 ?
                                null:
                                <Grid item xs={4} spacing={1}  justifyContent={'flex-end'} container>
                                    <Grid item>
                                        <SmallCancelButton
                                            label='PDF'
                                        />
                                    </Grid>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Collapse in={item.Open}>
                                        <Grid style={{ marginBottom : '16px' }}>
                                            {item.Scale_id.Detail}
                                        </Grid>
                                        {item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                                        <Grid key={j} style={{ marginBottom: '8px', padding: '4px 8px' }}>
                                            {q.ItemKind === 1 ?
                                            <ItemShortForm 
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 2 ?
                                            <ItemMultipleChoice
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 3 ?
                                            <ItemLikert
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            null}
                                        </Grid>
                                        ))}
                                        <Divider />
                                    </Collapse>
                                </Grid>
                            </Grid>
                            :null
                            ))}
                        </Grid>:
                        <Grid item xs={12}>
                            등록된 검사가 없습니다.
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>:
            null}
        </>
    );
};

export default SessionView;