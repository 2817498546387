import React from 'react';
import { Grid, Divider, IconButton, Checkbox, RadioGroup, FormControlLabel, Radio, FormGroup } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const ItemMultipleChoiceForm = (props) => {
    return (
        <Grid container justifyContent={'center'}>
            {props.subScale ?
            <Grid item xs={12} style={{ paddingTop: props.no === 1 ? '0px' : '16px', marginBottom: '16px' }}>
                <span style={{ color: 'rgb(139, 139, 139)'}}>{props.scale.SubScale[props.item.SubScale-1]}</span>
            </Grid>:
            null
            }
            <Grid item xs={props.update ? 10 : 12} container>
                <Grid item xs={12} style={{ fontSize: '20px' }}>
                    Q{props.no}. {props.item.Content}
                </Grid>
            </Grid>
            {props.update ?
            <Grid item xs={2} style={{ textAlign: 'right'}}>
                <IconButton style={{ marginRight: '8px'}} onClick={props.delete}>
                    <Delete />
                </IconButton>
                <IconButton onClick={props.update}>
                    <Edit />
                </IconButton>
            </Grid>:null}
            <Grid item xs={12} container style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                {/* <Grid item xs={1}>
                    A{props.no}.&nbsp;
                </Grid> */}
                <Grid item xs={12}>
                    {props.item.Choices?.map((item, i) => (
                    props.item.MultipleCheck ?
                    <FormGroup key={i}>
                        <FormControlLabel
                            value={i}
                            label={item}
                            onChange={(e) => props.handleChange(e, props.item._id, true)}
                            control={<Checkbox checked={Boolean(props.answer?.indexOf(`${i}`) > -1)}/>}
                        />    
                    </FormGroup>:
                    <RadioGroup key={i}>
                        <FormControlLabel
                            value={i}
                            label={item}
                            onChange={(e) => props.handleChange(e, props.item._id)}
                            control={<Radio checked={Boolean(props.answer?.indexOf(`${i}`)> -1)}/>}
                        />
                    </RadioGroup>
                    ))}
                </Grid>
            </Grid>
            {!props.lastItem ?
            <Grid item xs={11.5} style={{ paddingBottom: '4px' }}>
                <Divider />
            </Grid>:null}
        </Grid>
    );
};

export default ItemMultipleChoiceForm;