import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Client, Supervisee, Member, Team, Room, Group } from './Manage';
import NotFound from 'routes';

const ManageRoute = () => {
    return (
        <Routes>
            <Route path='client/*' element={<Client />} />
            <Route path='supervisee/*' element={<Supervisee />} />
            <Route path='member/*' element={<Member />} />
            <Route path='team/*' element={<Team />} />
            <Route path='room/*' element={<Room />} />
            <Route path='group/*' element={<Group />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default ManageRoute;