import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { UserBasicView } from 'components';

const UserBasicContainer = () => {

    const { clientNo, codeKey } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [key, setKey] = useState('');
    const [client, setClient] = useState({
        NickName: '', // 내담자 ID
        Name: '', // 이름
        Email: '', // 이메일
        EmailCheck: true,
        Phone: '', // 연락처
        Counselor_id: { _id : '' }, // 담당자 _id
        CounselorId: '',
        GroupId: '',
        Risk: '', // 위험도
        Address: '', // 현주소
        Brith: '', // 생년월일
        Gender: '', // 성별
        Marriage: '', // 결혼 유무
        Military: '', // 병역
        Education: '', // 최종학력
        Concern: '', // 현재 고민이나 어려움
        Employment: '', // 직업
        Living: '', // 주거형태
        LivingDetail: '', // 주거형태 상세
        Dependents: '', // 부양가족 수
        Nationality: '', // 국적
        NationalityDetail: '', // 국적 상세
        Religion: '', // 종교
        Medication: '', // 복용약물
        MedicationDetail: '', //복용약물 상세
        Family: [{ // 가족 사항
            Relation: '', // 관계
            Age: '', // 연령
            Name: '', // 이름
            Phone: '' // 연락처
        }],
        Referral: '', // 찾아오게된 경위
        ReferralDetail: '', // 경위 상세
        DesiredService: [], // 찾아온 목적
        Reason: [],  // 상담 받고 싶은 점
        DesiredTest: [], // 받고 싶은 검사
        PriorTreatment: {  // 이전 검사
            PriorExperience: false, // 여부
            When: '', // 언제
            Where: '', // 어디서 
            How: [], // 치료 유형
        },
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []] // 상담 가능 요알, 시간
    });
    const [members, setMembers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [emailLoading, setEmailLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        const loginKey = cookies.LoginKey ? cookies.LoginKey : key;
        await axios.put(`/api/manager/client/${clientNo}/${loginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    setUpdating(false);
                    enqueueSnackbar('수정을 완료했습니다.', { variant: 'success' });
                }, [1200]);
            }else{
                setUpdating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setUpdating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickEmailCheck = async() => {
        setEmailLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.post(`/api/manager/client/email/${cookies.LoginKey}`, body, config)
        .then((res) => {
            setTimeout(() => {
                if(!res.data.ErrorMessage){
                    setClient({ ...client, EmailCheck: true });
                    setEmailLoading(false);
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }else{
                    setClient({ ...client, Email: '' });
                    setEmailLoading(false);
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            }, [1200]);
        })
        .catch((err) => {
            console.log(err);
            setEmailLoading(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        if(cookies.LoginKey){
            await axios.get(`/api/manager/client/${clientNo}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    let loadClient = res.data.ClientData;
                    loadClient.Email = loadClient.Email_decrypt;
                    loadClient.Phone = loadClient.Phone_decrypt;
                    loadClient.Name = loadClient.Name_decrypt;
                    loadClient.EmailCheck = true;
                    loadClient.Available = loadClient.Available.length ? loadClient.Available : [[], [], [], [], [], [], []];
                    loadClient.AvailableDay = [];
                    loadClient.CounselorId = loadClient.Counselor_id ? loadClient.Counselor_id._id : '';
                    loadClient.GroupId = loadClient.selectedgroup;
                    for(let i = 0; i < loadClient.Available.length; i++){
                        if(loadClient.Available[i].length){
                            loadClient.AvailableDay.push((i+1).toString());
                            for(let j = 0; j < loadClient.Available[i].length; j++){
                                loadClient.Available[i][j] = loadClient.Available[i][j].toString();
                            }
                        }
                    }
                    setClient(loadClient);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
            await axios.get(`/api/employee/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    let loadMembers = res.data.Workers;
                    loadMembers = loadMembers.filter((item) => (item.Status === 1 || item.Status === 2));
                    for(let i = 0; i < loadMembers.length; i++){
                        loadMembers[i].value = loadMembers[i]._id;
                        loadMembers[i].label = loadMembers[i].Name;
                    }
                    setMembers(loadMembers);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
            await axios.get(`/api/manager/group/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    let loadGroups = res.data.GroupDatas;
                    for(let i = 0; i < loadGroups.length; i++){
                        loadGroups[i].value = loadGroups[i]._id;
                        loadGroups[i].label = loadGroups[i].Name;
                        if(loadGroups[i].Client_ids.indexOf(client._id) > -1){
                            setClient({ ...client, Group_id: loadGroups[i]._id });
                        }
                    }
                    setGroups(loadGroups);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }else{
            await axios.get(`/api/login/codeKey/${codeKey}`, config)
            .then(async(res) => {
                if(!res.data.ErrorMessage){
                    setKey(res.data.CodeKey.LoginKey);
                    await axios.get(`/api/manager/client/${clientNo}/${res.data.CodeKey.LoginKey}`, config)
                    .then((res) => {
                        if(!res.data.ErrorMessage){
                            let loadClient = res.data.ClientData;
                            loadClient.Email = loadClient.Email_decrypt;
                            loadClient.Phone = loadClient.Phone_decrypt;
                            loadClient.Name = loadClient.Name_decrypt;
                            loadClient.EmailCheck = true;
                            loadClient.Available = loadClient.Available.length ? loadClient.Available : [[], [], [], [], [], [], []];
                            loadClient.AvailableDay = [];
                            loadClient.CounselorId = loadClient.Counselor_id ? loadClient.Counselor_id._id : '';
                            loadClient.GroupId = loadClient.selectedgroup;
                            for(let i = 0; i < loadClient.Available.length; i++){
                                if(loadClient.Available[i].length){
                                    loadClient.AvailableDay.push((i+1).toString());
                                    for(let j = 0; j < loadClient.Available[i].length; j++){
                                        loadClient.Available[i][j] = loadClient.Available[i][j].toString();
                                    }
                                }
                            }
                            setClient(loadClient);
                        }else{
                            enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        enqueueSnackbar('Network Error!', { variant: 'error' });
                    });
                    await axios.get(`/api/employee/${res.data.CodeKey.LoginKey}`, config)
                    .then((res) => {
                        if(!res.data.ErrorMessage){
                            let loadMembers = res.data.Workers;
                            loadMembers = loadMembers.filter((item) => (item.Status === 1 || item.Status === 2));
                            for(let i = 0; i < loadMembers.length; i++){
                                loadMembers[i].value = loadMembers[i]._id;
                                loadMembers[i].label = loadMembers[i].Name;
                            }
                            setMembers(loadMembers);
                        }else{
                            enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        enqueueSnackbar('Network Error!', { variant: 'error' });
                    });
                    await axios.get(`/api/manager/group/${res.data.CodeKey.LoginKey}`, config)
                    .then((res) => {
                        if(!res.data.ErrorMessage){
                            let loadGroups = res.data.GroupDatas;
                            for(let i = 0; i < loadGroups.length; i++){
                                loadGroups[i].value = loadGroups[i]._id;
                                loadGroups[i].label = loadGroups[i].Name;
                                if(loadGroups[i].Client_ids.indexOf(client._id) > -1){
                                    setClient({ ...client, Group_id: loadGroups[i]._id });
                                }
                            }
                            setGroups(loadGroups);
                        }else{
                            enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }
    }, [enqueueSnackbar, cookies.LoginKey, setMembers, clientNo, setClient, setGroups]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        <UserBasicView
            members={members}
            groups={groups}
            client={client}
            setClient={setClient}
            updating={updating}
            handleClickUpdateBtn={handleClickUpdateBtn}
            emailLoading={emailLoading}
            handleClickEmailCheck={handleClickEmailCheck}
        />
    );
};

export default UserBasicContainer;