import React, { useEffect, useState } from 'react';
import { FormControl, Grid, RadioGroup, Radio, FormControlLabel, FormHelperText, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { FormContainer } from 'components/Items/Container';
import { TextForm, SelectForm } from 'components/Items/Form';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';

const ItemCreateView = (props) => {
    const itemData = props.itemData;
    const setItemData = props.setItemData;
    const navigate = useNavigate();
    const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
    const handleChangeContent = (e) => {
        setItemData({ ...itemData, Content: e.target.value });
    };
    const handleChangeItemKind = (e) => {
        setItemData({ ...itemData, ItemKind: Number(e.target.value) });
    };
    const handleChangeSubScale = (e) => {
        setItemData({ ...itemData, SubScale: Number(e.target.value) });
    };
    const handleChangeMultipleCheck = () => {
        setItemData({ ...itemData, MultipleCheck: !itemData.MultipleCheck });
    };
    const handleChangeLabelKind = (e) => {
        setItemData({ ...itemData, LabelKind: Number(e.target.value) });
    };
    const handleChangeChoices = (e, key) => {
        let changeSubScale = { ...itemData };
        changeSubScale.Choices[key] = e.target.value;
        setItemData(changeSubScale);
    };
    const handleChangePoints = (e, key) => {
        let changeItemData = { ...itemData };
        changeItemData.Points[key] = e.target.value;
        setItemData(changeItemData);
    };
    const handleClickPlus = () => {
        let changeItemData = { ...itemData };
        if(itemData.LabelKind === 1 && changeItemData.Choices.length > 1){
            for(let i = 1; i < changeItemData.Choices.length; i++){
                changeItemData.Choices[i] = '';
            }
        }
        changeItemData.Choices.push('');
        changeItemData.Points.push('');
        setItemData(changeItemData);
    };
    const handleClickMinus = () => {
        let changeItemData = { ...itemData };
        changeItemData.Choices.pop();
        changeItemData.Points.pop();
        setItemData(changeItemData);
    };

    useEffect(() => {
        let tump = false;
        if(!itemData.Content){
            tump = true;
        }
        if(!itemData.ItemKind){
            tump = true;
        }
        if(itemData.ItemKind === 2){
            for(let i = 0; i < itemData.Choices.length; i++){
                if(!itemData.Choices[i]){
                    tump = true;
                    break;
                }
            }
        }
        if(itemData.ItemKind === 3){
            if(itemData.LabelKind === 1){
                if(!itemData.Choices[0]) tump = true;
                if(!itemData.Choices[itemData.Choices.length - 1]) tump = true;
            }else if(itemData.LabelKind === 2){
                for(let i = 0; i < itemData.Choices.length; i++){
                    if(!itemData.Choices[i]){
                        tump = true;
                        break;
                    }
                }
            }else{
                tump = true;
            }
        }
        setDisabledCreateBtn(tump);
    }, [itemData, setDisabledCreateBtn]);

    useEffect(() => {
        let changeItemData = { ...itemData };
        if(itemData.LabelKind === 1 && changeItemData.Choices.length > 1){
            for(let i = 1; i < changeItemData.Choices.length-1; i++){
                changeItemData.Choices[i] = '';
            }
        }
        setItemData(changeItemData);
        
    }, [itemData, setItemData]);

    return (
        <FormContainer title='문항 생성'>
            <TextForm
                label='문항 내용'
                required={true}
                error={false}
                value={itemData.Content}
                placeholder='문항 내용을 입력해주세요.'
                handleChange={handleChangeContent}
            />
            <SelectForm
                label='하위 요인'
                required={false}
                error={false}
                value={itemData.SubScale}
                datas={props.subScales}
                disabled={props.subScales.length === 0}
                handleChange={handleChangeSubScale}
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    유형
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={1} control={<Radio onChange={handleChangeItemKind} checked={itemData.ItemKind === 1} />} label={'주관식'} />
                    <FormControlLabel value={2} control={<Radio onChange={handleChangeItemKind} checked={itemData.ItemKind === 2} />} label={'객관식'} />
                    <FormControlLabel value={3} control={<Radio onChange={handleChangeItemKind} checked={itemData.ItemKind === 3} />} label={'리커트'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {itemData.ItemKind === 2 ?
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    복수 선택
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={true} control={<Radio onChange={handleChangeMultipleCheck} checked={itemData.MultipleCheck} />} label={'O'} />
                    <FormControlLabel value={false} control={<Radio onChange={handleChangeMultipleCheck} checked={!itemData.MultipleCheck} />} label={'X'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>:null}
            {itemData.ItemKind === 3 ?
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    라벨 출력
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={1} control={<Radio onChange={handleChangeLabelKind} checked={itemData.LabelKind === 1} />} label={'양끝'} />
                    <FormControlLabel value={2} control={<Radio onChange={handleChangeLabelKind} checked={itemData.LabelKind === 2} />} label={'전체'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>:null}
            {itemData.ItemKind === 2 || itemData.ItemKind === 3 ?
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={6}>
                    선택지
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClickMinus} >
                        <Remove/>
                    </IconButton>
                    <IconButton onClick={handleClickPlus}>
                        <Add />
                    </IconButton>
                </Grid>
                {itemData.Choices.map((_, i) => (
                <Grid key={i} container spacing={1}>
                    <Grid item xs={6}>
                        <TextForm
                            required={false}
                            error={false}
                            value={itemData.Choices[i]}
                            disabled={itemData.LabelKind === 1 && (i !== 0 && i !== itemData.Choices.length-1)}
                            placeholder='선택지 내용'
                            handleChange={(e) => handleChangeChoices(e, i)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextForm
                            key={i}
                            required={false}
                            error={false}
                            value={itemData.Points[i]}
                            placeholder='점수'
                            handleChange={(e) => handleChangePoints(e, i)}
                        />
                    </Grid>
                </Grid>
                ))}
            </Grid>
            :null}
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton 
                        label='생성'
                        handleClick={props.handleClickCreateBtn}
                        disabled={disabledCreateBtn}
                        loading={props.creating}
                    />
                </Grid>
            </Grid>
        </FormContainer>
    );
};

export default ItemCreateView;