import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';

const ClientUpdateView = () => {
    const navigate = useNavigate();
    return (
        <>
        <FormContainer title='내담자 수정'>

            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton label='수정' handleClick={(e) => navigate(`/client/10001`)}/>
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default ClientUpdateView;