import React from 'react';
import { Grid, FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
/**
 * 
 * @param {string} value
 * @param {function(): string} handleChange 
 * @param {boolean} error
 * @param {string} label
 * @param {boolean} required
 * @param {function(): boolean} handleClick
 * @param {boolean} showPassword
 * @description 
 */
const PasswordForm = (props) => {
    const handlePressEnter = (e) => {
        if(e.key === 'Enter'){
            props.handlePressEnter();
        }else{
            return false;
        }
    };

    return (
        <>
            <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                {props.label ?
                    <Grid style={{ marginBottom: '8px' }}>
                        {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
                    </Grid> :
                    null
                }
                <OutlinedInput
                    type={props.showPassword ? 'text' : 'password'}
                    placeholder={props.placeholder ? props.placeholder : '비밀번호를 입력해주세요.'}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                onClick={props.handleClick}
                                onMouseDown={props.handleClick}
                                edge='end'
                            >
                                {props.showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                            </IconButton>
                        </InputAdornment>
                    }
                    value={props.value || ''}
                    onChange={props.handleChange}
                    onKeyPress={handlePressEnter}
                />
                {props.error ? <FormHelperText style={{ marginLeft: 0 }}>&nbsp;{props.errorMessage ? props.errorMessage : ' '}</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>}
            </FormControl>
        </>
    );
};

export default PasswordForm;