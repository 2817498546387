import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ScheduleDetailView } from 'components/User/Schedule';

const ScheduleDetailContainer = (props) => {
    const { scheduleNo } = useParams();
    const pages = [
        { name: '스케줄', link: '' },
        { name: '스케줄 상세', link: '' }
    ];

    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [schedule, setSchedule] = useState({
        Client_id: { Name_decrypt: '', NickName: '' },
        Counselor_id: { Name: '' },
        Kind: { State: 1, Detail: '' },
        Room_id: { Name: '' },
        Repeat: { State: false, Count: '', Days: '' }
    });
    const [user, setUser] = useState({});

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        if(schedule.Kind.State === 1){
            await axios.delete(`/api/manager/session/${schedule.ScheduleNo}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                        navigate('/schedule');
                    }, [250]);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }else{
            await axios.delete(`/api/schedule/${schedule.ScheduleNo}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                        navigate('/schedule');
                    }, [250]);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/schedule/${scheduleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadSchedule = { ...res.data.ScheduleData };
                setUser(res.data.UserData)
                setSchedule(loadSchedule);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setSchedule, enqueueSnackbar, cookies.LoginKey, scheduleNo]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScheduleDetailView 
                schedule={schedule}
                user={user}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default ScheduleDetailContainer;