import React, { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ClientListView } from 'components/User/Manage/Client';
import { CalcAge, GenderKo, YyyyMmDd, YyyyMmDdHhMmSs, ClientStateKo, YyMmDd, ClientClosingKo } from 'components/Function/ChangeText';
import { CSVLink } from "react-csv";

const ClientListContainer = (props) => {
    const pages = [
        { name: '내담자 관리', link: '' },
        { name: '내담자 목록', link: '' }
    ];
    
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [clients, setClients] = useState([{
        ClientNo: '',
        Name_decrypt: '',
        Phone_decrypt: '',
        Email_decrypt: '',
        BirthValue: '',
        GenderValue: '',
        AgeBirth: '',
        CounselorName: '',
        GroupName: '',
    }]);
    const [selectClient, setSelectClient] = useState({});
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClickDownload = () => {
        setLoading(true);
        let rowData = [...clients];
        let csvData = [
            ['No.', '내담자ID', '이름', '현황', '나이/생년월일', '성별', '연락처', '이메일', '담당자', '그룹']
        ];
        for(let i = 0; i < rowData.length; i++){
            csvData.push([clients[i].No, clients[i].NickName, clients[i].Name_decrypt, ClientStateKo(clients[i].State), CalcAge(clients[i].Birth) + '/' + YyyyMmDd(clients[i].Birth), GenderKo(clients[i].Gender), clients[i].Phone_decrypt, clients[i].Email_decrypt, clients[i].CounselorName, clients[i].GroupName]);
        }
        setCsvData(csvData);
        setTimeout(() => {
            clickLink();
            setLoading(false);
            enqueueSnackbar('다운로드가 완료되었습니다.', { variant: 'info' });
        }, [2000]);
    };
    const clickLink = () => {
        window.document.getElementById('csv_download').click();
    };

    const handleClickDeleteUserBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/client/${selectClient.ClientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                loadData();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/client/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadClients = res.data.ClientDatas;
                for(let i = 0; i < loadClients.length; i++){
                    // loadClients[i].AgeBirth = CalcAge(loadClients[i].Birth) + '/' + YyyyMmDd(loadClients[i].Birth);
                    loadClients[i].BirthValue = YyyyMmDd(loadClients[i].Birth);
                    loadClients[i].GenderValue = GenderKo(loadClients[i].Gender);
                    loadClients[i].CounselorName = loadClients[i].Counselor_id ? loadClients[i].Counselor_id.Name : '';
                    loadClients[i].GroupName = loadClients[i].Group.length ? loadClients[i].Group[0].Name : '';
                    loadClients[i].StateName = ClientStateKo(loadClients[i].State);
                    loadClients[i].CreatedAtValue = YyMmDd(loadClients[i].createdAt);
                }
                setClients(loadClients);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [enqueueSnackbar, cookies.LoginKey, setClients]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ClientListView
                clients={clients}
                selectClient={selectClient}
                setSelectClient={setSelectClient}
                handleClickDeleteUserBtn={handleClickDeleteUserBtn}
                handleClickDownload={handleClickDownload}
            />
            <CSVLink style={{ display: 'none' }} data={csvData} separator=',' id='csv_download' filename={`download_${YyyyMmDdHhMmSs(new Date())}`}>Download me</CSVLink>
        </DashboardLayout>
    );
};

export default ClientListContainer;