import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { ScaleSessionView } from 'components';

const ScaleSessionContainer = () => {
    const { scheduleNo, clientNo, scaleNo, codeKey } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [scale, setScale] = useState({
        NickName: '',
        Scales: [{
            Scale_id: {
                Label: '',
                Item_ids: []
            }
        }],
        ScaleAnswer: [{
            Scale_id: '',
            Answer: [{
                Item_id: '',
                Answer: []
            }]
        }]
    });
    const [key, setKey] = useState('');

    const handleClickSave = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({ Answer: scale.ScaleAnswer });
        const loginKey = cookies.LoginKey ? cookies.LoginKey : key;
        await axios.post(`/api/scale/${scheduleNo}/${clientNo}/${loginKey}`, body, config)
        .then((res) => {
            if(res.data.ErrorMessage){
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }else{
                enqueueSnackbar(res.data.Message, { variant: 'info' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };
    
    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        if(cookies.LoginKey){
            await axios.get(`/api/scale/${scheduleNo}/${clientNo}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    let loadScales = { ...res.data.ScalesData };
                    loadScales.Scales = loadScales.Scales.filter((item) => (item.Scale_id?.State === 1));
                    setScale(loadScales);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }else{
            await axios.get(`/api/login/codeKey/${codeKey}`, config)
            .then(async(res) => {
                if(!res.data.ErrorMessage){
                    setKey(res.data.CodeKey.LoginKey);
                    await axios.get(`/api/scale/${scheduleNo}/${clientNo}/${res.data.CodeKey.LoginKey}`, config)
                    .then((res2) => {
                        if(!res2.data.ErrorMessage){
                            let loadScales = { ...res2.data.ScalesData };
                            loadScales.Scales = loadScales.Scales.filter((item) => (item.Scale_id.State === 1));
                            console.log(loadScales)
                            setScale(loadScales);
                        }else{
                            enqueueSnackbar(res2.data.ErrorMessage, { variant: 'error' });
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar('Network Error!', { variant: 'error' });
                        console.log(err);
                    });
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }
    }, [enqueueSnackbar, setScale]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        <ScaleSessionView
            scale={scale}
            setScale={setScale}
            handleClickSave={handleClickSave}
            scaleNo={scaleNo}
        />
    );
};

export default ScaleSessionContainer;