import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RoomList, RoomCreate, RoomDetail, RoomUpdate } from 'domains/User/Manage/Room';
import NotFound from 'routes';

const RoomRoute = () => {
    return (
        <Routes>
            <Route path='' element={<RoomList />} />
            <Route path='create' element={<RoomCreate />} />
            <Route path=':roomNo' element={<RoomDetail />} />
            <Route path=':roomNo/update' element={<RoomUpdate />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default RoomRoute;