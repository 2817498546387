import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ScheduleUpdateView } from 'components/User/Schedule';

const ScheduleUpdateContainer = (props) => {
    const { scheduleNo } = useParams();
    const pages = [
        { name: '스케줄', link: '' },
        { name: '스케줄 상세', link: `/schedule/${scheduleNo}` },
        { name: '스케줄 수정', link: '' }
    ];

    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [schedule, setSchedule] = useState({
        Counselor_id: { Status: '' },
        Kind: { State: '', Detail: '' },
        Repeat: { State: '', Count: '', Days: '' }
    });
    const [clients, setClients] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [members, setMembers] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [user, setUser] = useState({});

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(schedule);
        if(schedule.Kind.State === 1){
            await axios.put(`/api/manager/session/${scheduleNo}/${cookies.LoginKey}`, body, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                        navigate(`/schedule/${scheduleNo}`);
                    }, [1500]);
                }else{
                    setUpdating(false);
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                setUpdating(false);
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }else{
            await axios.put(`/api/schedule/${scheduleNo}/${cookies.LoginKey}`, body, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                        navigate(`/schedule/${scheduleNo}`);
                    }, [1500]);
                }else{
                    setUpdating(false);
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                setUpdating(false);
                enqueueSnackbar('Network Error!', { variant: 'error' });
                console.log(err);
            });
        }
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/client/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let tump = [];
                for(let i = 0; i < res.data.ClientDatas.length; i++){
                    tump.push({ value: res.data.ClientDatas[i]._id, label: res.data.ClientDatas[i].Name_decrypt ? `${res.data.ClientDatas[i].Name_decrypt} (${res.data.ClientDatas[i].NickName})` : res.data.ClientDatas[i].NickName });
                }
                setClients(tump);
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/employee/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadMembers = res.data.Workers;
                loadMembers = loadMembers.filter((item) => (item.Status === 1 || item.Status === 2));
                for(let i = 0; i < loadMembers.length; i++){
                    loadMembers[i].value = loadMembers[i]._id;
                    loadMembers[i].label = loadMembers[i].Name;
                }
                setMembers(loadMembers);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/manager/room/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadRooms = res.data.RoomDatas;
                loadRooms = loadRooms.filter((item) => (item.State === 1));
                for(let i = 0; i < loadRooms.length; i++){
                    loadRooms[i].value = loadRooms[i]._id;
                    loadRooms[i].label = loadRooms[i].Name;
                }
                setRooms(loadRooms);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/schedule/${scheduleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadSchedule = { ...res.data.ScheduleData };
                setUser(res.data.ScheduleData.Counselor_id);
                loadSchedule.Counselor_id = loadSchedule.Counselor_id._id;
                if(loadSchedule.Client_id){
                    loadSchedule.Client_id = loadSchedule.Client_id._id;
                }                
                setSchedule(loadSchedule);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setSchedule, enqueueSnackbar, cookies.LoginKey, scheduleNo, setUser, setClients, setRooms, setMembers]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScheduleUpdateView 
                schedule={schedule}
                setSchedule={setSchedule}
                rooms={rooms}
                setRooms={setRooms}
                clients={clients}
                user={user}
                members={members}
                updating={updating}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default ScheduleUpdateContainer;