import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';

import { SelectScheduleBorderColor, SelectScheduleBgColor, ScheduleKindKo } from 'components/Function/ChangeText';

const DataCalendarMonth = (props) => {
    if(props.date){
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const firstDay = new Date(new Date(props.date).setDate(1));
        const lastDay = new Date(new Date(new Date(new Date(props.date).setDate(1)).setMonth(new Date(new Date(props.date)).getMonth()+1)).setDate(0));
        let days = [[]];
        let counter = 0;
        const tumpDate = new Date(props.date);
        for(let i = firstDay.getDay(); i > 0; i--){
            days[counter].push(new Date(tumpDate.getFullYear(), tumpDate.getMonth(), 1-i));
        }
        for(let i = 0; i < lastDay.getDate(); i++){
            if(days[counter].length === 7){
                days.push([]);
                counter++;
            }
            days[counter].push(new Date(tumpDate.getFullYear(), tumpDate.getMonth(), i+1));
        }
        for(let i = 0; i < 6-lastDay.getDay(); i++){
            if(days[counter].length === 7){
                days.push([]);
                counter++;
            }
            days[counter].push(new Date(tumpDate.getFullYear(), tumpDate.getMonth()+1, i+1));
        }

        return (
            <Grid className='table_container'>
                <table className='month_table'>
                    <thead>
                        <tr>
                            <th>일</th>
                            <th>월</th>
                            <th>화</th>
                            <th>수</th>
                            <th>목</th>
                            <th>금</th>
                            <th>토</th>
                        </tr>
                    </thead>
                    <tbody>
                        {days.map((weeks, i) => (
                        <tr key={i}>
                            {weeks.map((day, j) => (
                            <td key={j}
                                style={{ border: new Date(props.date).toString() === new Date(day).toString() ? '1px solid #C6E7CF' : '1px solid #FFF', boxSizing: 'border-box' }}
                                onClick={() => {props.handleClick(new Date(day)); }}
                            >
                                <div className={
                                    new Date(today).toString() === new Date(day).toString() ?
                                        'day_today' :
                                        new Date(day) < firstDay || new Date(day) > lastDay ?
                                            'day_other' :
                                            'day_this'       
                                    }
                                >
                                    <p>{new Date(day).getDate()}</p>    
                                    <Grid container style={{ color: '#252525' }} spacing={1} alignItems={'center'}>
                                        {props.datas.filter((item) => (new Date(new Date(item.StartDate).setHours(0, 0, 0)).toString() === new Date(day).toString())).map((item, k) => (
                                        k < 2 ?
                                        <Grid item xs={12} key={k} className='month_schedule'>
                                            <Grid container className={`schedule_border_color${SelectScheduleBorderColor(item.Kind.Detail, item.Date, item.State, item.Visit)}`}>
                                                <Grid item xs={4} className={`schedule_bg_color${SelectScheduleBgColor(item.Kind.Detail)}`} style={{ fontSize: '12px'}}>
                                                    {ScheduleKindKo(item.Kind.Detail)}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {item.Counselor_id.Name}
                                                </Grid>
                                            </Grid>
                                        </Grid> :
                                        null
                                        ))}
                                    </Grid>
                                </div>
                                {props.handleClickCreateSchdule ?
                                <IconButton className='hidden_button' onClick={(e)=>{ e.stopPropagation(); props.handleClickCreateSchdule(day);}}>
                                    <Add style={{ fontSize: '14px'}}/>
                                </IconButton>:null}
                            </td>
                            ))}
                        </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        );
    }else{
        return false;
    }
};

export default DataCalendarMonth;