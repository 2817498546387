import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { DashboardLayout } from 'components/Layout';
import { ScaleCreateView } from 'components/Admin/Scale';

const ScaleCreateContainer = (props) => {
    const pages = [
        { name: '검사', link: '/admin/scale' },
        { name: '검사 생성', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [creating, setCreating] = useState(false);
    const [scale, setScale] = useState({
        Label: '',
        ScaleKind: 1,
        CalScore: false,
        Negative: false,
        SubScale: [''],
        Content: '',
        Detail: '',
        Min: 0,
        Max: 0,
        Standard: 0,
        SubMin: 0,
        SubMax: 0,
        RCI: 0
    });
    const handleClickCreateBtn = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(scale);
        await axios.post(`/api/admin/scale/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/admin/scale/${res.data.Result.ScaleNo}`);
                }, [1200]);
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                setTimeout(() => {
                    setCreating(false);
                }, [1200]);
            }
        })
        .catch((err) => {
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScaleCreateView
                scale={scale}
                setScale={setScale}
                handleClickCreateBtn={handleClickCreateBtn}
                creating={creating}
            />
        </DashboardLayout>
    );
};

export default ScaleCreateContainer;