import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PDFView from 'components/ScalePDFView';

const ScaleBasicContainer = () => {
    const { scheduleNo, scaleNo } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [loading, setLoading] = useState(true);
    const [scale, setScale] = useState({        
        Scale_id: {
            Item_ids: []
        },
        Answer: {
            Item_id: '',
            Answer: []
        }
    });
    const [clientName, setClientName] = useState('');
    const [schedule, setSchedule] = useState({});
    window.onafterprint = function(){
        window.close();
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/scale/pdf/${scheduleNo}/${scaleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setScale(res.data.ScaleData);
                setClientName(res.data.ClientName);
                setSchedule(res.data.ScheduleData);
                setTimeout(() => {
                    setLoading(false);
                    setTimeout(() => {
                        window.print();
                    }, [200]);
                }, [1800]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [enqueueSnackbar, setScale]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        <PDFView 
            scale={scale}
            loading={loading}
            clientName={clientName}
            schedule={schedule}
        />
    );
};

export default ScaleBasicContainer;