import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LoginLayout } from 'components/Layout';
import { TextForm } from 'components/Items/Form';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';

const ContactView = (props) => {
    const { contact, setContact } = props;
    const navigate = useNavigate();

    return (
        <>
        <LoginLayout>
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                    minHeight: '100vh',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                    position: 'relative',
                    zIndex: '2'
                }}
            >
                <Grid
                    xs={12} sm={10} md={8} lg={6} xl={4}
                    item
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '30px',
                        padding: '60px 4%'
                    }}
                >
                    <h1 style={{ textAlign: 'center' }}>문의하기</h1>
                    <TextForm
                        label='이름'
                        required={true}
                        error={false}
                        value={contact.Name}
                        handleChange={(e) => setContact({ ...contact, Name: e.target.value })}
                    />
                    <TextForm
                        label='연락처'
                        required={true}
                        error={false}
                        value={contact.Phone}
                        handleChange={(e) => setContact({ ...contact, Phone: e.target.value })}
                    />
                    <TextForm
                        label='이메일'
                        required={true}
                        error={false}
                        value={contact.Email}
                        handleChange={(e) => setContact({ ...contact, Email: e.target.value })}
                    />
                    <TextForm
                        label='문의 내용'
                        required={true}
                        error={false}
                        rows={5}
                        value={contact.Content}
                        handleChange={(e) => setContact({ ...contact, Content: e.target.value })}
                    />
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item xs={6} md={4} lg={3}>
                            <LargeCancelButton
                                label='이전'
                                loading={false}
                                handleClick={() => navigate(-1)}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3}>
                            <LargeSubmitButton
                                label='문의하기'
                                loading={false}
                                handleClick={props.handleClickSendBtn}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LoginLayout>
        </>
    );
};

export default ContactView;