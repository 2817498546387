import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { MemberList, MemberCreate, MemberDetail, MemberUpdate } from 'domains/User/Manage/Member';
import NotFound from 'routes';

const MemberRoute = () => {
    return (
        <Routes>
            <Route path='' element={<MemberList />} />
            <Route path='create' element={<MemberCreate />} />
            <Route path=':memberNo' element={<MemberDetail />} />
            <Route path=':memberNo/update' element={<MemberUpdate />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default MemberRoute;