import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './reducer/store';

const theme = createTheme({  
  palette: {
      primary: { main: '#A1D8A0' },
      secondary: { main: '#C0C0C0' },
      success: { main: '#255F0B' },
      info: { main: '#0647A9' },
      error: { main: '#A92525'},
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)'
      }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paperFullWidth: {
          borderRadius: '15px'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px'
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFF'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '10px',
          '&.Mui-selected': {
            color: '#000',
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          backgroundColor: '#FFF',
          '&:hover': {
            backgroundColor: '#FFF'
          }
        },
        containedPrimary: {
          color: '#FFF'
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <CookiesProvider>
        <SnackbarProvider 
          maxSnack={3} 
          anchorOrigin={
            {
              vertical: 'bottom',
              horizontal: 'left',
            }
          }
          >
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </SnackbarProvider>
      </CookiesProvider>
    </Router>
  </Provider>
  // </React.StrictMode>,
);

reportWebVitals();