import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import SearchPassowrdView from 'components/SearchPasswordView';

const SearchPasswordContainer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { codeKey } = useParams();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    const handleClickBtn = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(user);
        await axios.patch(`/api/user/password`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    navigate('/login');
                }, [1500]);
            }else{
                setLoading(true);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            setLoading(true);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/login/codeKey/${codeKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                console.log(res.data.CodeKey)
                setUser(res.data.CodeKey);
            }else{
                enqueueSnackbar('만료된 링크입니다.', { variant: 'error' });
                navigate('/login');
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            navigate('/login');
        });
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        <SearchPassowrdView
            user={user}
            setUser={setUser}
            handleClickBtn={handleClickBtn}
            loading={loading}
        />
    );
};

export default SearchPasswordContainer;