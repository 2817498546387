import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { GroupList, GroupCreate, GroupDetail, GroupUpdate } from 'domains/User/Manage/Group';
import NotFound from 'routes';

const GroupRoute = () => {
    return (
        <Routes>
            <Route path='' element={<GroupList />} />
            <Route path='create' element={<GroupCreate />} />
            <Route path=':groupNo' element={<GroupDetail />} />
            <Route path=':groupNo/update' element={<GroupUpdate />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default GroupRoute;