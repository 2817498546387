import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { ItemMultipleChoice, ItemShortForm, ItemLikert } from './Items/View';
import { YyyyMmDd } from './Function/ChangeText';

// Create Document Component
const PDFView = (props) => {
  let point = 0;
  if(props.scale.Scale_id.CalScore){
      for(let i = 0; i < props.scale.Answer.length; i++){
        const tumpItem = props.scale.Scale_id.Item_ids.filter((item) => (item._id === props.scale.Answer[i].Item_id))[0];
        for(let j = 0; j < props.scale.Answer[i].Answer.length; j++){
            point += tumpItem.Points[`${props.scale.Answer[i].Answer[j]}`];   
        }
      }
  }

  return (
    <>
    {props.loading ? 
    <Grid style={{ padding: '60px'}}>
      <CircularProgress />
    </Grid>
    :
    <Grid style={{ width: '21cm', minHeight: '29.7cm', paddingRight: '1.7cm' }}>
        <h1 style={{ textAlign: 'center' }}>{props.scale.Scale_id.Label}</h1>
        <p style={{textAlign: 'right', paddingRight: '16px' }}>
            <b>{props.clientName}</b>
            <br/><br/>
            {YyyyMmDd(props.schedule.StartDate)} {props.schedule.StartTime}
        </p>
        <p>{props.scale.Scale_id.Detail}</p>
        {props.scale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
        <Grid key={j} style={{ marginBottom: '8px' }}>
            {q.ItemKind === 1 ?
            <ItemShortForm 
                item={q}
                no={j+1}
                scale={props.scale.Scale_id}
                subScale={
                    q.SubScale && (j === 0 || q.SubScale !== props.scale.Scale_id.Item_ids[j-1].SubScale)
                }
                lastItem={props.scale.Scale_id.Item_ids.length === j+1}
                answer={props.scale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                pdf={true}
            /> :
            q.ItemKind === 2 ?
            <ItemMultipleChoice 
                item={q}
                no={j+1}
                scale={props.scale.Scale_id}
                subScale={
                    q.SubScale && (j === 0 || q.SubScale !== props.scale.Scale_id.Item_ids[j-1].SubScale)
                }
                lastItem={props.scale.Scale_id.Item_ids.length === j+1}
                answer={props.scale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                pdf={true}
            /> :
            q.ItemKind === 3 ?
            <ItemLikert
                item={q}
                no={j+1}
                scale={props.scale.Scale_id}
                subScale={
                    q.SubScale && (j === 0 || q.SubScale !== props.scale.Scale_id.Item_ids[j-1].SubScale)
                }
                lastItem={props.scale.Scale_id.Item_ids.length === j+1}
                answer={props.scale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                pdf={true}    
            /> :
            null}
        </Grid>
        ))}
        <Grid style={{ textAlign: 'right', paddingRight: '16px' }}>
            총점: <u><b>{point}점</b></u>
        </Grid>
      </Grid>}
      </>
)};

export default PDFView;