import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { DashboardLayout } from 'components/Layout';
import { MemberCreateView } from 'components/User/Manage/Member';

const MemberCreateContainer = (props) => {
    const pages = [
        { name: '직원 관리', link: '/manage/member' },
        { name: '직원 생성', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [supervisors, setSupervisors] = useState([]);
    const [member, setMember] = useState({
        Name: '',
        Email: '',
        EmailCheck: false,
        Phone: '',
        Status: '',
        Supervisor_id: '',
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간,
        Region: [], //지역
        License: [], // 자격증
        Degree: '',
        UniversityStatus: '',
        University: '',
        Major: ''
    });
    const [emailLoading, setEmailLoading] = useState(false);
    const [creating, setCreating] = useState(false);

    const handleClickEmailCheck = async() => {
        setEmailLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(member);
        await axios.post(`/api/manager/employee`, body, config)
        .then((res) => {
            setTimeout(() => {
                if(!res.data.ErrorMessage){
                    setMember({ ...member, EmailCheck: true });
                    setEmailLoading(false);
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }else{
                    setMember({ ...member, Email: '' });
                    setEmailLoading(false);
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            }, [1200]);
        })
        .catch((err) => {
            console.log(err);
            setEmailLoading(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickCreateBtn = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(member);
        await axios.post(`/api/manager/employee/${cookies.LoginKey}`, body, config)
        .then((res) => {
            setTimeout(() => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                        navigate(`/manage/member/${res.data.Result.UserNo}`);
                    }, []);
                }else{
                    setCreating(false);
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            }, [1200]);
        })
        .catch((err) => {
            console.log(err);
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/employee/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadSupervisor = res.data.Workers.filter((item) => ((item.Status === 1 || item.Status === 2) && item.State === 1));
                for(let i = 0; i < loadSupervisor.length; i++){
                    loadSupervisor[i].value = loadSupervisor[i]._id;
                    loadSupervisor[i].label = loadSupervisor[i].Name;
                }
                setSupervisors(loadSupervisor);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [setSupervisors, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <MemberCreateView 
                member={member}
                setMember={setMember}
                handleClickEmailCheck={handleClickEmailCheck}
                emailLoading={emailLoading}
                supervisors={supervisors}
                creating={creating}
                handleClickCreateBtn={handleClickCreateBtn}
            />
        </DashboardLayout>
    );
};

export default MemberCreateContainer;