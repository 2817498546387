import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton } from 'components/Items/Button';
import { DataTable } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';

const RoomListView = (props) => {
    const navigate = useNavigate();
    const { selectRoom, setSelectRoom, handleClickDeleteBtn } = props;
    const [search, setSearch] = useState('');
    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'Name', label: '이름' },
        { key: 'StateKo', label: '상태' },
    ];
    
    const [openRoomDeleteModal, setOpenRoomDeleteModal] = useState(false);
    const handleClickDetail = (data) => {
        navigate(`/manage/room/${data.RoomNo}`);
    };
    const handleClickUpdate = (data) => {
        navigate(`/manage/room/${data.RoomNo}/update`);
    };
    const handleClickDelete = (data) => {
        setSelectRoom(data);
        setOpenRoomDeleteModal(true);
    };

    return (
        <>
        <ListContainer title='상담실 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} justifyContent={'flex-end'} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={6} textAlign={'right'}>
                        <SmallSubmitButton 
                            label='상담실 생성'
                            handleClick={() => navigate('/manage/room/create')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys}
                    datas={props.rooms}
                    delete={true}
                    update={true}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickUpdate={handleClickUpdate}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
        </ListContainer>
        {selectRoom.RoomNo ?
        <ConfirmModal
            title='상담실 삭제'
            icon='error'
            open={openRoomDeleteModal}
            handleClose={() => setOpenRoomDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={() => {setOpenRoomDeleteModal(false);handleClickDeleteBtn();}}
        >
            상담실 정보를 삭제 하시겠습니까? 상담실 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default RoomListView;