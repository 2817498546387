import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { TextForm } from 'components/Items/Form';

const TeamUpdateView = (props) => {
    const navigate = useNavigate();
    return (
        <>
        <FormContainer title='팀 수정'>
            <TextForm
                label='이름'
                required={true}
                error={false}
                value={props.team.Name}
                placeholder='이름을 입력해주세요.'
                handleChange={(e) => props.setTeam({ ...props.team, Name: e.target.value })}
            />
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton
                        label='수정'
                        loading={props.updating}
                        disabled={!Boolean(props.team.Name)}
                        handleClick={props.handleClickUpdateBtn}/>
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default TeamUpdateView;