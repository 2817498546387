import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ScaleCreate, ScaleDetail, ScaleList, ScaleUpdate } from 'domains/Admin/Scale';
import { ItemCreate, ItemUpdate } from 'domains/Admin/Scale/Item';

const ScaleRoute = () => {
    return (
        <Routes>
            <Route path='' element={<ScaleList />}/>
            <Route path='create' element={<ScaleCreate />}/>
            <Route path=':scaleNo' element={<ScaleDetail />}/>
            <Route path=':scaleNo/create' element={<ItemCreate />}/>
            <Route path=':scaleNo/update' element={<ScaleUpdate />}/>
            <Route path=':scaleNo/:itemNo/update' element={<ItemUpdate />}/>
        </Routes>
    );
};

export default ScaleRoute;