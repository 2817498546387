import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton } from 'components/Items/Button';
import { DataTable } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';

const TeamListView = (props) => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { selectTeam, setSelectTeam, handleClickDeleteBtn } = props;
    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'Name', label: '이름' },
        { key: 'UserCnt', label: '인원' },
    ];
    const [openTeamDeleteModal, setOpenTeamDeleteModal] = useState(false);
    const handleClickDetail = (data) => {
        navigate(`/manage/team/${data.TeamNo}`);
    };
    const handleClickUpdate = (data) => {
        navigate(`/manage/team/${data.TeamNo}/update`);
    };
    const handleClickDelete = (data) => {
        setSelectTeam(data);
        setOpenTeamDeleteModal(true);
    };

    return (
        <>
        <ListContainer title='팀 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} justifyContent={'flex-end'} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={6} textAlign={'right'}>
                        <SmallSubmitButton 
                            label='팀 생성'
                            handleClick={() => navigate('/manage/team/create')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys}
                    datas={props.teams}
                    delete={true}
                    update={true}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickUpdate={handleClickUpdate}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
        </ListContainer>
        {selectTeam.TeamNo ? 
        <ConfirmModal
            title='팀 삭제'
            icon='error'
            open={openTeamDeleteModal}
            handleClose={() => setOpenTeamDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={() => {setOpenTeamDeleteModal(false);handleClickDeleteBtn();}}
        >
            팀 정보를 삭제 하시겠습니까? 팀 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default TeamListView;