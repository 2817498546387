import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

import { DashboardLayout } from 'components/Layout';
import { MemberUpdateView } from 'components/User/Manage/Member';

const MemberUpdateContainer = (props) => {
    const { memberNo } = useParams();
    const pages = [
        { name: '직원 관리', link: '/manage/member' },
        { name: '직원 상세', link: `/manage/member/${memberNo}` },
        { name: '직원 수정', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [supervisors, setSupervisors] = useState([]);
    const [member, setMember] = useState({
        Name: '',
        Email: '',
        EmailCheck: false,
        Phone: '',
        Status: '',
        Supervisor_id: '',
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간
        Region: [], // 활동 지역
        License: [], // 자격증
        Degree: '',
        UniversityStatus: '',
        University: '',
        Major: ''
    });
    const [updating, setUpdating] = useState(false);

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(member);
        await axios.put(`/api/manager/employee/${memberNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            setTimeout(() => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                        navigate(`/manage/member/${memberNo}`);
                    }, []);
                }else{
                    setUpdating(false);
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            }, [1200]);
        })
        .catch((err) => {
            console.log(err);
            setUpdating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/employee/${memberNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadMember = { ...res.data.Worker };
                loadMember.Available = loadMember.Available.length ? loadMember.Available : [[], [], [], [], [], [], []];
                loadMember.AvailableDay = [];
                for(let i = 0; i < loadMember.Available.length; i++){
                    if(loadMember.Available[i].length){
                        loadMember.AvailableDay.push((i+1).toString());
                        for(let j = 0; j < loadMember.Available[i].length; j++){
                            loadMember.Available[i][j] = loadMember.Available[i][j].toString();
                        }
                    }
                }
                for(let i = 0; i < loadMember.Region.length; i++){
                    loadMember.Region[i] = loadMember.Region[i].toString();
                }
                setMember(loadMember);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
        await axios.get(`/api/employee/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadSupervisor = res.data.Workers.filter((item) => ((item.Status === 1 || item.Status === 2) && item.State === 1));
                for(let i = 0; i < loadSupervisor.length; i++){
                    loadSupervisor[i].value = loadSupervisor[i]._id;
                    loadSupervisor[i].label = loadSupervisor[i].Name;
                }
                setSupervisors(loadSupervisor);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [memberNo, setSupervisors, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <MemberUpdateView
                member={member}
                setMember={setMember}
                supervisors={supervisors}
                updating={updating}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default MemberUpdateContainer;