import React from 'react';
import { Grid, FormControl, OutlinedInput, FormHelperText } from '@mui/material';

/**
 * 
 * @param {string} value
 * @param {function(): string} handleChange
 * @param {string} placeholder
 * @param {boolean} required
 * @param {boolean} error
 * @param {string} label
 * @param {number} rows
 * @description 
 */
const DateForm = (props) => {
    return (
        <>
            <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                {props.label ?
                    <Grid style={{ marginBottom: '8px' }}>
                        {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
                    </Grid> :
                    null
                }
                <OutlinedInput
                    type={'date'}
                    placeholder={props.placeholder}
                    value={props.value || ''}
                    onChange={props.handleChange}
                    rows={props.rows ? props.rows : 1}
                    multiline={props.rows}
                />
                {props.error ? <FormHelperText style={{ marginLeft: 0 }}>{props.errorMessage}</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>}
            </FormControl>
        </>
    );
};

export default DateForm;