import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';

import { ScheduleKindKo, SelectScheduleBorderColor, SelectScheduleBgColor } from 'components/Function/ChangeText';

const DataCalendarWeek = (props) => {
    if(props.date){
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        let days = [];

        const tumpDate = new Date(props.date);
        for(let i = tumpDate.getDay(); i > 0; i--){
            days.push(new Date(tumpDate.getFullYear(), tumpDate.getMonth(), tumpDate.getDate()-i));
        }
        for(let i = 0; i < 7-tumpDate.getDay(); i++){
            days.push(new Date(tumpDate.getFullYear(), tumpDate.getMonth(), tumpDate.getDate()+i));
        }

        return (
            <Grid className='table_container'>
                <table className='week_table'>
                    <thead>
                        <tr>
                            <th>일</th>
                            <th>월</th>
                            <th>화</th>
                            <th>수</th>
                            <th>목</th>
                            <th>금</th>
                            <th>토</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {days.map((day, j) => (
                            <td key={j}
                                style={{ boxSizing: 'border-box' }}
                                className={new Date(props.date).toString() === new Date(day).toString() ? 'today_first_td today_last_td' : 'other_td'}
                                onClick={() => props.handleClick(new Date(day))}
                            >
                                <div 
                                    className={
                                        new Date(today).toString() === new Date(day).toString() ?
                                            'day_today' :
                                            'day_this'       
                                    }
                                >
                                    <p>{new Date(day).getDate()}</p>
                                </div>
                                <div style={{ minHeight: '200px'}}>
                                    <Grid container style={{ color: '#252525' }} spacing={1}>
                                        {props.datas.filter((item) => (new Date(new Date(item.StartDate).setHours(0, 0, 0)).toString() === new Date(day).toString())).map((item, k) => (
                                        <Grid item xs={12} className='week_schedule' key={k}>
                                            <Grid container className={`schedule_border_color${SelectScheduleBorderColor(item.Kind.Detail, item.Date, item.State, item.Visit)}`}>
                                                <Grid item xs={12} className={`schedule_bg_color${SelectScheduleBgColor(item.Kind.Detail)}`} style={{ fontSize: '12px'}}>
                                                    {ScheduleKindKo(item.Kind.Detail)}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <b>[{item.StartTime}]</b> {item.Room_id ? item.Room_id.Name : ''}
                                                </Grid>
                                                <Grid>
                                                    {item.Counselor_id.Name} {item.Client_id ? `🤝 ${item.Client_id.Name_decrypt}` : ''}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        ))}
                                    </Grid>
                                </div>
                                {props.handleClickCreateSchdule ? 
                                <IconButton className='hidden_button' onClick={(e)=>{ e.stopPropagation(); props.handleClickCreateSchdule(day);}}>
                                    <Add style={{ fontSize: '14px'}}/>
                                </IconButton>:null}
                            </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </Grid>
        );
    }else{
        return false;
    }
};

export default DataCalendarWeek;