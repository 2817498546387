import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { RegisterView } from 'components';
import { useCookies } from 'react-cookie';

const RegisterContainer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [, setCookies] = useCookies(['LoginKey', 'Name', 'UserNo', 'Status']);
    const [manager, setManager] = useState({
        Email: '',
        EmailCheck: false,
        CertNumber: '',
        Password: '',
        PasswordCheck: '',
        Phone: '',
        PrivacyAgree: false,
        TermAgree: false
    });
    const [code, setCode] = useState('');
    const [creating, setCreating] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);

    const handleClickEmailCheck = async() => {
        setEmailLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(manager);
        await axios.post(`/api/register/emailAuth`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setEmailLoading(false);
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setCode(res.data.Code);
            }else{
                setEmailLoading(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setEmailLoading(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickRegister = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(manager);
        await axios.post(`/api/register`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                if(res.data.State === 1){
                    setCookies('LoginKey', res.data.LoginKey, { path: '/' });
                    setCookies('Name', res.data.Name, { path: '/' });
                    setCookies('UserNo', res.data.UserNo, { path: '/' });
                    setCookies('Status', res.data.Status, { path: '/' });
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    if(res.data.Status === 1){
                        setTimeout(() => {
                            navigate(`/manage/client`);
                        }, [1200]);
                    }else if(res.data.Status === 2){
                        setTimeout(() => {
                            navigate(`/client`);
                        }, [1200]);
                    }else if(res.data.Status === 3){
                        setTimeout(() => {
                            navigate(`/manage/client`);
                        }, [1200]);
                    }else if(res.data.Status === 5){
                        setTimeout(() => {
                            navigate(`/admin/user`);
                        }, [1200]);
                    }
                }else if(res.data.State === 2){
                    enqueueSnackbar('관리자의 확인이 필요합니다.', {  variant: 'info' });
                }else if(res.data.State === 3){
                    enqueueSnackbar('사용이 정지된 계정입니다.', { variant: 'error' });
                }else{
                    enqueueSnackbar('로그인 에러. 관리자에게 문의해주세요.', { variant: 'error' });
                }
            }else{
                setCreating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    return(
        <RegisterView
            manager={manager}
            setManager={setManager}
            handleClickEmailCheck={handleClickEmailCheck}
            code={code}
            setCode={setCode}
            emailLoading={emailLoading}
            creating={creating}
            handleClickRegister={handleClickRegister}
        />
    );
};

export default RegisterContainer;