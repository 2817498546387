import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';

import { SmallCancelButton, SmallSubmitButton } from '../Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
/**
 * 
 * @param {boolean} open
 * @param {string} title
 * @param {function} handleClose
 * @param {string} submitLabel
 * @param {function} handleClickSubmit
 */
const FormModal = (props) => {
    
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth='sm'
                scroll='body'
            >
                <DialogTitle style={{ textAlign: 'center', padding: '24px 24px 0px 24px' }}>
                    <div style={{ fontSize: '120%', marginBottom: '16px', fontWeight: 'bold' }}>{props.title}</div>
                </DialogTitle>
                <DialogContent style={{ padding: '0px 24px', marginBottom: '16px' }}>
                    {props.children}
                </DialogContent>
                <DialogActions style={{ padding: '0px 24px 24px 24px' }}>
                    <SmallCancelButton
                        handleClick={props.handleClose}
                        label='취소'
                    />
                    <SmallSubmitButton
                        handleClick={props.handleClickSubmit}
                        label={props.submitLabel}
                        disabled={props.disabled}
                        loading={props.loading}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FormModal;