import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';

import { SmallSubmitButton } from '../Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
/**
 * 
 * @param {boolean} open
 * @param {string} title
 * @param {function} handleClose
 * @param {string} submitLabel
 * @param {function} handleClickSubmit
 */
const DetailModal = (props) => {
    
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth='sm'
                scroll='body'
            >
                <DialogTitle style={{ textAlign: 'center', padding: '24px 24px 0px 24px' }}>
                    <div style={{ fontSize: '120%', marginBottom: '16px', fontWeight: 'bold' }}>{props.title}</div>
                </DialogTitle>
                <DialogContent style={{ padding: '0px 24px', marginBottom: '16px', height: props.height === 'full' ? `${window.document.body.offsetHeight*0.76}px` : 'auto' }}>
                    {props.children}
                </DialogContent>
                <DialogActions style={{ padding: '0px 24px 24px 24px' }}>
                    <SmallSubmitButton
                        handleClick={props.handleClose}
                        label='닫기'
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DetailModal;