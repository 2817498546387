import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: count => {
        count.value += 1;
    },
    decrement: count => {
        count.value -= 1;
    },
  },
});

export const { increment, decrement } = messageSlice.actions;

export default messageSlice.reducer;