import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { DashboardLayout } from 'components/Layout';
import { GroupDetailView } from 'components/User/Manage/Group';
import { CalcAge, GenderKo } from 'components/Function/ChangeText';

const GroupDetailContainer = (props) => {
    const { groupNo } = useParams();
    const pages = [
        { name: '그룹 관리', link: '/manage/group' },
        { name: '그룹 상세', link: '' }
    ];

    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [group, setGroup] = useState({
        Name: '',
        Client_ids: []
    });
    const [clients, setClients] = useState([]);

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/group/${groupNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                navigate(-1);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/group/${groupNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setGroup(res.data.GroupData);
                let loadClients = [...res.data.GroupData.Client_ids];
                for(let i = 0; i < loadClients.length; i++){
                    loadClients[i].No = i + 1;
                    loadClients[i].AgeValue = CalcAge(loadClients[i].Birth);
                    loadClients[i].GenderValue = GenderKo(loadClients[i].Gender);
                }
                setClients(loadClients);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [groupNo, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <GroupDetailView 
                group={group}
                clients={clients}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default GroupDetailContainer;