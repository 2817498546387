import * as React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const FullScreenModal = (props) => {
  return (
    <div>
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge='start'
                        onClick={props.handleClose}
                        style={{ color: '#FFF' }}
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div' style={{ color: '#FFF' }}>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid>
                {props.children}
            </Grid>
        </Dialog>
    </div>
  );
};

export default FullScreenModal;