import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { TeamDetailView } from 'components/User/Manage/Team';
import { MemberStatus } from 'components/Function/ChangeText';

const TeamDetailContainer = (props) => {
    const { teamNo } = useParams();
    const pages = [
        { name: '팀 관리', link: '/manage/team' },
        { name: '팀 상세', link: '' }
    ];
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [team, setTeam] = useState({});
    const [members, setMembers] = useState([]);
    const [addMember, setAddMember] = useState('');
    const [removeMember, setRemoveMember] = useState('');

    const handleClickRemoveTeam = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/team/${teamNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                navigate(`/manage/team`);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickAddMember = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.patch(`/api/manager/team/${teamNo}/${addMember}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let changeTeam = { ...team };
                let addData = members.filter((item) => (item._id === addMember))[0];
                addData['No'] = team.User_ids.length + 1;
                changeTeam.User_ids.push(addData);
                setTeam(changeTeam);
                let changeMembers = [...members];
                changeMembers = changeMembers.filter((item) => (item._id !== addMember));
                setMembers(changeMembers);
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickRemoveMember = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/team/${teamNo}/${removeMember}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let changeTeam = { ...team };
                changeTeam.User_ids = changeTeam.User_ids.filter((item) => (item._id !== removeMember));
                setTeam(changeTeam);
                let changeMembers = [...members];
                let tump = team.User_ids.filter((item) => (item._id === removeMember))[0];
                tump.label = tump.Name;
                tump.value = tump._id;
                changeMembers.push(tump);
                setMembers(changeMembers);
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/team/${teamNo}/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                let loadTeam = res.data.TeamData;
                loadTeam.UserCnt = `${loadTeam.User_ids.length} 명`;
                for(let i = 0; i < loadTeam.User_ids.length; i++){
                    loadTeam.User_ids[i].No = i+1;
                    loadTeam.User_ids[i].StatusKo = MemberStatus(loadTeam.User_ids[i].Status);
                }
                setTeam(loadTeam);
                await axios.get(`/api/employee/${cookies.LoginKey}`, config)
                .then((res) => {
                    if(!res.data.ErrorMessage){
                        let loadMembers = res.data.Workers;
                        loadMembers = loadMembers.filter((item) => {
                            let tump = loadTeam.User_ids.filter((item2) => (item.UserNo === item2.UserNo));
                            if(tump.length === 0){
                                return true;
                            }else{
                                return false;
                            }
                        });
                        for(let i = 0; i < loadMembers.length; i++){
                            loadMembers[i].No = i + 1;
                            loadMembers[i].StatusKo = MemberStatus(loadMembers[i].Status);
                            loadMembers[i].value = loadMembers[i]._id;
                            loadMembers[i].label = loadMembers[i].Name;
                        }
                        setMembers(loadMembers);
                    }else{
                        enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar('Network Error!', { variant: 'error' });
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setTeam, enqueueSnackbar, cookies.LoginKey, teamNo]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <TeamDetailView 
                team={team}
                members={members}
                addMember={addMember}
                setAddMember={setAddMember}
                handleClickAddMember={handleClickAddMember}
                setRemoveMember={setRemoveMember}
                handleClickRemoveMember={handleClickRemoveMember}
                handleClickRemoveTeam={handleClickRemoveTeam}
            />
        </DashboardLayout>
    );
};

export default TeamDetailContainer;