import React from 'react';
import { Button } from '@mui/material';

/**
 * 
 * @param {string} label 
 * @param {boolean} disabled
 * @param {function} handleClick
 * @returns 
 */
const SmallCancelButton = (props) => {
    return(
        <Button
            variant='outlined'
            style={{ borderRadius: '5px', height: '40px' }}
            onClick={props.handleClick}
            disabled={props.disabled}
        >
            {props.label}
        </Button>
    );
};

export default SmallCancelButton;