import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Tab, Tabs, Box, IconButton, Link, Collapse, Divider } from '@mui/material';
import { ArrowLeft, ArrowRight, Circle, KeyboardArrowUp } from '@mui/icons-material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataCalendarMonth, DataCalendarWeek, DataCalendarDay } from 'components/Items/Table';
import { SearchForm } from 'components/Items/Form';
import { RoomState, getWeek, YyyyMmDd, DayKo, ServiceKo, ScheduleKindKo } from 'components/Function/ChangeText';
import { ConfirmModal, DetailModal } from 'components/Items/Modal';
import { SmallSubmitButton } from 'components/Items/Button';
import { SelectScheduleBgColor, SelectScheduleBorderColor } from 'components/Function/ChangeText';

const RoomDetailView = (props) => {
    const navigate = useNavigate();
    const { roomNo } = useParams();
    const [openRoomDeleteModal, setOpenRoomDeleteModal] = useState(false);
    const [search, setSearch] = useState('');
    const [search2, setSearch2] = useState('');
    const [selectSchedules, setSelectSchedules] = useState([]);
    const [openScheduleDetailModal, setOpenScheduleDetailModal] = useState(false);
    const [targetDate, setTargetDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [tab, setTab] = useState(0);

    const nextSchdule = () => {
        if(tab === 0){
            setTargetDate(new Date(targetDate.setMonth(targetDate.getMonth()+1)));
        }else if(tab === 1){
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()+7)));
        }else{
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()+1)));
        }
    };

    const beforeSchedule = () => {
        if(tab === 0){
            setTargetDate(new Date(targetDate.setMonth(targetDate.getMonth()-1)));
        }else if(tab === 1){
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()-7)));
        }else{
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()-1)));
        }
    };

    const handleCloseScheduleDetailModal = () => {
        setOpenScheduleDetailModal(false);
        setSelectSchedules([]);
    };
    const handleClickOpenSchedule = (key) => {
        let openSelectSchdules = [...selectSchedules];
        openSelectSchdules[key].Open = openSelectSchdules[key].Open ? false : true;
        setSelectSchedules(openSelectSchdules);
    };

    const handleClickDay = (date) => {
        setTargetDate(date);
        handleClickSchedule(date);
    };
    
    const handleClickSchedule = (date) => {
        let nextDay = new Date(new Date(date).setDate(date.getDate()+1));
        const tumpDatas = filterDatas(props.schedules).filter((item) => (new Date(item.StartDate) > date && new Date(item.StartDate) < nextDay));
        if(tumpDatas.length > 0){
            setSelectSchedules(tumpDatas);
            setOpenScheduleDetailModal(true);
        }else{
            return false;
        }
    };

    const filterDatas = (datas) => {
        let tumpDatas = datas;
        if(search){
            tumpDatas = tumpDatas.filter((item) => (item.KindDetailKo?.indexOf(search) > -1 || item.Client_id?.Name_decrypt?.indexOf(search) > -1 || item.Counselor_id?.Name?.indexOf(search) > -1 || item.Room_id?.Name?.indexOf(search) > -1));
        }
        return tumpDatas;
    };

    return (
        <>
        <DetailContainer
            title='상담실 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenRoomDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/manage/room/${roomNo}/update`) } ]}
        >
            <DataBox
                datas={[{ key: '이름', value: props.room.Name }, { key: '사용 가능 여부', value: RoomState(props.room.State) }]}
            />
        </DetailContainer>
        <DetailContainer title='스케줄 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={6} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} md={4.5}>
                        <Box sx={{ marginBottom: '16px' }}>
                            <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)} variant='fullWidth'>
                                <Tab label='월' tabIndex={0}/>
                                <Tab label='주' tabIndex={1}/>
                                <Tab label='일' tabIndex={2}/>
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} md={3} justifyContent={'center'} alignItems={'center'}>
                        <Grid item>
                            <IconButton onClick={beforeSchedule}>
                                <ArrowLeft />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <h3 style={{ margin: 0 }}>{targetDate.getFullYear()}. {targetDate.getMonth()+1}. {tab === 1 ? `${getWeek(targetDate)}W` : null}{tab === 2 ? `${targetDate.getDate()}` : null}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={nextSchdule}>
                                <ArrowRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {tab === 0 ?
            <DataCalendarMonth
                date={targetDate}
                datas={filterDatas(props.schedules)}
                handleClick={handleClickDay}
                handleClickCreateSchdule={false}
            />:
            tab === 1 ?
            <DataCalendarWeek
                date={targetDate}
                datas={filterDatas(props.schedules)}
                handleClick={handleClickDay}
                handleClickCreateSchdule={false}
            />:
            <DataCalendarDay
                date={targetDate}
                datas={filterDatas(props.schedules)}
                handleClickCreateSchdule={false}
            />}
        </DetailContainer>
        <DetailModal
            title={`${YyyyMmDd(targetDate)} (${DayKo(targetDate)})`}
            open={openScheduleDetailModal}
            handleClose={handleCloseScheduleDetailModal}
            height='full'
        >
            <Grid style={{ marginBottom: '16px', marginTop: '8px' }}>
                <SearchForm
                    value={search2}
                    handleChange={(e) => setSearch2(e.target.value)}
                />
            </Grid>
            {selectSchedules.map((item, i) => (
            <Grid key={i}>
                <Grid container style={{ marginBottom: '16px', marginTop: i === 0 ? 0 : '16px' }} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={10} container spacing={1}>
                        <Grid item>
                            <Circle className={`schedule_circle_color${SelectScheduleBorderColor(item.Kind.Detail, item.Date, item.State, item.Visit)}`}/>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={`schedule_bg_modal schedule_bg_color${SelectScheduleBgColor(item.Kind.Detail)}`}>
                                {ScheduleKindKo(item.Kind.Detail)}
                            </div>
                        </Grid>
                        <Grid item>
                            <Link onClick={() => navigate(`/schedule/${item.ScheduleNo}`)}>&#91;{item.StartTime}&#93; {item.Counselor_id.Name} {item.Client_id ? `🤝 ${item.Client_id.Name_decrypt}` : null}</Link>
                        </Grid>
                        <Grid item>
                            <span style={{ color: '#5E5E5E'}}>{item.Room_id ? item.Room_id.Name : ''}</span>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton onClick={() =>handleClickOpenSchedule(i)}>
                            <KeyboardArrowUp style={{ transform: `rotate(${item.Open ? 0 : 180}deg)`, transition: 'all ease 0.25s' }} />
                        </IconButton>
                    </Grid>
                    <Collapse in={item.Open} style={{ width: '100%' }}>
                        {item.Kind.State === 1 ?
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <p>상담자</p>
                                <div className='div_data_value'>{item.Counselor_id.Name}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>내담자</p>
                                <div className='div_data_value'>{item.Client_id.Name_decrypt ? `${item.Client_id.Name_decrypt} (${item.Client_id.NickName})` : item.Client_id.NickName}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <p>상담 일</p>
                                <div className='div_data_value'>{YyyyMmDd(item.StartDate)}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 시작 시간</p>
                                <div className='div_data_value'>{item.StartTime}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 종료 시간</p>
                                <div className='div_data_value'>{item.EndTime}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 종류</p>
                                <div className='div_data_value'>{ScheduleKindKo(item.Kind.Detail)}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 방식</p>
                                <div className='div_data_value'>{ServiceKo(item.Service)}</div>
                            </Grid>
                        </Grid>:
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <p>이름</p>
                                <div className='div_data_value'>{item.Counselor_id.Name}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>날짜</p>
                                <div className='div_data_value'>{YyyyMmDd(item.StartDate)}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>시작 시간</p>
                                <div className='div_data_value'>{item.StartTime}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>스케줄 종류</p>
                                <div className='div_data_value'>{ScheduleKindKo(item.Kind.Detail)}</div>
                            </Grid>
                        </Grid>}
                        <Grid container justifyContent={'flex-end'} spacing={1} style={{ marginTop: '16px'}}>
                            <Grid item>
                                <SmallSubmitButton
                                    label='상세'
                                    handleClick={() => navigate(`/schedule/${item.ScheduleNo}`)}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
                <Divider />
            </Grid>
            ))}   
        </DetailModal>
        <ConfirmModal
            title='상담실 삭제'
            icon='error'
            open={openRoomDeleteModal}
            handleClose={() => setOpenRoomDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={props.handleClickDeleteBtn}
        >
            상담실 정보를 삭제 하시겠습니까? 상담실 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        </>
    );
};

export default RoomDetailView;