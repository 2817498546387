import React, { useEffect, useState } from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ListContainer } from 'components/Items/Container';

const ClientChartView = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
      let loadScales = [];
      let loadDatas = [];
      let checkCnt = [];
      let resultCnt = 0;
      let plus = 0;
      let minus = 0;
      let same = 0;
      let cancel = 0;
      const tumpScales = props.scales.filter((item) => (item.CalScore));
      for(let i = 0; i < tumpScales.length; i++){
        loadDatas.push({ 'id': tumpScales[i].Label, '_id': tumpScales[i]._id, 'data': [] });
      }
      let rawDatas = [];
      for(let i = 0; i < props.clients.length; i++){
        const tumpClient = props.clients[i];
        tumpClient.Schedule_ids = tumpClient.Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
        for(let j = 0; j < tumpClient.Schedule_ids.length; j++){
          const tumpSchedule = tumpClient.Schedule_ids[j];
          if(tumpSchedule.State === 2){
            cancel += 1;
          }
          for(let k = 0; k < tumpSchedule.ScaleAnswer.length; k++){
            const tumpScaleAnswer = tumpSchedule.ScaleAnswer[k];
            if(tumpScaleAnswer.Answer.length){
              if(tumpScales.filter((item) => (item._id === tumpScaleAnswer.Scale_id))[0]){
                const tumpScale = tumpClient.Scales.filter((item) => (item.Scale_id?._id === tumpScaleAnswer.Scale_id))[0];
                if(tumpScale){
                  rawDatas.push({ StartDate: tumpSchedule.StartDate, Negative: tumpScale.Scale_id.Negative ,Scale_id: tumpScale.Scale_id._id, Item_ids: tumpScale.Scale_id.Item_ids, Answer: tumpScaleAnswer.Answer, ClientNo: tumpClient.ClientNo, ClientName: tumpClient.Name_decrypt ? tumpClient.Name_decrypt : tumpClient.NickName });
                }
              }
            }
          }
        }
      }
      for(let i = 0; i < rawDatas.length; i++){
        for(let j = 0; j < loadDatas.length; j++){
          if(rawDatas[i].Scale_id === loadDatas[j]._id){
            let tumpPoint = 0;
            let maxPoint = 0;
            for(let k = 0; k < rawDatas[i].Answer.length; k++){
              const tumpItem = rawDatas[i].Item_ids.filter((item) => (item._id === rawDatas[i].Answer[k].Item_id))[0];
              if( tumpItem !== undefined ) {
                for(let k2 = 0; k2 < rawDatas[i].Answer[k].Answer.length; k2++){
                  // if(rawDatas[i].Negative){
                  //   tumpPoint -= Number(tumpItem.Points[`${rawDatas[i].Answer[k].Answer[k2]}`]);
                  //   maxPoint -= Math.max.apply(null, tumpItem.Points) ? Math.max.apply(null, tumpItem.Points) : 0;
                  // }else{
                    tumpPoint += Number(tumpItem.Points[`${rawDatas[i].Answer[k].Answer[k2]}`]);
                    maxPoint += Math.max.apply(null, tumpItem.Points) ? Math.max.apply(null, tumpItem.Points) : 0;
                  // }
                }
              }
            }
            if(loadDatas[j].data.length === 0){
              loadDatas[j].data.push({ 'rawx': tumpPoint, 'rawy': tumpPoint, 'ClientNo': rawDatas[i].ClientNo, 'ClientName': rawDatas[i].ClientName, 'MaxPoint': maxPoint });
            }else{
              let insertData = false;
              for(let k = 0; k < loadDatas[j].data.length; k++){
                if(loadDatas[j].data[k].ClientNo === rawDatas[i].ClientNo){
                  loadDatas[j].data[k].rawy = tumpPoint;
                  insertData = true;
                }
              }
              if(!insertData){
                loadDatas[j].data.push({ 'rawx': tumpPoint, 'rawy': tumpPoint, 'ClientNo': rawDatas[i].ClientNo, 'ClientName': rawDatas[i].ClientName, 'MaxPoint': maxPoint });
              }
            }
          }
        }
      }
      let sumX = 0;
      let sumY = 0;
      for(let i = 0; i < loadDatas.length; i++){
        if(loadDatas[i].data.length){
          resultCnt += loadDatas[i].data.length;
          loadScales.push(loadDatas[i].id);
          checkCnt.push(false);
          for(let j = 0; j < loadDatas[i].data.length; j++){
            sumX += loadDatas[i].data[j].rawx;
            sumY += loadDatas[i].data[j].rawy;
          }
        }else{
          loadDatas[i] = null;
        }
      }
      loadDatas = loadDatas.filter((item) => (item !== null));
      const meanX = resultCnt > 0 ? sumX/resultCnt : 0;
      const meanY = resultCnt > 0 ? sumY/resultCnt : 0;
      let dSumX = 0;
      let dSumY = 0;
      for(let i = 0; i < loadDatas.length; i++){
        for(let j = 0; j < loadDatas[i].data.length; j++){
          dSumX += (meanX - loadDatas[i].data[j].rawx)**2;
          dSumY += (meanY - loadDatas[i].data[j].rawy)**2;
        }
      }
      const varX = resultCnt > 2 ? dSumX/(resultCnt - 1) : 0;
      const varY = resultCnt > 2 ? dSumY/(resultCnt - 1) : 0;
      const psd = Math.sqrt((varX + varY)/2);
      const calcCohen = psd > 0 ? ((meanY - meanX)/psd).toFixed(2) : 0;
      const calcHedges = resultCnt > 0 ? ((meanY - meanX)/Math.sqrt(((resultCnt-1)*varX+(resultCnt-1)*varY)/(resultCnt + resultCnt - 1))).toFixed(2) : 0;
      let ratio = 0; // 증감율
      setDatas(loadDatas);
      setScales(loadScales);
      setCheckedScales(checkCnt);
      for(let i = 0; i < loadDatas.length; i++){
        for(let j = 0; j < loadDatas[i].data.length; j++){
          // if(loadDatas[i].data[j].rawx < loadDatas[i].data[j].rawy){
          //   plus += 1; // 개선됨
          // }else if(loadDatas[i].data[j].rawx > loadDatas[i].data[j].rawy){
          //   minus += 1; // 변화 없음
          // }else{
          //   same += 1; // 작은 변화
          // }
          ratio = ( loadDatas[i].data[j].rawy / loadDatas[i].data[j].rawx ) * 100;
          if(ratio >= 125){
            plus += 1; // 개선됨
          }else if(ratio > 104){
            minus += 1; // 변화 없음
          }else{
            same += 1; // 작은 변화
          }
        }
      }
      setCnt({ Plus: plus, Minus: minus, Same: same, Cancel: cancel, Cohen: calcCohen, Hedges: calcHedges });
    }, [props]);

    const [scales, setScales] = useState([]);
    const [datas, setDatas] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedScales, setCheckedScales] = useState([]);
    const [bgc, setBgc] = useState(false);
    const [reverse, setReverse] = useState(false);
    const [cnt, setCnt] = useState({ Plus: 0, Minus: 0, Same: 0, Cancel: 0, Cohen: 0, Hedges: 0 });
    
    const handleChange = (e) => {
        if(Number(e.target.value) !== 0){
            let changeCheckedScales = [...checkedScales];
            changeCheckedScales[Number(e.target.value) - 1] = !checkedScales[Number(e.target.value) - 1];
            setCheckedScales(changeCheckedScales);
        }else{
          let changeChecked = [...checkedScales];
          for(let i = 0; i < changeChecked.length; i++){
            changeChecked[i] = !allChecked;
          }
          setCheckedScales(changeChecked);
          setAllChecked(!allChecked);
        }
    };
    const filterDatas = (datas) => {
        return datas.filter((item) => {
            let returnData = false;
            for(let i = 0; i < checkedScales.length; i++){
                if(checkedScales[i] && item.id === scales[i]){
                    returnData = true;
                }
            }
            return returnData;
        });
    };

    const handleClickReverse = () => {
      let changeDatas = [...datas];
      for(let i = 0; i < changeDatas.length; i++){
        for(let j = 0; j < changeDatas[i].data.length; j++){
          changeDatas[i].data[j].y = changeDatas[i].data[j].y * (-1);
          // changeDatas[i].data[j].z = changeDatas[i].data[j].x;
          // changeDatas[i].data[j].x = changeDatas[i].data[j].y;
          // changeDatas[i].data[j].y = changeDatas[i].data[j].z;
          // delete changeDatas[i].data[j].z;
        }
      }
      setReverse(!reverse);
      setDatas(changeDatas);
    };

    const calcPoint = (data, key) => {
      if(data.length){
        let first = 0;
        let last = 0;
        for(let i = 0; i < data.length; i++){
          first += data[i].rawx;
          last += data[i].rawy;
        }
        // if(reverse){
        //   last = last;
        // }
        if(key === 1){
          return (first/data.length).toFixed(1).replace('.0', '');
        }else if(key === 2){
          return (last/data.length).toFixed(1).replace('.0', '');
        }else if(key === 3){
          return first !== 0 ? ((last - first)/Math.abs(first)) > 0 ? '+'+(((last - first)/Math.abs(first))*100).toFixed(1).replace('.0', '')+'%' : (((last - first)/first)*100).toFixed(1).replace('.0', '')+'%' : '0%';
        }else if(key === 4){
          return last - first > 0 ? '+'+(last - first).toFixed(1).replace('.0', '') : (last - first).toFixed(1).replace('.0', '');
        }else {
          return '';
        }
      }else{
        return '';
      }
    };

    useEffect(() => {
      let changeDatas = [...datas];
      for(let i = 0; i < checkedScales.length; i++){
        if(checkedScales[i]){
          if(checkedScales.filter((item) => (item === true)).length === 1){
            for(let j = 0; j < changeDatas[i].data.length; j++){
              changeDatas[i].data[j].x = changeDatas[i].data[j].rawx;
              changeDatas[i].data[j].y = changeDatas[i].data[j].rawy;
            }
          }else{
            for(let j = 0; j < changeDatas[i].data.length; j++){
              changeDatas[i].data[j].x = changeDatas[i].data[j].MaxPoint ? changeDatas[i].data[j].rawx/changeDatas[i].data[j].MaxPoint*100 : 0;
              changeDatas[i].data[j].y = changeDatas[i].data[j].MaxPoint ? changeDatas[i].data[j].rawy/changeDatas[i].data[j].MaxPoint*100 : 0;
            }
          }
        }
      }
      setDatas(changeDatas);
    }, [checkedScales]);
     
    return (
        <>
        <ListContainer
            title='전체 차트'
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={3} style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px'}}>
                    <h3 style={{ textAlign: 'center', marginTop: '0px' }}>차트 관리</h3>
                    <p style={{ textAlign: 'center', marginTop: '0px' }}>검사 선택</p>
                    <FormControl fullWidth>
                        <FormGroup onChange={handleChange}>
                            <FormControlLabel control={<Checkbox value={'0'} checked={allChecked} />} label={'ALL'} />
                            {scales.map((scales, i) => (
                            <FormControlLabel key={i} control={<Checkbox value={i+1} checked={checkedScales[i]} />} label={scales} />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <p style={{ textAlign: 'center', marginTop: '0px' }}>옵션</p>
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label={'배경색'} onChange={() => setBgc(!bgc)}/>
                            <FormControlLabel control={<Checkbox value={'2'}/>} label={'그래프 변환'} onChange={handleClickReverse}/>
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Grid height={'500px'} marginBottom={'16px'} style={{ position: 'relative' }}>
                      <div style={{ background: 'linear-gradient(140deg, blue 5%, yellow, red)', opacity: 0.5, height: '500px', width: '100%', position: 'absolute', display: bgc ? 'block' : 'none' }} />
                        <ResponsiveScatterPlot
                            data={filterDatas(datas)}
                            margin={{ top: 10, right: 110, bottom: 60, left: 60 }}
                            xScale={{ type: 'linear', max: 'auto', min: 'auto' }}
                            xFormat='>-.2f'
                            yScale={{ type: 'linear', max: 'auto', min: 'auto' }}
                            yFormat='>-.2f'
                            blendMode='multiply'
                            colors={{ scheme: 'paired' }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '마지막 점수',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '첫 점수',
                                legendPosition: 'middle',
                                legendOffset: 46
                            }}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemWidth: 100,
                                    itemHeight: 12,
                                    itemsSpacing: 5,
                                    itemDirection: 'left-to-right',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            tooltip={(e) => (
                                <Grid container sx={{ boxShadow: 1, p: 1, bgcolor: '#FFF', borderRadius: '4px', fontSize: '14px' }} alignItems={'center'}>
                                    <Grid item sx={{ pr: 0.5 }}><div style={{ width: '15px', height: '15px', backgroundColor: e.node.color, borderRadius: '7.5px' }}/></Grid>
                                    <Grid item sx={{ pr: 1 }}>{e.node.serieId}</Grid>
                                    <Grid item sx={{ pr: 1 }}>
                                        <span style={{ color: '#C0C0C0'}}>First: </span>{reverse ? e.node.data.rawy : e.node.data.rawx}<br/>
                                        <span style={{ color: '#C0C0C0'}}>Last: </span> {reverse? e.node.data.rawx : e.node.data.rawy}
                                    </Grid>
                                    <Grid item>{e.node.data.ClientName}</Grid>
                                </Grid>
                            )}
                            onClick={(node, _) => {navigate(`/client/${datas.filter((item) => (item.id === node.serieId))[0].data[node.serieIndex].ClientNo}`);}}
                            // theme={{ 'background': bgc ? 'rgba(255, 255, 0, 0.1)' : 'rgba(0, 0, 0, 0)' }}
                        />
                    </Grid>
                    <Grid container alignItems={'stretch'} style={{ padding: '16px 8px 0px 8px'}}>
                        <Grid item xs={12} sm={12} md={3}>
                            <Grid style={{ padding: '0px 8px', height: '100%' }} >
                                <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                    <h3 style={{ textAlign: 'center', marginTop: '0px' }}>데이터 요약</h3>
                                    <Grid container>
                                        <Grid item xs={8}>내담자</Grid>
                                        <Grid item xs={4}>{props.clients.length}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>상담중</Grid>
                                        <Grid item xs={4}>{props.clients.filter((item) =>(item.State < 8)).length}</Grid>
                                    </Grid>
                                    <hr/>
                                    <Grid container>
                                        <Grid item xs={8}>10일 경과</Grid>
                                        <Grid item xs={4}>{props.clients.filter((item) => (item.LastVist && new Date(new Date(item.LastVist).setDate(new Date(item.LastVist).getDate()+10)) < new Date())).length}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>30일 경과</Grid>
                                        <Grid item xs={4}>{props.clients.filter((item) => (item.LastVist && new Date(new Date(item.LastVist).setDate(new Date(item.LastVist).getDate()+30)) < new Date())).length}</Grid>
                                    </Grid>
                                    <hr/>
                                    <Grid container>
                                        <Grid item xs={8}>개선됨</Grid>
                                        <Grid item xs={4}>{cnt.Plus}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>작은변화</Grid>
                                        <Grid item xs={4}>{cnt.Same}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>변화없음</Grid>
                                        <Grid item xs={4}>{cnt.Minus}</Grid>
                                    </Grid>
                                    <hr/>
                                    <Grid container>
                                        <Grid item xs={8}>취소된 일정</Grid>
                                        <Grid item xs={4}>{cnt.Cancel}</Grid>
                                    </Grid>
                                    <p style={{ textAlign: 'center'}}>Effect Size</p>
                                    <Grid container>
                                        <Grid item xs={8}>Cohen's d</Grid>
                                        {/* <Grid item xs={4}>{cnt.Cohen}</Grid> */}
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>Hedges' g*</Grid>
                                        {/* <Grid item xs={4}>{cnt.Hedges}</Grid> */}
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                            <Grid style={{ padding: '0px 8px', height: '100%' }} >
                                <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                    <h3 style={{ textAlign: 'center', marginTop: '0px' }}>검사 평균</h3>
                                    <table className='mini_table'>
                                        <thead>
                                            <tr>
                                                <th>검사 이름</th>
                                                <th>첫번째 검사</th>
                                                <th>마지막 검사</th>
                                                <th>증감</th>
                                                <th>증감률</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datas.map((data, i) => (
                                            <tr key={i}>
                                              <td>{data.id}</td>
                                              <td>{calcPoint(data.data, 1)}</td>
                                              <td>{calcPoint(data.data, 2)}</td>
                                              <td>{calcPoint(data.data, 4)}</td>
                                              <td>{calcPoint(data.data, 3)}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListContainer>
        </>
    );
};

export default ClientChartView;