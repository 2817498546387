import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ScheduleListView } from 'components/User/Schedule';
import { ScheduleKindKo } from 'components/Function/ChangeText';

const ScheduleListContainer = (props) => {
    const pages = [
        { name: '스케줄', link: '' },
        { name: '스케줄 목록', link: '' }
    ];

    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();

    const [schedules, setSchedules] = useState([]);
    const [user, setUser] = useState({});
    const [schedule, setSchedule] = useState({
        Client_id: '',
        Counselor_id: '',
        StartDate: '2023-9-9',
        StartTime: '',
        EndTime: '',
        Service: '',
        State: '',
        Room_id: '',
        Kind: { State: 1, Detail: '' },
        Repeat: { State: false, Count: '', Days: '' },
        IsFee: false,
        Fee: '',
        Memo: ''
    });
    const [clients, setClients] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [teamMembers, setTeamMembers] = useState(['']);

    const handleClickCreateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(schedule);
        if(schedule.Kind.State === 1){
            await axios.post(`/api/manager/session/${cookies.LoginKey}`, body, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        loadDatas();
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                    }, [250]);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }else{
            await axios.post(`/api/schedule/${cookies.LoginKey}`, body, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        loadDatas();
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                    }, [250]);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }
    };

    const handleClickDeleteBtn = async(data) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        if(data.Kind.State === 1){
            await axios.delete(`/api/manager/session/${data.ScheduleNo}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        loadDatas();
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                    }, [250]);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }else{
            await axios.delete(`/api/schedule/${data.ScheduleNo}/${cookies.LoginKey}`, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    setTimeout(() => {
                        loadDatas();
                        enqueueSnackbar(res.data.Message, { variant: 'success' });
                    }, [250]);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }
    };
    
    // ====================================================================================================
    // 내담자를 선택하면 상담자를 불러오기
    const handleClientId = async(client) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/schedule/client/${client}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setUser(res.data.UserData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };
    // ====================================================================================================

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/schedule/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadSchedules = [...res.data.ScheduleDatas];
                for(let i = 0; i < loadSchedules.length; i++){
                    loadSchedules[i].KindDetailKo = ScheduleKindKo(loadSchedules[i].Kind.Detail);
                }
                loadSchedules = loadSchedules.sort((a, b) => {
                    if(a.StartTime > b.StartTime) return 1;
                    if(a.StartTime < b.StartTime) return -1;
                    else return 0;
                });
                setSchedules(loadSchedules);
                setUser(res.data.UserData);
                setSchedule({
                    Client_id: '',
                    Counselor_id: res.data.UserData._id,
                    StartDate: '',
                    StartTime: '',
                    EndTime: '',
                    Service: '',
                    State: '',
                    Room_id: '',
                    Kind: { State: res.data.UserData.Status === 3 ? 2 : 1, Detail: '' },
                    Repeat: { State: false, Count: '', Days: '' },
                    IsFee: false,
                    Fee: '',
                    Memo: ''
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
        await axios.get(`/api/client/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let tump = [];
                for(let i = 0; i < res.data.ClientDatas.length; i++){
                    if(res.data.ClientDatas.State !== 8){
                        tump.push({ value: res.data.ClientDatas[i]._id, label: res.data.ClientDatas[i].Name_decrypt ? `${res.data.ClientDatas[i].Name_decrypt} (${res.data.ClientDatas[i].NickName})` : `(${res.data.ClientDatas[i].NickName})` });
                    }
                }
                tump.sort((a, b) => (String(a.label).localeCompare(String(b.label))));
                setClients(tump);
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/manager/room/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadRooms = res.data.RoomDatas;
                loadRooms = loadRooms.filter((item) => (item.State === 1));
                for(let i = 0; i < loadRooms.length; i++){
                    loadRooms[i].value = loadRooms[i]._id;
                    loadRooms[i].label = loadRooms[i].Name;
                }
                setRooms(loadRooms);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/team/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadTeams = res.data.TeamDatas;
                let tumpData = [];
                for(let i = 0; i < loadTeams.length; i++){
                    tumpData = tumpData.concat(loadTeams[i].User_ids);
                }
                setTeamMembers([...new Set(tumpData)]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [setSchedules, enqueueSnackbar, cookies.LoginKey, setClients, setUser, setSchedule, setRooms, setTeamMembers]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScheduleListView 
                schedules={schedules}
                user={user}
                schedule={schedule}
                setSchedule={setSchedule}
                clients={clients}
                rooms={rooms}
                setRooms={setRooms}
                handleClickCreateBtn={handleClickCreateBtn}
                handleClickDeleteBtn={handleClickDeleteBtn}
                handleClientId={handleClientId}
                teamMembers={teamMembers}
            />
        </DashboardLayout>
    );
};

export default ScheduleListContainer;