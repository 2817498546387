import React from 'react';
import { Grid, Divider, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const ItemShortForm = (props) => {
    let point = 0;
    if(props.pdf && props.scale.CalScore){
        for(let i = 0; i < props.answer.length; i++){
            point += props.item.Points[`${props.answer[i]}`];
        }
    }
    return (
        <Grid container justifyContent={'center'}>
            {props.subScale ?
            <Grid item xs={12} style={{ paddingTop: props.no === 1 ? '0px' : '16px' }}>
                <span style={{ color: 'rgb(139, 139, 139)'}}>{props.subScales[props.no]}</span>
                {/* {JSON.stringify(props.subScales[props.no-1], null, 2)}<br/>{props.no} */}
            </Grid>:
            null
            }
            <Grid item xs={props.update ? 10 : 12} container>
                <Grid item xs={1} sm={0.5}>
                    Q{props.no}.
                </Grid>
                <Grid item xs={11} sm={11.5}>
                    {props.item?.Content}
                    {props.pdf && props.scale.CalScore ? <span style={{ color: '#5E5E5E' }}>&nbsp;&nbsp;{point}점</span> : ''}
                </Grid>    
            </Grid>
            {props.update ?
            <Grid item xs={2} style={{ textAlign: 'right'}}>
                <IconButton style={{ marginRight: '8px'}} onClick={props.delete}>
                    <Delete />
                </IconButton>
                <IconButton onClick={props.update}>
                    <Edit />
                </IconButton>
            </Grid>:null}
            <Grid item xs={12} container style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Grid item xs={1} sm={0.5}>
                    {/* A{props.no}.&nbsp; */}
                </Grid>
                <Grid item xs={11} sm={11.5}>
                    {props.answer?.Answer}
                    {/* {JSON.stringify(props.answer)} */}
                    {/* {props.answer ? props.answer.Answer[0] : null} */}
                </Grid>
            </Grid>
            {!props.lastItem ?
            <Grid item xs={11.5} style={{ paddingBottom: '4px' }}>
                <Divider />
            </Grid>:null}
        </Grid>
    );
};

export default ItemShortForm;