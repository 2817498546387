import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { MemberListView } from 'components/User/Manage/Member';
import { MemberStatus } from 'components/Function/ChangeText';

const MemberListContainer = (props) => {
    const pages = [
        { name: '직원 관리', link: '' },
        { name: '직원 목록', link: '' }
    ];
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [members, setMembers] = useState([]);
    const [selectMember, setSelectMember] = useState({});

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/employee/${selectMember.UserNo}/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadData();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };
    
    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/employee/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadMembers = res.data.Workers.filter((item) => (item.Manager_id && item.State === 1));
                for(let i = 0; i < loadMembers.length; i++){
                    loadMembers[i].No = i + 1;
                    loadMembers[i].StatusKo = MemberStatus(loadMembers[i].Status);
                }
                setMembers(loadMembers);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [setMembers, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <MemberListView
                members={members}
                selectMember={selectMember}
                setSelectMember={setSelectMember}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default MemberListContainer;