import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ItemCreateView } from 'components/Admin/Scale/Item';

const ItemCreateContainer = (props) => {
    const { scaleNo } = useParams();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const pages = [
        { name: '검사', link: '/admin/scale' },
        { name: '검사 상세', link: `/admin/scale/${scaleNo}` },
        { name: '문항 생성', link: '' },
    ];
    const [creating, setCreating] = useState(false);
    const [subScales, setSubScales] = useState([]);
    const [itemData, setItemData] = useState({
        Content: '',
        SubScale: '',
        ItemKind: '',
        MultipleCheck: false,
        Choices: [''],
        Points: [''],
        LabelKind: ''
    });

    const handleClickCreateBtn = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(itemData);
        await axios.post(`/api/admin/item/${scaleNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/admin/scale/${scaleNo}`);
                }, [1200]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                setTimeout(() => {
                    setCreating(false);
                }, [1200]);
            }
        })
        .catch((err) => {
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/scale/${scaleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadSubScales = [];
                for(let i = 0; i < res.data.ScaleData.SubScale.length; i++){
                    loadSubScales.push({ value: i+1, label: res.data.ScaleData.SubScale[i] });
                }
                setSubScales(loadSubScales);
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, enqueueSnackbar, scaleNo]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ItemCreateView
                itemData={itemData}
                subScales={subScales}
                setItemData={setItemData}
                handleClickCreateBtn={handleClickCreateBtn}
                creating={creating}
            />
        </DashboardLayout>
    );
};

export default ItemCreateContainer;