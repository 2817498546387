import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { UserDetailView } from 'components/Admin/User';
import { MemberStatus, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const UserDetailContainer = (props) => {
    const { userNo } = useParams();
    const pages = [
        { name: '유저', link: '/admin/user' },
        { name: '유저 상세', link: '' }
    ];
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [updating, setUpdating] = useState(false);
    const [manager, setManager] = useState({});
    const [workers, setWorkers] = useState([]);

    const handleClickState = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(manager);
        await axios.patch(`/api/admin/manager/${userNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setManager({ ...manager, State: manager.State === 1 ? 2 : 1 });
                setTimeout(() => {
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    setUpdating(false);
                }, [1200]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/manager/${manager._id}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                navigate(-1);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/manager/${userNo}/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                setManager(res.data.ManagerData);
                await axios.get(`/api/admin/manager/employee/${res.data.ManagerData._id}/${cookies.LoginKey}`, config)
                .then((res2) => {
                    if(!res2.data.ErrorMessage){
                        let loadWorkers = [...res2.data.WorkerDatas];
                        for(let i = 0; i < loadWorkers.length; i++){
                            loadWorkers[i].No = i + 1;
                            loadWorkers[i].StatusKo = MemberStatus(loadWorkers[i].Status);
                            loadWorkers[i].CreatedAtValue = YyyyMmDdHhMmSs(loadWorkers[i].createdAt);
                        }
                        setWorkers(loadWorkers);
                    }else{
                        enqueueSnackbar(res2.data.ErrorMessage, { variant: 'error' });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar('Network Error!', { variant: 'error' });
                    console.log(err);
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, enqueueSnackbar, setManager, userNo, setWorkers]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <UserDetailView 
                manager={manager}
                workers={workers}
                updating={updating}
                handleClickState={handleClickState}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default UserDetailContainer;