import React from 'react';
import { Grid, Divider, IconButton } from '@mui/material';
import { RadioButtonUnchecked, RadioButtonChecked, Delete, Edit } from '@mui/icons-material';

const ItemLikert = (props) => {
    let point = 0;
    if(props.pdf && props.scale.CalScore){
        for(let i = 0; i < props.answer.length; i++){
            point += props.item.Points[`${props.answer[i]}`];
        }
    }
    const regex = /[^0-9]/g;
    const regex2 = /[0-9]/g;
    return (
        <Grid container justifyContent={'center'} style={{ overflowX: 'hidden' }}>
            {props.subScale ?
            <Grid item xs={12} style={{ paddingTop: props.no === 1 ? '0px' : '16px' }}>
                <span style={{ color: 'rgb(139, 139, 139)'}}>{props.scale.SubScale[props.item.SubScale-1]}</span>
            </Grid>:
            null
            }
            <Grid item xs={props.update ? 10 : 12} container>
                <Grid item xs={12}>
                    Q{props.no}. {props.item.Content}
                    {props.pdf && props.scale.CalScore ? <span style={{ color: '#5E5E5E' }}>&nbsp;&nbsp;{point}점</span> : ''}
                </Grid>
            </Grid>
            {props.update ?
            <Grid item xs={2} style={{ textAlign: 'right'}}>
                <IconButton style={{ marginRight: '8px'}} onClick={props.delete}>
                    <Delete />
                </IconButton>
                <IconButton onClick={props.update}>
                    <Edit />
                </IconButton>
            </Grid>:null}
            <Grid item xs={12} container style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center', verticalAlign: 'middle' }}>
                <Grid item>
                    {/* A{props.no}.&nbsp; */}
                </Grid>
                <Grid item>
                    <table style={{ tableLayout: 'fixed', width: '100%' }}>
                        <tbody>
                            <tr>
                                {props.item.LabelKind === 1 ?
                                <td>
                                    {props.item.Choices[0]}
                                </td>:
                                null}
                                {props.item.Points?.map((_, i) => (
                                <td key={i}>
                                    {props.item.LabelKind === 2 ?
                                    <Grid style={{ color: 'gray', whiteSpace: 'nowrap', float: i === props.item.Points.length-1 && props.float ? 'right' : 'none' }}>
                                        {props.item.Choices[i].replace(regex2, '') ? props.item.Choices[i].replace(regex2, '') : 'ㅤ'}
                                    </Grid>:
                                    null}
                                    {props.answer ?
                                        props.answer[0] === String(i) ?
                                            <RadioButtonChecked color='primary' />:
                                            <RadioButtonUnchecked />:
                                        <RadioButtonUnchecked />}
                                    {props.item.LabelKind === 2 ?
                                    <Grid>
                                        {props.item.Choices[i].replace(regex, '') ? props.item.Choices[i].replace(regex, '') : 'ㅤ'}
                                    </Grid>:
                                    null}
                                    {/* {props.item.LabelKind === 2 ?
                                    <Grid>
                                        {props.item.Choices[i]}
                                    </Grid>:
                                    null} */}
                                </td>
                                ))}
                                {props.item.LabelKind === 1 ?
                                <td>
                                    {props.item.Choices[props.item.Choices.length-1]}
                                </td>:
                                null}
                            </tr>
                        </tbody>
                    </table>
                    {/* {props.item.Choices.map((item, i) => (
                        <Grid container key={i} style={{ paddingBottom: '4px' }}>
                            <Grid item>
                                <RadioButtonChecked color='primary' />:
                                <RadioButtonUnchecked />
                            </Grid>
                        </Grid>
                    ))} */}
                </Grid>
            </Grid>
            {!props.lastItem ?
            <Grid item xs={11.5} style={{ paddingBottom: '4px' }}>
                <Divider />
            </Grid>:null}
        </Grid>
    );
};

export default ItemLikert;