import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Client, Message, Schedule, Manage, User, Admin } from './pages';
import { Login, Register, Contact, ScaleBasic, ScaleSession, ScaleSessionAll, SearchPassword, ScalePDF, RegistPassword, UserBasic } from 'domains';
import { useSnackbar } from 'notistack';

import NotFound from './NotFound';
import { useCookies } from 'react-cookie';

const HeadRoutes = () => {
    const location = useLocation();
    const [cookies] = useCookies(['Status']);
    const status = Number(cookies.Status);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        window.scrollTo(0, 0);
        if(status === 5){
            if(location.pathname.indexOf('/admin') !== 0){
                URLError('/admin/user');
            }
        }else if(status === 3){
            if(location.pathname.indexOf('/admin') > -1){
                URLError('/manage/client');
            }else if(!(location.pathname.indexOf('/manage/room') === 0) && !(location.pathname.indexOf('/schedule') === 0) && !(location.pathname.indexOf('/message') === 0) && !(location.pathname.indexOf('/manage/client') === 0) && !(location.pathname.indexOf('/user') === 0)){
                URLError('/manage/client');
            }else if(location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/contact' || (location.pathname.indexOf('/searchpassword') > -1)){
                URLError('/manage/client');
            }
        }else if(status === 2){
            if(location.pathname.indexOf('/admin') > -1){
                URLError('/client');
            }else if(!(location.pathname.indexOf('/manage/client') === 0) && !(location.pathname.indexOf('/manage/client') === 0) && !(location.pathname.indexOf('/schedule') === 0) && !(location.pathname.indexOf('/message') === 0) && !(location.pathname.indexOf('/client') === 0) && !(location.pathname.indexOf('/manage/supervisee') === 0) && !(location.pathname.indexOf('/scale') === 0) && !(location.pathname.indexOf('/user') === 0)){
                URLError('/client');
            }else if(location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/contact' || (location.pathname.indexOf('/searchpassword') > -1)){
                URLError('/client');
            }
        }else if(status === 1){
            if(location.pathname.indexOf('/admin') > -1){
                URLError('/manage/client');
            }else if(location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/contact' || (location.pathname.indexOf('/searchpassword') > -1)){
                URLError('/manage/client');
            }
        }else{
            if(location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact' && !(location.pathname.indexOf('/searchpassword') > -1) && !(location.pathname.indexOf('/scale') === 0) && !(location.pathname.indexOf('/user') === 0) && !(location.pathname.indexOf('/registpassword') > -1)){
                URLError('/');
            }
        }
    }, [location.pathname]);

    const URLError = (url) => {
        enqueueSnackbar('잘못된 경로입니다.', { variant: 'error' });
        navigate(url);
    };

    return (
        <Routes>
            <Route path='' element={<Login />} />
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='contact' element={<Contact />} />
            <Route path='user/*' element={<User />} />
            <Route path='client/*' element={<Client />} />
            <Route path='manage/*' element={<Manage />} />
            <Route path='message/*' element={<Message />} />
            <Route path='schedule/*' element={<Schedule />} />
            <Route path='admin/*' element={<Admin />} />
            <Route path='searchpassword/:codeKey' element={<SearchPassword />} />
            <Route path='registpassword/:codeKey' element={<RegistPassword />} />
            <Route path='scale/pdf/:scheduleNo/:scaleNo' element={<ScalePDF />}/>
            <Route path='scale/:clientNo/basic/:itemId/:codeKey' element={<ScaleBasic />}/>
            <Route path='scale/:clientNo/:scheduleNo/:codeKey' element={<ScaleSessionAll />}/>
            <Route path='scale/:clientNo/:scheduleNo/:scaleNo/:codeKey' element={<ScaleSession />}/>
            <Route path='user/:clientNo/basic/:codeKey' element={<UserBasic />}/>
            <Route path='api/*' element={<NotFound />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default HeadRoutes;