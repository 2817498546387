import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { FormContainer } from 'components/Items/Container';
import { TextForm } from 'components/Items/Form';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';

const ContactView = (props) => {
    const { user, setUser } = props;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>        
        <FormContainer title='문의 하기'>
            <TextForm
                label='이름'
                required={true}
                error={false}
                value={user.Name}
                disabled={true}
            />
            <TextForm
                label='연락처'
                required={true}
                error={false}
                value={user.Phone}
                handleChange={(e) => setUser({ ...user, Phone: e.target.value })}
            />
            <TextForm
                label='이메일'
                required={true}
                error={false}
                value={user.Email}
                handleChange={(e) => setUser({ ...user, Email: e.target.value })}
            />
            <TextForm
                label='문의 내용'
                required={true}
                error={false}
                rows={5}
                value={user.Content}
                handleChange={(e) => setUser({ ...user, Content: e.target.value })}
            />
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4} lg={3}>
                    <LargeCancelButton
                        label='이전'
                        loading={false}
                        handleClick={() => navigate(-1)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <LargeSubmitButton
                        label='문의하기'
                        loading={false}
                        handleClick={props.handleClickSendBtn}
                    />
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default ContactView;