import React, { useState } from 'react';
import { Grid, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton } from 'components/Items/Button';
import { DataTable } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';

const ScaleListView = (props) => {
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const scaleKeys = [{ key: 'No', label: 'No.'},
                        { key: 'Label', label: '이름'},
                        { key: 'StateValue', label: '상태'},
                        { key: 'ScaleKindValue', label: '검사 종류'},
                        { key: 'CreatedAtValue', label: '생성 일시'}];

    const [search, setSearch] = useState('');
    const [openScaleDeleteModal, setOpenScaleDeleteModal] = useState(false);

    const filterDatas = (datas) => {
        if(checked){
            return datas.filter((item) => (item.State === 2));
        }else{
            return datas;
        }
    };
    const handleClickDetail = (data) => {
        navigate(`/admin/scale/${data.ScaleNo}`);
    };
    const handleClickUpdate = (data) => {
        navigate(`/admin/scale/${data.ScaleNo}/update`);
    };
    const handleClickDelete = (data) => {
        props.setSelectScale(data);
        setOpenScaleDeleteModal(true);
    };

    return (
        <>
        <ListContainer title='검사 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid container item xs={6} justifyContent={'flex-end'} alignItems={'center'} textAlign={'right'}>
                        <Grid item>
                            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                        </Grid>
                        <Grid item>
                            <span className='hover_pointer' onClick={() => setChecked(!checked)}>미출시</span>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} textAlign={'right'}>
                        <SmallSubmitButton 
                            label='검사 생성'
                            handleClick={() => navigate('/admin/scale/create')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={scaleKeys}
                    datas={filterDatas(props.scales)}
                    delete={true}
                    update={true}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickUpdate={handleClickUpdate}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
        </ListContainer>
        {props.selectScale.ScaleNo ?
        <ConfirmModal
            title='검사 삭제'
            icon='error'
            open={openScaleDeleteModal}
            handleClose={() => setOpenScaleDeleteModal(false)}
            handleClickSubmit={() => {setOpenScaleDeleteModal(false);props.handleClickScaleDeleteBtn();}}
            submitLabel='삭제'
        >
            검사 정보를 삭제 하시겠습니까? 검사 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default ScaleListView;