import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, FormGroup, Checkbox } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { DateForm, SelectForm } from 'components/Items/Form';
import { Separator } from 'components/Function/AddDatas';

const ClientReportView = (props) => {
    const { report, setReport, scales, handleClickDownload } = props;
    const navigate = useNavigate();
    const [endDateError, setEndDateError] = useState(false);
    const handleChangeEndDate = (e) => {
        if(new Date(report.StartDate) > new Date(e.target.value)){
            setEndDateError(true);
        }else{
            setEndDateError(false);
            setReport({ ...report, EndDate: e.target.value });
        }
    };

    const handleChangeScales = (e) => {
        let changeScales = { ...report };
        if(changeScales.Scales.indexOf(e.target.value) > -1){
            changeScales.Scales.splice(changeScales.Scales.indexOf(e.target.value), 1);
        }else{
            changeScales.Scales.push(e.target.value);
        }
        setReport(changeScales);
    };

    const handleChangeOption = (e) => {
        let changeOption = { ...report };
        if(changeOption.Option.indexOf(Number(e.target.value)) > -1){
            changeOption.Option.splice(changeOption.Option.indexOf(Number(e.target.value)), 1);
        }else{
            changeOption.Option.push(Number(e.target.value));
        }
        setReport(changeOption);
    };

    return (
        <>
        <FormContainer title='리포트 다운로드'>
            <FormControl fullWidth >
                <Grid style={{ marginBottom: '8px' }}>
                    리포트 종류
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={'1'} control={<Radio checked={Boolean(report.Kind === 1)}/>} label={'내담자'} onChange={() => setReport({ ...report, Kind: 1, ClientState: 1, Scales: [], Option: [] })} />
                    <FormControlLabel value={'2'} control={<Radio checked={Boolean(report.Kind === 2)}/>} label={'상담회기'} onChange={() => setReport({ ...report, Kind: 2, ClientState: 1, Scales: [], Option: [] })} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            
            <FormControl fullWidth >
                <Grid style={{ marginBottom: '8px' }}>
                    내담자 선택
                </Grid>
                <RadioGroup row defaultValue={'1'}>
                    <FormControlLabel value={'1'} control={<Radio checked={Boolean(report.ClientState === 1)} />} label={'진행중'} onChange={() => setReport({ ...report, ClientState: 1 })}/>
                    <FormControlLabel value={'2'} control={<Radio checked={Boolean(report.ClientState === 2)} />} label={'종결'} onChange={() => setReport({ ...report, ClientState: 2 })}/>
                    {report.Kind === 2 ? <FormControlLabel value={'3'} control={<Radio checked={Boolean(report.ClientState === 3)}/>} label={'전체'} onChange={() => setReport({ ...report, ClientState: 3 })}/> : null}
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <DateForm
                label='시작일'
                required={false}
                error={false}
                value={report.StartDate}
                handleChange={(e) => {setReport({ ...report, StartDate: e.target.value, EndDate: '' });setEndDateError(false);}}
            />
            <DateForm
                label='종료일'
                required={false}
                error={endDateError}
                errorMessage='시작일 이전일 수 없습니다.'
                value={report.EndDate}
                handleChange={handleChangeEndDate}
            />
            {report.Kind === 2 ?
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    검사 선택
                </Grid>
                <FormGroup row>
                    {scales.map((scale, i) => (
                    <FormControlLabel key={i} value={scale._id} control={<Checkbox checked={Boolean(report.Scales.indexOf(scale._id) > -1)}/>} label={scale.Label} onChange={handleChangeScales} />
                    ))}
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>:null}
            {/* {report.Kind === 2 ?
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    옵션
                </Grid>
                <FormGroup row>
                    <FormControlLabel value={1} control={<Checkbox checked={Boolean(report.Option.indexOf(1) > -1)}/>} label={'하위 요인'} onChange={handleChangeOption}/>
                    <FormControlLabel value={2} control={<Checkbox checked={Boolean(report.Option.indexOf(2) > -1)}/>} label={'응답'} onChange={handleChangeOption}/>
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>:
            null} */}
            <SelectForm
                label='구분자'
                required={false}
                error={false}
                value={report.Separator}
                datas={Separator()}
                handleChange={(e) => setReport({ ...report, Separator: e.target.value })}
            />
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton label='다운로드' handleClick={handleClickDownload} loading={props.loading} />
                </Grid>
            </Grid>
        </FormContainer>
        </>
    );
};

export default ClientReportView;