import React, { useState, useEffect, useCallback} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ScaleDetailView } from 'components/Admin/Scale';

const ScaleDetailContainer = (props) => {
    const pages = [
        { name: '검사', link: '/admin/scale' },
        { name: '검사 상세', link: '' }
    ];
    const { scaleNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [scale, setScale] = useState({});
    const [selectItem, setSelectItem] = useState({});
    const navigate = useNavigate();

    const handleChangeState = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(scale);
        await axios.patch(`/api/admin/scale/${scaleNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setScale({...scale, State: res.data.State });
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickScaleDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/scale/${scaleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                navigate(-1);
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickItemDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/item/${scaleNo}/${selectItem.ItemNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Messgae, { variant: 'info' });
                loadData();
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };
    
    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/scale/${scaleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setScale(res.data.ScaleData);
            }else{ 
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, setScale, enqueueSnackbar, scaleNo]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ScaleDetailView
                scale={scale}
                handleChangeState={handleChangeState}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                handleClickItemDeleteBtn={handleClickItemDeleteBtn}
                handleClickScaleDeleteBtn={handleClickScaleDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default ScaleDetailContainer;