import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm } from 'components/Items/Form';
import { ConfirmModal } from 'components/Items/Modal';
import { DegreeKo, MemberStatus, UniversityStatusKo, LicenseKo, RegionKo } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime } from 'components/Function/AddDatas';

const MemberDetailView = (props) => {
    const navigate = useNavigate();
    const { memberNo } = useParams();
    const { handleClickDeleteBtn } = props;
    const [openMemberDeleteModal, setOpenMemberDeleteModal] = useState(false);
    const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false);
    const [search, setSearch] = useState('');

    const memberTable = [
        { key: '이름', value: props.member.Name },
        { key: '이메일', value: props.member.Email },
        { key: '연락처', value: props.member.Phone },
        { key: '권한', value: MemberStatus(props.member.Status) },
        { key: '학위', value: DegreeKo(props.member.Degree) },
        { key: '수료여부', value: UniversityStatusKo(props.member.UniversityStatus) },
        { key: '학교', value: props.member.University },
        { key: '전공', value: props.member.Major },
    ];
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'NickName', label: '닉네임'},
                        { key: 'Name_decrypt', label: '이름'},
                        { key: 'AgeValue', label: '만 나이'},
                        { key: 'Birth', label: '생년월일'},
                        { key: 'GenderValue', label: '성별'},
                        { key: 'Phone_decrypt', label: '연락처'}, 
                        { key: 'Email_decrypt', label: '이메일'}];
    
    const handleClickDetail = (data) => {
        navigate(`/manage/client/${data.ClientNo}`);
    };
    const filterDatas = (datas) => {
        return datas;
    };

    return (
        <>
        <DetailContainer
            title='직원 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenMemberDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/manage/member/${memberNo}/update`) }]}
            // button={{ label: '비밀번호 초기화', handleClick: () => setOpenPasswordResetModal(true) }}
        >
            <DataBox
                datas={memberTable}
            />
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            희망 상담시간
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <Grid container style={{ textAlign: 'center', borderBottom: '1px solid #C0C0C0', fontSize: '80%' }}>
                                <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                </Grid>
                                {PossibleDay().map((day, i) => (
                                <Grid item xs={12/8} key={i} style={{ borderRight: i < 6 ? '1px solid #C0C0C0' : '0px' }}>
                                    {day.label.replace('요일', '')}
                                </Grid>
                                ))}
                            </Grid>
                            {PossibleTime().map((time, i) => (
                            <Grid container key={i} style={{ textAlign: 'center', borderBottom: i < 17 ? '1px solid #C0C0C0' : '0px', fontSize: '80%' }}>
                                <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                    {time.label.split('~')[0].replace(':00 ', '시')}
                                </Grid>
                                {PossibleDay().map((day, j) => (
                                <Grid
                                    item
                                    xs={12/8}
                                    key={j}
                                    style={{ 
                                        backgroundColor: 
                                            props.member.AvailableDay.indexOf(day.value) > -1 && props.member.Available[Number(day.value)-1].indexOf(time.value) > -1 ?
                                            'rgba(161, 216, 160, 0.7)' : 
                                            '', 
                                        borderRight: j < 6 ? '1px solid #C0C0C0' : '0px'
                                    }}
                                />
                                ))}
                            </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'center'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            활동지역
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingLeft: '16px'
                            }}
                        >
                            {props.member.Region.map((item, i) => (
                            <span key={i}>{i === 0 ? '' : ', '}{RegionKo(item)}</span>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            자격증
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingLeft: '16px'
                            }}
                        >
                            {props.member.License.map((item, i) => (
                            <div key={i}>{LicenseKo(item)}</div>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DetailContainer>
        {props.member.Status === 1 || props.member.Status === 2 ?
        <DetailContainer
            title='내담자 목록'
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={filterDatas(props.clients)}
                        delete={false}
                        update={false}
                        search={search}
                        handleClickDetail={handleClickDetail}
                    />
                </Grid>
            </Grid>
        </DetailContainer>:null}
        <ConfirmModal
            title='직원 삭제'
            icon='error'
            open={openMemberDeleteModal}
            handleClose={() => setOpenMemberDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={() => {setOpenMemberDeleteModal(false);handleClickDeleteBtn();}}
        >
            직원 정보를 삭제 하시겠습니까? 직원 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        {/* <ConfirmModal
            title='비밀번호 초기화'
            icon='info'
            open={openPasswordResetModal}
            handleClose={() => setOpenPasswordResetModal(false)}
            handleSubmit={() => { props.handleClickPasswordReset();setOpenPasswordResetModal(false); }}
            submitLabel='확인'
        >
            확인 버튼 클릭 시 <b>1234</b>로 비밀번호 변경가 변경됩니다.
        </ConfirmModal> */}
        </>
    );
};

export default MemberDetailView;