import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ContactView } from 'components';

const ContactContainer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [contact, setContact] = useState({
        Name: '',
        Phone: '',
        Email: '',
        Content: ''
    });

    const handleClickSendBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(contact);
        await axios.post(`/api/contact`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setContact({ Name: '', Phone: '', Email: '', Content: '' });
                navigate('/login');
                enqueueSnackbar(res.data.Message, { variant: 'info' })
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    return (
        <ContactView 
            contact={contact}
            setContact={setContact}
            handleClickSendBtn={handleClickSendBtn}
        />
    );
};

export default ContactContainer;