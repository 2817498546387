import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { DashboardLayout } from 'components/Layout';
import { TeamCreateView } from 'components/User/Manage/Team';

const TeamCreateContainer = (props) => {
    const pages = [
        { name: '팀 관리', link: '/manage/team' },
        { name: '팀 생성', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [creating, setCreating] = useState(false);
    const [team, setTeam] = useState({
        Name: ''
    });

    const handleClickCreateBtn = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(team);
        await axios.post(`/api/manager/team/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setTimeout(() => {
                    navigate(`/manage/team/${res.data.Result.TeamNo}`);
                }, [1200]);
            }else{
                setCreating(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setCreating(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <TeamCreateView 
                team={team}
                setTeam={setTeam}
                creating={creating}
                handleClickCreateBtn={handleClickCreateBtn}
            />
        </DashboardLayout>
    );
};

export default TeamCreateContainer;